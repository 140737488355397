import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import LinkTag from '../UI/LinkTag';
import CTA from '../UI/CTA';
import Video from '../UI/Video';
import BlockQuote from '../UI/BlockQuote';
import { NavLink } from 'react-router-dom';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import ImageComponent from '../UI/Image';
import SkeletonLoader from '../UI/SkeletonLoader';
import './styles.scss';

const CustomerCardPresentationalComponent = ({
  contentType = 'text',
  cardType = 'Horizontal',
  categories,
  platforms,
  title,
  linkText,
  linkUrl,
  videoUrl,
  logo,
  image,
  quote,
  isLoading,
}) => {
  const isVideoType = contentType === 'video';
  const Tag = isVideoType ? 'article' : NavLink;

  const ctaProps = {
    ...(isVideoType ? { tag: 'navlink', to: linkUrl } : { tag: 'span' }),
    type: 'secondary',
  };

  return (
    <Tag to={linkUrl || ''} className={`customer-card customer-card--${contentType} customer-card--${cardType}`}>
      {!isLoading && <LinkOpenIcon type={cardType === 'Short' ? 3 : 2} />}
      <div className="customer-card__inner">
        <div className="customer-card__text-wrap">
          <div className="customer-card__header">
            <SkeletonLoader lines={1} isLoading={isLoading} customClass="customer-card__tags">
              <div className="tags">
                {categories?.map((item, i) => {
                  const tagText = item.fields?.Title.value || item;
                  return (
                    <LinkTag key={i} tag={'span'}>
                      <Text field={{ value: tagText }} />
                    </LinkTag>
                  );
                })}
                {platforms?.map((item, i) => {
                  const tagText = item.fields?.Title.value || item;
                  return (
                    <LinkTag key={i} tag={'span'}>
                      <Text field={{ value: tagText }} />
                    </LinkTag>
                  );
                })}
              </div>
            </SkeletonLoader>
            <SkeletonLoader lines={2} isLoading={isLoading}>
              {title && (
                <h4 className="customer-card__title">
                  <Text field={{ value: title }} />
                </h4>
              )}
            </SkeletonLoader>
          </div>
          <div
            className={classNames('customer-card__footer', {
              'customer-card__footer--loading': isLoading,
            })}
          >
            <SkeletonLoader lines={1} isLoading={isLoading} customClass="customer-card__tags">
              <div className="customer-card__footer-item">
                {linkText && linkUrl && (
                  <CTA {...ctaProps}>
                    <Text field={{ value: linkText }} />
                  </CTA>
                )}
              </div>
            </SkeletonLoader>
            <SkeletonLoader lines={2} isLoading={isLoading} customClass="customer-card__laoding-logo">
              <div className="customer-card__footer-item">
                {logo && <ImageComponent className="customer-card__logo" imageData={logo} />}
              </div>
            </SkeletonLoader>
          </div>
        </div>
        <SkeletonLoader isLoading={isLoading} customClass="customer-card__media">
          {cardType !== 'Short' && (
            <div className="customer-card__pic-wrap">
              {isVideoType ? (
                <Video url={videoUrl} embeded />
              ) : (
                <ImageComponent className="customer-card__img" imageData={image} />
              )}
              {contentType === 'quote' && (
                <div className="customer-card__quote-wrap theme-dark">
                  <BlockQuote>
                    <Text field={{ value: quote }} />
                  </BlockQuote>
                </div>
              )}
            </div>
          )}
        </SkeletonLoader>
      </div>
    </Tag>
  );
};

export default CustomerCardPresentationalComponent;
