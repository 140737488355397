import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import SideMenu from '../SideMenu';
import { canUseDOM } from '@/js/utils';
import './style.scss';
import Logo from '../Logo';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const BurgerMenu = ({ rendering }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((value) => !value);
  };

  const header = () => {
    return (
      <>
        <Logo darkTheme fullSize />
        <div className="side-menu__header-controls">
          <SearchInputPortal onSearchOpen={handleClick} rendering={rendering} />
          <button className="side-menu__close" onClick={handleClick}>
            <i className="fas fas fa-times" aria-hidden="true" />
          </button>
        </div>
      </>
    );
  };

  const body = () => {
    return (
      <>
        <Placeholder name="jss-header-navigation" mobile rendering={rendering} />
        <div className="side-menu__navigation-secondary-wrap">
          <Placeholder name="jss-header-navigation-secondary" mobile rendering={rendering} />
        </div>
        <div className="side-menu__contacts-wrap">
          <Placeholder name="jss-header-contacts" mobile rendering={rendering} />
        </div>
        <div className="side-menu__socials-wrap">
          <Placeholder name="jss-header-socials" rendering={rendering} />
        </div>
      </>
    );
  };

  const portal = () => {
    if (!canUseDOM()) {
      return null;
    }
    return ReactDOM.createPortal(
      <SideMenu
        open={open}
        setOpen={setOpen}
        header={header()}
        headerClassName={'theme-dark bg-dark'}
        body={body()}
        bodyClassName={'theme-dark bg-dark'}
      />,
      document.getElementById('push-menu-root')
    );
  };

  return (
    <>
      <button className="burger-menu" onClick={handleClick}>
        <span />
      </button>
      {portal()}
    </>
  );
};

const SearchInputPortal = ({ onSearchOpen, rendering }) => {
  const [openSearch, setOpenSearch] = useState(false);

  const handleSearchBtnClick = () => {
    onSearchOpen();
    setOpenSearch(true);
  };

  const portal = () => {
    if (!canUseDOM()) {
      return null;
    }
    return ReactDOM.createPortal(
      <Placeholder name="jss-header-search" open={openSearch} setOpen={setOpenSearch} mobile rendering={rendering} />,
      document.getElementById('root')
    );
  };

  return (
    <>
      <button className="search-btn" onClick={handleSearchBtnClick}>
        <i className="fas fa-search" />
      </button>
      {portal()}
    </>
  );
};

export default BurgerMenu;
