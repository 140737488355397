import axios from 'axios';

export const FETCH_INSIGHTS_START = 'FETCH_INSIGHTS_START';
export const FETCH_INSIGHTS_SUCCESS = 'FETCH_INSIGHTS_SUCCESS';
export const FETCH_INSIGHTS_ERROR = 'FETCH_INSIGHTS_ERROR';
export const SET_STATIC_DATA = 'SET_STATIC_DATA';
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS';

export const setStaticData = (data) => {
  return {
    type: SET_STATIC_DATA,
    payload: data,
  };
};

export const setFilterParams = (filterData) => {
  return {
    type: SET_FILTER_PARAMS,
    payload: filterData,
  };
};

export const fetchDataStart = (loadMore = false) => {
  return {
    type: FETCH_INSIGHTS_START,
    loadMore,
  };
};

export const fetchDataSuccess = (INSIGHTS) => {
  return {
    type: FETCH_INSIGHTS_SUCCESS,
    payload: INSIGHTS,
  };
};

export const fetchDataError = (errorMessage) => {
  return {
    type: FETCH_INSIGHTS_ERROR,
    errorMessage: errorMessage,
  };
};

export const fetchInsights =
  (page, loadMore = false) =>
  async (dispatch, getState) => {
    const { ApiUrl, PageSize, SettingId } = getState().InsightsOverviewReducer.staticData;
    
    const formData = {
      ...getState().InsightsOverviewReducer.filterData,
      Page: page || getState().InsightsOverviewReducer.currentPage,
      PageSize,
      SettingId,
    };
    dispatch(fetchDataStart(loadMore));
    axios
      .post(ApiUrl, formData)
      .then(function (response) {
        dispatch(
          fetchDataSuccess({
            posts: response.data.SearchResult,
            currentPage: response.data.CurrentPage,
            countPages: response.data.CountPages,
            categories: response.data.AvailableCategories,
            technologies: response.data.AvailableTechnologies,
            count: response.data.Count,
            loadMore,
          })
        );
      })
      .catch(function () {
        dispatch(fetchDataError('error'));
      });
  };
