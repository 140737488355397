import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import { ExpEditor, ThemeResolver, withSitecoreContext, compose } from '@/js/HOC';
import { DateFormatter } from '@/js/utils';
import { useDispatch, useSelector } from 'react-redux';
import PostCardPresentationalComponent from '../PostCardPresentation';
import PostPreviewPresentationalComponent from '../PostPreviewPresentation';
import CTA from '../UI/CTA';
import { fetchBlogPosts } from '../../js/redux/actions/BlogOverview';
import classNames from 'classnames';
import './styles.scss';

const BlogOverviewContainerPreview = (props) => {
  const { posts, isLoading, currentPage, countPages, staticData } = useSelector((state) => state.BlogOverviewReducer);
  const { NoResultMessage } = staticData;
  const dispatchRedux = useDispatch();

  const showLoadMoreBtn = currentPage < countPages;
  const isPostsLoading = !isLoading.loadMore && isLoading.value;

  const handleLoadMoreClick = () => {
    dispatchRedux(fetchBlogPosts(currentPage + 1, true));
  };

  return (
    <section
      className={classNames({
        'blog-overview': true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        {isPostsLoading && <PostPreviewPresentationalComponent isLoading={isPostsLoading} />}
        {!isPostsLoading && (
          <>
            {posts?.length <= 0 && !isPostsLoading ? (
              NoResultMessage
            ) : (
              <>
                <div className="blog-overview__list row">
                  {posts?.map((post, i) => {
                    const postProps = {
                      url: post.Url,
                      image: {
                        src: post.PreviewImage,
                        alt: post.PreviewTitle,
                        width: 715,
                        height: 475,
                      },
                      title: post.PreviewTitle,
                      text: post.PreviewText,
                      categories: post?.Category,
                      platforms: post?.Platform,
                      authors: post?.Authors,
                      publicationDate: DateFormatter(post.DateOfPublication),
                    };
                    return post.IsFavorite ? (
                      <div key={i} className="blog-overview__item col-12">
                        <PostPreviewPresentationalComponent {...postProps} />
                      </div>
                    ) : (
                      <div key={i} className="blog-overview__item col-12 col-md-6 col-lg-4">
                        <PostCardPresentationalComponent {...postProps} />
                      </div>
                    );
                  })}
                </div>
                {showLoadMoreBtn && (
                  <div className="blog-overview__footer">
                    {!isLoading.value ? (
                      <CTA tag="button" type="primary" onClick={handleLoadMoreClick}>
                        <Text field={{ value: i18n.t('ComponentsBlogsOverviewLoadMore') }} />
                      </CTA>
                    ) : (
                      isLoading.loadMore && <PostPreviewPresentationalComponent isLoading={isLoading.value} />
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(BlogOverviewContainerPreview);
