import React from 'react';
import './styles.scss';

const Preloader = ({ show, absolute }) => {
  if (!show) {
    return null;
  }
  return (
    <div className={`preloader${absolute ? ' absolute' : ''}`}>
      <div className="preloader__spinner" />
    </div>
  );
};

export default Preloader;
