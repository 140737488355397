import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { SIZE_UNITS } from '@/js/utils';
import './styles.scss';

const getFileSizeUnit = (size) => {
  return Object.keys(SIZE_UNITS).reverse().find((key) => {
    return size / +key >= 1;
  }) || 1048576;
}

const PreviewFile = ({ file, onRemove }) => {
  const isImage = useRef(file.type.startsWith('image/'));
  const fileSizeUnit = getFileSizeUnit(file.size);
  const fileSize = file.size / fileSizeUnit;
  const normalizedFileSize = Number.isInteger(fileSize) ? fileSize : fileSize.toFixed(1);

  const [state, setState] = useState({
    name: file.name,
    size: normalizedFileSize,
  });

  const handleReaderEvent = (e) => {
    setState((prev) => {
      return {
        ...prev,
        src: e.target.result,
      };
    });
  }

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener('load', handleReaderEvent);
    reader.readAsDataURL(file);

    return () => reader.removeEventListener('load', handleReaderEvent);
  }, []);

  const onClose = (e) => {
    e.stopPropagation();
    onRemove && onRemove(file);
  };

  return (
    <div
      className={classNames({
        'preview-file': true,
        'preview-file--image': isImage.current,
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <span className="preview-file__close" onClick={onClose}>
        <i className="fas fa-times" />
      </span>
      {isImage.current && (
        <div className="preview-file__img">
          <img src={state.src} />
        </div>
      )}
      <div className="preview-file__meta">
        <div className="preview-file__name">{state.name}</div>
        <div className="preview-file__size">{state.size} {SIZE_UNITS[fileSizeUnit]}</div>
      </div>
    </div>
  );
};

export default PreviewFile;
