import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import CTA from '../UI/CTA';
import TechCard from '../TechCard';
import classNames from 'classnames';
import './style.scss';

const OurTechnology = ({ fields, additionalClasses, additionalStyles }) => {
  const renderLink = () => {
    if (fields.Link?.value?.href) {
      return (
        <CTA tag={'navlink'} to={fields.Link.value.href} type="primary" arrowRight>
          <Text field={fields.LinkTitle} />
        </CTA>
      );
    }
  };

  return (
    <section
      className={classNames({
        'our-technology': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        <div className="row">
          <div className="our-technology__left-col col-12">
            <div className="our-technology__header">
              {fields.Title?.value && <Text tag={'h6'} className="our-technology__label" field={fields.Title} />}
              {fields.IntroText?.value && (
                <RichText tag={'h2'} className="our-technology__title" field={fields.IntroText} />
              )}
              {renderLink()}
            </div>
          </div>
          <div className="our-technology__right-col col-12">
            <div className="our-technology__body">
              <div className="our-technology__list">
                {fields.TechnologyPages?.map((techItem) => {
                  return (
                    !techItem?.fields.HideInNavigation?.value && (
                      <div className="our-technology__item" key={techItem.id}>
                        <TechCard url={techItem.url} fields={techItem.fields} />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
            <div className="our-technology__footer">{renderLink()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(OurTechnology);
