import React from 'react';
import Header from '../Header';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const BlogHeader = (props) => {
  const additionalClasses = {
    'header--body': true,
  };

  return (
    <Header additionalClasses={additionalClasses} title={props.sitecoreContext?.route?.fields?.PostTitle} {...props} />
  );
};

export default compose(withSitecoreContext(), ExpEditor)(BlogHeader);
