import { useReducer, useRef, useEffect } from 'react';
import axios from 'axios';

const initialState = {
  response: null,
  isLoading: false,
  errorMessage: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_START': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'FETCH_SUCCESS': {
      return {
        ...state,
        response: action.payload,
        isLoading: false,
      };
    }
    case 'FETCH_ERROR': {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    }
    default:
      return state;
  }
};

const useFetch = ({ url, errorMessage = '' }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const post = (options) => {
    dispatch({ type: 'FETCH_START' });
    return axios
      .post(url, options)
      .then((response) => {
        isMounted.current && dispatch({ type: 'FETCH_SUCCESS', payload: response });
        return response.status === 200 && isMounted.current ? response.data : null;
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_ERROR', payload: errorMessage });
      });
  };

  return { post, response: state.response, isLoading: state.isLoading, error: state.errorMessage };
};

export default useFetch;
