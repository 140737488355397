import React from 'react';
import { Modal } from 'react-bootstrap';
import { RichText, Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { compose, ModalRoot, ExpEditor, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import CTA from '../UI/CTA';
import './styles.scss';

const SuccessModal = ({ fields, onHide, show, isExperienceEditor, backdrop = true, onShow }) => {
  const handleBackBtnClick = () => {
    onHide();
  };
  return (
    <Modal
      className={classNames({
        'success-modal': true,
        'experience-editor': isExperienceEditor,
      })}
      centered
      size={'sm'}
      show={show}
      onHide={onHide}
      backdrop={backdrop}
      onShow={onShow}
    >
      <Modal.Header className={'modal-header-empty'} closeButton />
      <Modal.Body className={'modal-body-centered'}>
        {fields?.Image?.value?.src && <Image className={'success-modal__image'} field={fields.Image} />}
        {fields?.Title?.value && <Text tag="h4" field={fields.Title} />}
        {fields?.Description?.value && <RichText tag={'p'} field={fields.Description} />}
        {fields?.BackLink?.value?.href && fields?.BackLinkLabel?.value && (
          <div className="btn-wrap">
            <CTA
              type={'primary'}
              tag={'navlink'}
              to={fields?.BackLink.value.href}
              onClick={handleBackBtnClick}
              arrowRight
            >
              <Text field={fields.BackLinkLabel} />
            </CTA>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ModalRoot)(SuccessModal);
