import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import BtnTag from '../UI/BtnTag';

const MorePlatform = ({ fields }) => {
  const url = fields?.BlogOverviewPage?.value?.href;

  return (
    <div className="more-platform">
      {fields?.Title?.value && <Text tag="h6" className="sidebar__title" field={fields.Title} />}
      <div className="btn-tags">
        {fields?.Platform?.map((item) => {
          return (
            <NavLink key={item.id} to={{ pathname: url, search: `?platforms=${item.fields.Title.value}` }}>
              <BtnTag className="btn-tag">
                <Text field={item.fields.Title} />
              </BtnTag>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(MorePlatform);
