import {
  FETCH_STORIES_SUCCESS,
  FETCH_STORIES_START,
  FETCH_STORIES_ERROR,
  SET_STATIC_DATA,
  SET_FILTER_PARAMS,
} from '../actions/StoriesOverview';

const initialState = {
  staticData: {
    ApiUrl: null,
    NoResultMessage: null,
    PageSize: null,
    SettingId: null,
  },
  filterData: {
    Category: null,
    Platform: null,
    Product: null,
    Country: null,
  },
  isLoading: {
    loadMore: false,
    value: false,
  },
  isLoaded: false,
  posts: [],
  categories: [],
  platforms: [],
  products: [],
  countries: [],
  currentPage: 1,
  count: 0,
  errorMessage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          ...action.payload,
        },
      };
    case SET_STATIC_DATA:
      return {
        ...state,
        staticData: {
          ...state.staticData,
          ...action.payload,
        },
      };
    case FETCH_STORIES_START:
      return {
        ...state,
        isLoading: {
          loadMore: action.loadMore,
          value: true,
        },
      };
    case FETCH_STORIES_SUCCESS:
      const sortedPosts = [...action.payload.posts].sort((a, b) => {
        if (a.ViewType === 'Horizontal' && b.ViewType === 'Vertical') {
          return -1;
        }
      });
      let posts = action.payload.loadMore ? [...state.posts, ...sortedPosts] : sortedPosts;
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          value: false,
        },
        posts: posts,
        categories: action.payload.categories,
        platforms: action.payload.platforms,
        products: action.payload.products,
        countries: action.payload.countries,
        currentPage: action.payload.currentPage,
        countPages: action.payload.countPages,
        count: action.payload.count,
        isLoaded: true,
      };
    case FETCH_STORIES_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: {
          ...state.isLoading,
          value: false,
        },
      };
    default: {
      return state;
    }
  }
};

export default reducer;
