import React from 'react';
import Service from '../Service';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import { getColumnsClass } from '../../js/utils/helpers';
import classNames from 'classnames';
import './styles.scss';

const ServicesOverview = ({ additionalStyles, additionalClasses, fields, rendering }) => {
  return (
    <section
      className={classNames({
        'services-overview': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        <div className="services-overview__list row">
          {fields?.items?.map((item) => {
            return (
              !item?.fields?.HideInNavigation?.value && (
                <div className={`services-overview__item col-12 col-md-6 ${getColumnsClass(rendering?.params?.Columns)}`} key={`${item.id}-services-overview`}>
                  <Service url={item.url} fields={item.fields} />
                </div>
              )
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(ServicesOverview);
