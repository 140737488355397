import React, { useState } from 'react';
import { Text as JSSText, Image, Link as JSSLink } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, WithCustomColumnsGrid, withSitecoreContext } from '@/js/HOC';
import './style.scss';

const ImageGridItem = ({ fields, sitecoreContext }) => {
  const { DefaultImage, HoverImage, Text, Link } = fields;
  const isEEActive = sitecoreContext?.pageEditing;
  const hasLink = !!Link?.value?.href;
  const hasImage = !!DefaultImage?.value?.src;
  const hasHoverImage = !!HoverImage?.value?.src;
  const [isHover, setIsHover] = useState(false);

  const handleHover = () => {
    hasHoverImage && !isEEActive && setIsHover(!isHover);
  };

  const getImageContent = () => {
    if(isEEActive) {
      return (
        <>
          <div className='image-grid-item__image-wrapper'>
            <Image
              field={DefaultImage}
              loading="lazy" />
          </div>
          <div className='image-grid-item__image-wrapper'>
            <Image
              field={HoverImage}
              loading="lazy" />
          </div>
        </>
    )}
    return (
      hasImage && (
        <div className={`image-grid-item__image-wrapper ${hasHoverImage ? 'withHoverImage' : ''}`}>
          {!isHover && (
            <Image
              title=""
              field={DefaultImage}
              loading="lazy" />
          )}
          {isHover && (
            <Image
              title=""
              field={HoverImage}
              loading="lazy" />
          )}
        </div>
      )
    )
  }

  return (
    <>
      <JSSLink
        field={Link}
        className={`image-grid-item ${hasLink || isEEActive ? '' : 'd-none'}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        {getImageContent()}
        {Text?.value && <JSSText field={Text} tag="span" />}
      </JSSLink>
      <div
        className={`image-grid-item ${!hasLink && !isEEActive ? '' : 'd-none'}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        {getImageContent()}
        {Text?.value && <JSSText field={Text} tag="span" />}
      </div>
    </>
  );
};

export default compose(withSitecoreContext(), ExpEditor, WithCustomColumnsGrid)(ImageGridItem);
