import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ThemeResolver } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const ContainerWithOneColumns = ({ rendering, additionalClasses, additionalStyles }) => (
  <div
    className={classNames({
      'container-one-col': true,
      ...additionalClasses,
    })}
    style={additionalStyles}
  >
    <div className="container">
      <div className="row">
        <div className="col">
          <Placeholder rendering={rendering} name="jss-container-col-1" />
        </div>
      </div>
    </div>
  </div>
);

export default compose(ThemeResolver)(ContainerWithOneColumns);
