import React from 'react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../UI/CTA';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const ImageGrid = ({ fields, rendering, additionalClasses, additionalStyles, drawBeveledBorder, params }) => {
  const { Title, Subtitle, IntroText, Columns, LinkLabel, Link } = fields;
  
  return (
    <section
      className={classNames({
        'image-grid': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        <Text tag="h6" className="image-grid__subtitle" field={Subtitle} />
        <Text tag="h2" className="image-grid__title" field={Title} />
        <Text tag="p" className="image-grid__intro" field={IntroText} />
        <div className="image-grid__list row">
          <Placeholder customColumns={Columns?.fields.Value?.value} additionalClass='image-grid__item' name="jss-common-image-grid" rendering={rendering} />
        </div>
        {LinkLabel?.value && Link?.value?.href && (
          <div className="image-grid__footer">
            <CTA tag={'navlink'} to={Link?.value.href} type="primary" arrowRight>
              <Text field={LinkLabel} />
            </CTA>
          </div>
        )}
      </div>
      {drawBeveledBorder && <div className="beveled-border-maker" />}
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(ImageGrid);
