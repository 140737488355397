import React, { useEffect, useState } from 'react';
import { isDevMode } from '@/js/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext, WithLoader } from '@/js/HOC';
import useFetch from '@/js/hooks/useFetch';
import PostCardPresentationalComponent from '../PostCardPresentation';
import CTA from '../UI/CTA';
import classNames from 'classnames';
import './styles.scss';

// toDo add skeleton loader
const ArticlesCard = ({ fields, additionalClasses, additionalStyles, sitecoreContext }) => {
  const [articles, setArticles] = useState([]);

  const count = Number(fields.Count?.value) || 2;
  const apiUrl = isDevMode ? 'http://localhost:4000/getGlossaryPosts' : fields?.ApiUrl?.value;
  const { post, isLoading } = useFetch({ url: apiUrl });

  const getData = async () => {
    const data = await post({
      CategoryIds: fields.Categories?.map((item) => item.id),
      PlatformIds: fields.Platforms?.map((item) => item.id),
      BlogIds: fields.Blogs?.map((item) => item.id),
      ExcludePostId: sitecoreContext?.route?.itemId,
      Count: Number(fields.Count?.value) || 2,
    });

    if (data) {
      setArticles(data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section
      className={classNames({
        'articles-card': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div
            className={classNames({
              'col-12': true,
              'col-lg-8': count % 2 === 0,
            })}
          >
            <div className="articles-card__header">
              {fields.Title?.value && <Text tag="h4" className="articles-card__title" field={fields.Title} />}
              {fields.LinkLabel?.value && fields.Link?.value?.href && (
                <CTA tag={'navlink'} to={fields.Link.value.href} type="primary" arrowRight>
                  <Text field={fields.LinkLabel} />
                </CTA>
              )}
            </div>
            <WithLoader isLoading={isLoading} itemsLength={articles.length}>
            <div className="articles-card__list row">
              {articles.map((article, i) => {
                return (
                  <div
                    className={classNames({
                      'articles-card__item col-12 col-md-6': true,
                      'col-lg-4': count % 3 === 0,
                    })}
                    key={i}
                  >
                    <PostCardPresentationalComponent
                      url={article.Url}
                      image={{
                        src: article?.PreviewImage,
                        alt: article?.PreviewTitle,
                        width: 390,
                        height: 220,
                      }}
                      title={article?.PreviewTitle}
                      text={article?.PreviewText}
                    />
                  </div>
                );
              })}
            </div>
            </WithLoader>
          </div>
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(ArticlesCard);
