import React, { useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useDidMountEffect from '@/js/hooks/useDidMountEffect';
import { isDevMode, debounce } from '@/js/utils';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import useFetch from '@/js/hooks/useFetch';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import i18n from 'i18next';
import './styles.scss';

const SearchInput = ({ mobile, open, setOpen, fields }) => {
  const url = isDevMode ? 'http://localhost:4000/searchAutosuggestion' : fields?.ApiUrl?.value;
  const scrollArea = useRef();
  const input = useRef();
  const [state, setState] = useState({
    searchTerm: '',
    showResults: false,
    results: [],
  });
  const { post, isLoading } = useFetch({ url: url });
  const history = useHistory();

  useDidMountEffect(() => {
    if (mobile) {
      setTimeout(() => {
        open ? disableBodyScroll(scrollArea.current) : enableBodyScroll(scrollArea.current);
      }, 0);
    }
    if (!open) {
      clearSearch();
    } else {
      setTimeout(() => {
        input?.current.focus();
      }, 500);
    }
  }, [open]);

  const getDataDebounce = useCallback(
    debounce((value) => getData(value), 500),
    []
  );

  const getData = async (term = state.searchTerm) => {
    const data = await post({
      term,
      count: fields?.Count?.value,
    });
    if (data) {
      setState((prev) => ({
        ...prev,
        results: data,
        showResults: true,
      }));
    }
  };

  const clearSearch = () => {
    setState((prev) => ({
      ...prev,
      searchTerm: '',
      showResults: false,
      results: [],
    }));
  };

  const onToggleClick = () => {
    setOpen((prev) => !prev);
  };

  const onBackDropClick = () => {
    setOpen(false);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      searchTerm: value,
    }));
    if (value.length > 2) {
      getDataDebounce(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!state.searchTerm) {
      return;
    }
    redirectToGlobalSearch(state.searchTerm);
  };

  const redirectToGlobalSearch = (searchTerm) => {
    const searchUrl = fields?.SearchPage?.value?.href;
    if (!searchUrl) {
      return;
    }
    history.push({
      pathname: searchUrl,
      search: `?query=${searchTerm}`,
    });
    setOpen(false);
  };

  const handleAutoSuggestClick = (text) => (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      searchTerm: text,
      showResults: false,
    }));
    redirectToGlobalSearch(text);
  };

  const renderSearchResults = (results) => {
    return (
      <div className="search-input__results">
        <div className="search-input__results-title">
          {results?.length ? i18n.t('ComponentsSearchInputResults') : fields?.NotFoundMessage?.value}
        </div>
        <ul className="search-input__results-list list-reset">
          {results?.map((item) => {
            return (
              <li key={item} className="search-input__results-item">
                <div onClick={handleAutoSuggestClick(item)} className="search-input__results-link">
                  <i className="fas fa-search" />
                  <span>{item}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div
      className={`search-input ${mobile ? 'search-input--mobile theme-dark' : 'search-input--desktop'} ${
        open ? 'search-input--open' : ''
      }`}
    >
      <div onClick={onBackDropClick} className="search-input__backdrop" />
      <div className="search-input__body-wrap">
        <form onSubmit={handleSubmit} className="search-input__body">
          <button className="search-input__submit-btn">
            <i className="fas fa-search" />
          </button>
          <input
            ref={input}
            className="search-input__input"
            type="text"
            placeholder={fields?.PlaceholderLabel.value}
            value={state.searchTerm}
            onChange={handleSearchChange}
          />
          {!mobile && (
            <div className={`search-input__results-wrap${state.showResults ? ' open' : ''}`}>
              {renderSearchResults(state.results)}
            </div>
          )}
        </form>
        <button onClick={onToggleClick} type="button" className="search-input__toggle">
          {isLoading ? <i className="fas fa-spinner" /> : <i className={open ? 'fas fa-times' : 'fas fa-search'} />}
        </button>
      </div>
      {mobile && (
        <div className={`search-input__results-wrap`} ref={scrollArea}>
          {renderSearchResults(state.results)}
        </div>
      )}
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(SearchInput);
