import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import './styles.scss';
import { NavLink } from 'react-router-dom';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';

const BreadCrumb = ({ fields }) => {
  return (
    <nav className="breadcrumb">
      <ul className="breadcrumb__list list-reset">
        {Object.keys(fields).map((item, i) => {
          const link = fields[item];
          return (
            <li key={i} className={'breadcrumb__item'}>
              <NavLink to={link.url} className={'breadcrumb__link'} activeClassName={'breadcrumb__link--active'} exact>
                <Text field={{ value: link.title }} />
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(BreadCrumb);
