import React, { useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import loadable from '@loadable/component';
const ReactTooltip = loadable(() => import('react-tooltip'));
import './styles.scss';

const IntegrationsBlockItem = (props) => {
  const [content, setContent] = useState('');
  const renderTag = (number) => {
    let result;
    if (props.fields[`ToolName${number}`]?.value) {
      result = (
        <Text
          className="tool-row__tag"
          field={props.fields[`ToolName${number}`]}
          tag="span"
          onMouseEnter={() => {
            const content = props.fields[`ToolHover${number}`].value;
            setContent(content);
          }}
          onMouseLeave={() => {
            setContent('');
          }}
        />
      );
      if (!props.fields[`ToolHover${number}`]?.value) {
        result = <Text className="tool-row__title" field={props.fields[`ToolName${number}`]} tag="span" />;
      }
    } else {
      result = <Text className="tool-row__title" field={props.fields[`ToolHover${number}`]} tag="span" />;
    }
    return result;
  };

  return (
    <div className="integration-block-item" data-tip="">
      <div className="tool-row">
        <div className="tool-row__item tool-row__item--left ">{renderTag('')}</div>
        <div className="tool-row__icon">
          <i className="fas fa-arrows-h" />
        </div>
        <div className="tool-row__item tool-row__item--right ">{renderTag(2)}</div>
      </div>
      {props.fields?.Text?.value && <RichText field={props.fields.Text} />}
      <ReactTooltip type="light">{content}</ReactTooltip>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(IntegrationsBlockItem);
