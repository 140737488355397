import { FETCH_GLOSSARY_START, FETCH_GLOSSARY_SUCCESS, FETCH_GLOSSARY_ERROR } from '../actions/GlossaryOverview';

const initialState = {
  isLoading: false,
  articles: [],
  errorMessage: null,
};

const convertToHashMap = (glossaryList) => {
  return glossaryList.reduce((accumulator, {Key, Items}) => {
    accumulator[Key] = Items;
    return accumulator;
  }, {});
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GLOSSARY_START:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_GLOSSARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        articles: convertToHashMap(action.payload),
      };
    case FETCH_GLOSSARY_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
