import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, WithCustomColumnsGrid, withSitecoreContext } from '@/js/HOC';
import './style.scss';

const MiscTextBlockItem = ({ fields }) => {
  return (
    <article className="misctext-block-item">
	{fields.Subtitle?.value && <Text tag="h6" className="misctext-block-item__subtitle" field={fields.Subtitle} />}
      {fields?.Title?.value && (
        <h4 className="misctext-block-item__title">
          <Text field={fields.Title} />
        </h4>
      )}
      <div className="list-square">{fields?.Text?.value && <RichText field={fields.Text} />}</div>
    </article>
  );
};

export default compose(withSitecoreContext(), ExpEditor, WithCustomColumnsGrid)(MiscTextBlockItem);
