import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import { Tabs, Tab } from 'react-bootstrap';
import classNames from 'classnames';
import './styles.scss';

const LocationBlock = ({ fields, rendering, additionalClasses, additionalStyles, tabItems, isExperienceEditor }) => {
  const renderItems = () => {
    return tabItems?.map((Component) => {
      const tabTitle = Component.props?.fields?.TabTitle;
      return (
        <Tab tabClassName={'h5'} key={Component.key} eventKey={tabTitle?.value} title={<Text field={tabTitle} />}>
          {Component}
        </Tab>
      );
    });
  };

  return (
    <section
      className={classNames({
        location: true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        {fields.Title?.value && <Text className={'location__title'} tag={'h4'} field={fields.Title} />}
        {isExperienceEditor ? (
          <Placeholder rendering={rendering} name="jss-contact-location" />
        ) : (
          <div className="tabs">
            <Tabs>{renderItems()}</Tabs>
          </div>
        )}
      </div>
    </section>
  );
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-contact-location',
    prop: 'tabItems',
  }),
  ExpEditor,
  ThemeResolver
)(LocationBlock);
