import React, { useContext, useRef } from 'react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import { canUseDOM } from '@/js/utils';
import { ModalDispatchContext, ActionTypes } from '@/js/context/ModalContext';
import JssForm from '../JssForm';
import './styles.scss';

const ContactUsForm = (props) => {
  const dispatch = useContext(ModalDispatchContext);
  const trackFormData = useRef(true);

  const onSubmit = () => {
    dispatch({
      type: ActionTypes.SHOW_MODAL,
      payload: {
        modalName: 'SuccessContactUsModal',
      },
    });
  };

  return <JssForm {...props} onSubmit={onSubmit} isTrack={canUseDOM() && trackFormData.current} />;
};

export default compose(withSitecoreContext(), ExpEditor)(ContactUsForm);
