import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const LinkOpenIcon = ({ type }) => {
  return (
    <div
      className={classNames({
        'link-open-icon': true,
        'link-open-icon--picture': type === 2,
        'link-open-icon--no-bg': type === 3,
      })}
    >
      <i className="fas fa-arrow-up" />
    </div>
  );
};

export default LinkOpenIcon;
