import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const FooterTerms = (props) => {
  return (
    <nav className="footer-terms">
      {props.fields &&
        Object.keys(props.fields).map((field) => {
          const link = props.fields[field];
          return (
            <NavLink
              key={link.title}
              activeClassName="footer-terms__link--active"
              className="footer-terms__link"
              to={link.url}
            >
              <Text field={{ value: link.title }} />
            </NavLink>
          );
        })}
    </nav>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(FooterTerms);
