import React from 'react';
import i18n from 'i18next';
import classNames from 'classnames';

const handleInputChange = (props, trackForm) => (name, value, isValid, errorMessages) => {
  const fieldId = props.field.model.itemId;

  if (!isValid) {
    errorMessages = [];
    errorMessages.push(props.field.model.title + i18n.t('FormsContactsMessagesIsRequired'));
  }

  if (trackForm && value) {
    sessionStorage.setItem(fieldId, value);
  }

  props.onChange(name, value, isValid, errorMessages);
};

export const TextField = (Component, fieldSetting) => (props) => {
  const componentProps = {
    ...props,
    onChange: handleInputChange(props, fieldSetting.trackForm),
  };

  const classes = classNames({
    'form-control-text-field': true,
    'has-value': props.value,
  });

  return (
    <div className={classes}>
      <Component {...componentProps} />
    </div>
  );
};
