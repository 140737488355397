import { useEffect, useState, useRef } from 'react';

const useIntersection = ({ root = null, rootMargin, threshold = 0 }, multiple = false) => {
  const [entry, updateEntry] = useState();
  const [observeTarget, setObserveTarget] = useState();

  const observer = useRef(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entry) => {
      updateEntry(multiple ? entry : entry[0]);
    }, {
      root,
      rootMargin,
      threshold,
    });
    const { current: currentObserver } = observer;
    if(observeTarget) {
      if(multiple) {
        observeTarget.forEach(target => {currentObserver.observe(target);});
      } else {
        currentObserver.observe(observeTarget);
      }
    }
    return () => currentObserver.disconnect();
  }, [observeTarget, root, rootMargin, threshold]);

  return [setObserveTarget, entry, observer.current];
};

export default useIntersection;
