import React, { useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import './style.scss';

const SideMenu = ({ open, setOpen, header, body, headerClassName, bodyClassName }) => {
  const scrollArea = useRef();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    clearAllBodyScrollLocks();
    open ? disableBodyScroll(scrollArea.current) : enableBodyScroll(scrollArea.current);
    document.body.classList.toggle('modal-open', open);
  }, [open]);

  const handleCloseClick = () => {
    setOpen(false);
  };

  return (
    <div className={`side-menu ${open ? 'open' : ''}`}>
      <div className="side-menu__backdrop" onClick={handleCloseClick} />
      <div className="side-menu__wrap" ref={scrollArea}>
        <div className="side-menu__inner">
          <div className={`side-menu__header ${headerClassName ? headerClassName : ''}`}>{header}</div>
          <div className={`side-menu__body ${bodyClassName ? bodyClassName : ''}`}>{body}</div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
