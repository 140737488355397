import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import PostCardPresentationalComponent from '../PostCardPresentation';
import PostPreviewPresentationalComponent from '../PostPreviewPresentation';
import Preloader from '../UI/Preloader';
import CTA from '../UI/CTA';
import { DateFormatter } from '@/js/utils';
import './styles.scss';

const AuthorPosts = ({ posts, noResultMessage, loadBtnLabel, isLoading, currentPage, totalPages, onLoadMore }) => {
  const showLoadMoreBtn = currentPage < totalPages;

  const handleLoadMoreClick = () => {
    onLoadMore(currentPage + 1);
  };

  return (
    <div className="author-posts">
      <div className="row author-posts__list">
        {!posts?.length && !isLoading
          ? noResultMessage
          : posts.map((post) => {
              const postProps = {
                url: post.Url,
                image: {
                  src: post.PreviewImage,
                  alt: post.PreviewTitle,
                  width: 715,
                  height: 475,
                },
                title: post.PreviewTitle,
                text: post.PreviewText,
                categories: post?.Category,
                platforms: post?.Platform,
                authors: post?.Authors,
                publicationDate: DateFormatter(post.DateOfPublication),
              };
              return post.IsFavorite ? (
                <div key={post.DateOfPublication} className="author-posts__item col-12">
                  <PostPreviewPresentationalComponent {...postProps} />
                </div>
              ) : (
                <div key={post.DateOfPublication} className="author-posts__item col-12 col-md-6 col-lg-4">
                  <PostCardPresentationalComponent {...postProps} />
                </div>
              );
            })}
      </div>
      {showLoadMoreBtn && (
        <div className="author-posts__footer">
          {isLoading ? (
            <Preloader show={isLoading} />
          ) : (
            <CTA tag="button" type="primary" onClick={handleLoadMoreClick}>
              <Text field={{ value: loadBtnLabel }} />
            </CTA>
          )}
        </div>
      )}
    </div>
  );
};

export default AuthorPosts;
