import React from 'react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const OurPlatformPartnersBlock = (props) => {
  const renderItems = () => {
    if (props.isExperienceEditor) {
      return <Placeholder rendering={props.rendering} name="jss-company-our-platform-partners" />;
    }
    return props.partners?.map((Component) => {
      //OurPlatformPartnersBlockItem
      return (
        <div key={Component.key} className="partners__item col-12 col-md-6 col-lg-4">
          {Component}
        </div>
      );
    });
  };

  return (
    <section
      className={classNames({
        partners: true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        {props.fields.Title?.value && <Text tag="h2" className="partners__title" field={props.fields.Title} />}
        <div className="partners__list row">{renderItems()}</div>
      </div>
    </section>
  );
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-company-our-platform-partners',
    prop: 'partners',
  }),
  ExpEditor,
  ThemeResolver
)(OurPlatformPartnersBlock);
