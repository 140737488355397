import React, { useState, useEffect } from 'react';
import './styles.scss';

const Pagination = ({ currentPage, pagesCount, maxPages = 7, onPageChange }) => {
  if (!currentPage || !pagesCount || pagesCount <= 1) {
    return null;
  }

  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const getPagingRange = () => {
    let min = 1;
    let length = maxPages;
    if (maxPages > pagesCount) {
      length = pagesCount;
    }

    let start = page - Math.floor(length / 2);
    start = Math.max(start, min);
    start = Math.min(start, min + pagesCount - length);

    const startDots = start !== min;
    const endDots = start + length - 1 !== pagesCount;

    return Array.from({ length: length }, function (el, i) {
      let value = start + i;
      if (startDots) {
        if (i === 0) value = 1;
        if (i === 1) value = 'more';
      }
      if (endDots) {
        if (i === length - 1) value = pagesCount;
        if (i === length - 2) value = 'more';
      }
      return value;
    });
  };

  const handlePageChange = (value) => (e) => {
    e.preventDefault();
    setPage(value);
    onPageChange(value);
  };

  return (
    <nav className="pagination">
      {page > 1 && (
        <div className={`page-item`}>
          <a onClick={handlePageChange(page - 1)} href="#" className={`page-link prev`}>
            <i className="fas fa-chevron-left" />
          </a>
        </div>
      )}
      {getPagingRange().map((item, i) => {
        return (
          <div key={i} className={`page-item ${page === item ? 'active' : ''}`}>
            {item == 'more' ? (
              <span>...</span>
            ) : (
              <a onClick={handlePageChange(item)} href="#" className={`page-link`}>
                {item}
              </a>
            )}
          </div>
        );
      })}
      {page < pagesCount && (
        <div className={`page-item`}>
          <a onClick={handlePageChange(page + 1)} href="#" className={`page-link next`}>
            <i className="fas fa-chevron-right" />
          </a>
        </div>
      )}
    </nav>
  );
};

export default Pagination;
