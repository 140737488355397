import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { compose, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import CustomerCardPresentationalComponent from '../CustomerCardPresentation';
import CTA from '../UI/CTA';
import { fetchInsights } from '../../js/redux/actions/InsightsOverview';
import classNames from 'classnames';
import './styles.scss';

const InsightsOverviewContainerPreview = (props) => {
  const { posts, isLoading, currentPage, countPages, staticData } = useSelector(
    (state) => state.InsightsOverviewReducer
  );
  const { NoResultMessage } = staticData;
  const dispatchRedux = useDispatch();

  const showLoadMoreBtn = currentPage < countPages;
  const isPostsLoading = !isLoading.loadMore && isLoading.value;

  const handleLoadMoreClick = () => {
    dispatchRedux(fetchInsights(currentPage + 1, true));
  };

  return (
    <section
      className={classNames({
        'blog-overview': true,
        'stories-overview': true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        {isPostsLoading && <CustomerCardPresentationalComponent isLoading={isPostsLoading} />}
        {!isPostsLoading && (
          <>
            {posts?.length <= 0 && !isPostsLoading ? (
              NoResultMessage
            ) : (
              <>
                <div className="blog-overview__list row">
                  {posts?.map((post, i) => {
                    const HorizontalType = post.ViewType === 'Horizontal';
                    const videoUrl = post.PreviewYouTubeLink || post.PreviewVideoLInk;
                    let type = 'text';
                    if (post.PreviewQuote) {
                      type = 'quote';
                    } else if (videoUrl) {
                      type = 'video';
                    }
                    return (
                      <div key={i} className={`blog-overview__item col-12${!HorizontalType ? ' col-md-6' : ''}`}>
                        <CustomerCardPresentationalComponent
                          contentType={type}
                          cardType={post.ViewType}
                          categories={post?.Category}
                          platforms={post?.Technology} // ???????? Technology vs platforms
                          title={post.PreviewTitle}
                          linkText={post.PreviewLinkLabel}
                          linkUrl={post.Url}
                          videoUrl={videoUrl}
                          logo={{
                            src: post.PreviewLogo,
                            alt: post.PreviewTitle,
                          }}
                          image={{
                            src: post.PreviewImage,
                            alt: post.PreviewTitle,
                            width: 600,
                            height: 400,
                          }}
                          extLinkUrl={post.PreviewExtLink}
                          extLinkTitle={post.PreviewExtLinkTitle}
                          quote={post.PreviewQuote}
                        />
                      </div>
                    );
                  })}
                </div>
                {showLoadMoreBtn && (
                  <div className="blog-overview__footer">
                    {!isLoading.value ? (
                      <CTA tag="button" type="primary" onClick={handleLoadMoreClick}>
                        <Text field={{ value: i18n.t('ComponentsInsightsOverviewLoadMore') }} />
                      </CTA>
                    ) : (
                      isLoading.loadMore && <CustomerCardPresentationalComponent isLoading={isLoading.value} />
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ThemeResolver)(InsightsOverviewContainerPreview);
