import React, { useMemo } from 'react';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import BtnTag from '../UI/BtnTag';
import classNames from 'classnames';
import './styles.scss';

const FilterDropdown = (props) => {
  const {
    label,
    selected,
    disabled,
    options,
    defaultOption,
    selectedOptionId,
    onClick,
    onChange,
    split = false,
    selectedOptionAsLabel = false,
  } = props;

  const hasItems = useMemo(() => options?.length > 0, [options]);

  const isSelected = useMemo(
    () => selected || (selectedOptionId && selectedOptionId !== defaultOption),
    [selected, selectedOptionId, defaultOption]
  );

  const selectedOption = useMemo(
    () => options?.find((option) => option.Id === selectedOptionId) || null,
    [options, selectedOptionId]
  );

  const dropdownLabel = selectedOptionAsLabel && selectedOption ? selectedOption?.Name : label;

  return (
    <Dropdown
      as={ButtonGroup}
      className={classNames({
        'filter-dropdown': true,
        'dropdown-split': split && hasItems,
      })}
    >
      {split && (
        <BtnTag onClick={onClick} selected={isSelected} disabled={!isSelected && disabled}>
          {dropdownLabel}
        </BtnTag>
      )}
      {hasItems && (
        <>
          <Dropdown.Toggle
            split={split}
            className={classNames({
              'btn-tag': true,
              selected: isSelected,
            })}
          >
            {!split && dropdownLabel}
          </Dropdown.Toggle>
          <Dropdown.Menu flip={false}>
            {defaultOption && (
              <Dropdown.Item
                key={defaultOption}
                active={!selectedOption?.Id || selectedOption?.Id === defaultOption}
                onClick={onChange({ Id: null })}
              >
                {defaultOption}
              </Dropdown.Item>
            )}
            {options?.map((option) => {
              return (
                <Dropdown.Item
                  key={option.Id}
                  active={option.Id === selectedOption?.Id}
                  disabled={option.Disabled}
                  onClick={onChange(option)}
                >
                  {option.Name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </>
      )}
    </Dropdown>
  );
};

export default FilterDropdown;
