import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './style.scss';

const Contacts = (props) => {
  return (
    <div className={`contacts ${props.mobile ? 'contacts--mobile' : 'contacts--desktop'}`}>
      <ul className="contacts__list list-reset">
        {props.fields?.Email?.value && (
          <li className="contacts__item">
            <a href={`mailto:${props.fields.Email.value}`} className="contacts__link contacts__link--dark">
              <Text field={props.fields.Email} />
            </a>
          </li>
        )}
        {props.fields?.PhoneLabel?.value && (
          <li className="contacts__item">
            <a href={`tel:${props.fields.Phone.value}`} className="contacts__link contacts__link--mail">
              <Text field={props.fields.PhoneLabel} />
            </a>
          </li>
        )}
      </ul>
      {!props.mobile && props.fields.Address?.value && <RichText field={props.fields.Address} />}
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(Contacts);
