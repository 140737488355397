import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import './styles.scss';

const Filter = (props) => {
  return (
    <div
      className={classNames({
        filter: true,
        'filter--mobile': !props.isDesktop,
        'filter--desktop': props.isDesktop,
        disabled: props.isLoading.value,
        loaded: props.isLoaded,
      })}
    >
      <div className="filter__list row">
        {props.children}
        <div className="filter__item filter__item--reset col-auto">
          {props.resetBtnLabel?.value && (
            <div className="filter__item-control">
              <button className="tag reset-link" disabled={props.isInitialState} onClick={props.resetFilter}>
                <Text field={props.resetBtnLabel} /> <i className="fas fa-trash" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;
