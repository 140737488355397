import React from 'react';
import Preloader from '../../components/UI/Preloader';
import classNames from 'classnames';

const WithLoader = ({ isLoading, children, noResultMessage, itemsLength, loaderWithResults = false }) => {
  const results = loaderWithResults ? (
    <div className={classNames({ preloader__wrap: true, 'is-loading': isLoading })}>
      <Preloader show={isLoading} absolute />
      {children}
    </div>
  ) : (
    <div className="preloader__wrap">{isLoading ? <Preloader show={isLoading} /> : children}</div>
  );
  return <>{itemsLength <= 0 && !isLoading ? noResultMessage : results}</>;
};

export default WithLoader;
