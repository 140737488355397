import React, { useEffect, useRef } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import GoogleMap from '../UI/GoogleMap';
import useIntersection from '@/js/hooks/useIntersection';
import ImageComponent from '../UI/Image';

const LocationGroup = ({ fields, rendering, locationsItems }) => {
  const locations = useRef([]);
  const [ref, entry, observer] = useIntersection({ rootMargin: '0px 0px 200px 0px' });

  const isIntersecting = entry?.isIntersecting;

  useEffect(() => {
    if (isIntersecting) {
      observer && observer.disconnect();
    }
  }, [isIntersecting]);

  locations.current = locationsItems?.map((Component) => {
    const fields = Component.props?.fields;
    return {
      lat: Number(fields?.Latitude?.value) || 0,
      lng: Number(fields?.Longitude?.value) || 0,
      text: fields?.Title?.value || '',
    };
  });

  return (
    <div className="location-item">
      <Placeholder rendering={rendering} name="jss-contact-location-group" />
      <div className="location-item__body">
        <div className="row no-gutters">
          {fields.Image?.value?.src && (
            <div className="col-12 col-md-6 location-item__pic-wrap">
              <ImageComponent imageData={fields.Image.value} />
            </div>
          )}
          <div className="col-12 col-md-6 location-item__map-wrap" ref={ref}>
            {isIntersecting && <GoogleMap location={locations.current} zoom={Number(fields.MapsZoom?.value)} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-contact-location-group',
    prop: 'locationsItems',
  }),
  ExpEditor
)(LocationGroup);
