import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Tag = ({ children, onClick, tag, selected }) => {
  const Tag = tag || 'button';
  return (
    <Tag
      onClick={onClick}
      className={classNames({
        tag: true,
        selected: selected,
      })}
    >
      {children}
    </Tag>
  );
};

export default Tag;
