import React from 'react';
import {
  borderColorResolver,
  textColorResolver,
  backgroundResolver,
  backgroundImageResolver,
  beveledCornerBorderResolver,
} from '@/js/utils/themeElementsResolver';

const ThemeResolver = (Component) => (props) => {
  const theme = backgroundResolver(props.params?.Theme);
  const textColorClass = textColorResolver(props.params?.TextColor);
  const borderColorClass = borderColorResolver(props.params?.GridBorderColor);
  const image = backgroundImageResolver(props.params?.BackgroundImage);
  const blockHasBeveledCorners = props.params?.HasBeveledCorners === '1';
  const beveledCornerBorder = beveledCornerBorderResolver(blockHasBeveledCorners, props.params?.CornerColor);

  const additionalClasses = {
    'skew-bg': blockHasBeveledCorners,
    [theme]: true,
    [borderColorClass]: !!props.params?.GridBorderColor,
    [textColorClass]: !!props.params?.TextColor,
  };

  if(beveledCornerBorder) {
    additionalClasses[beveledCornerBorder] = true;
    additionalClasses['has-beveled-corner'] = true;
  }

  const additionalStyles = {
    ...image,
  };

  return <Component
    drawBeveledBorder={!!beveledCornerBorder}
    additionalClasses={additionalClasses}
    additionalStyles={additionalStyles}
    {...props}
  />;
};

export default ThemeResolver;
