import React from 'react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const IntegrationsBlock = (props) => {
  const renderItems = () => {
    if (props.isExperienceEditor) {
      return <Placeholder rendering={props.rendering} name="jss-services-integrations" />;
    }
    return props.services?.map((Component) => {
      //IntegrationsBlockItem
      return (
        <div key={Component.key} className={`integration-block__item col-12 col-lg-4 col-md-6`}>
          {Component}
        </div>
      );
    });
  };

  return (
    <section
      className={classNames({
        'integration-block': true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        {props.fields?.Title?.value && (
          <Text className="integration-block__title" field={props.fields.Title} tag="h2" />
        )}
        <div className="integration-block__list row">{renderItems()}</div>
      </div>
    </section>
  );
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-services-integrations',
    prop: 'services',
  }),
  ExpEditor,
  ThemeResolver
)(IntegrationsBlock);
