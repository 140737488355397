import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const OurAwardsBlock = ({ isExperienceEditor, rendering, additionalClasses, additionalStyles, fields, awards }) => {
  const renderItems = () => {
    if (isExperienceEditor) {
      return <Placeholder rendering={rendering} name="jss-company-our-awards" />;
    }
    return awards?.map((Component) => {
      //OurCustomerStoriesBlockItem
      return (
        <div key={Component.key} className={`our-awards-block__item col-6 col-md-4 col-lg-2`}>
          {Component}
        </div>
      );
    });
  };

  return (
    <section
      className={classNames({
        'our-awards-block': true,
        'bg-gray': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        {fields?.Title?.value && <Text className="our-awards-block__title" field={fields.Title} tag="h2" />}
        <div className="our-awards-block__list row">{renderItems()}</div>
      </div>
    </section>
  );
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-company-our-awards',
    prop: 'awards',
  }),
  ExpEditor,
  ThemeResolver
)(OurAwardsBlock);
