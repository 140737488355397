import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import ImageComponent from '../UI/Image';
import { compose, ExpEditor, WithCustomColumnsGrid, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const BenefitsBlockItem = ({ fields }) => {
  return (
    <article className="benefits-item">
      <div className="benefits-item__pic-wrap">
        {fields.Image?.value?.src && <ImageComponent imageData={fields.Image.value} />}
      </div>
      <div className="benefits-item__text-wrap">
        {fields.Subtitle?.value && <Text tag="h6" className="benefits-item__subtitle" field={fields.Subtitle} />}
        {fields.Title?.value && (
          <h4 className="benefits-item__title">
            <Text field={fields.Title} />
          </h4>
        )}
        {fields.ListText?.value && <RichText field={fields.ListText} />}
      </div>
    </article>
  );
};

export default compose(withSitecoreContext(), ExpEditor, WithCustomColumnsGrid)(BenefitsBlockItem);
