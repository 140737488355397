import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link as JssLink } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import './styles.scss';

const tagsMap = {
  navlink: NavLink,
  link: JssLink,
  a: 'a',
  button: 'button',
  span: 'span',
};

const ctaTypesMap = {
  accent: 'cta-accent',
  primary: 'cta-primary',
  secondary: 'cta-secondary',
  light: 'cta-light',
};

const CTA = ({
  tag = tagsMap.button,
  type = 'accent',
  size,
  arrowRight,
  arrowLeft,
  className = '',
  to,
  href,
  onClick,
  children,
  disabled,
}) => {
  const TagName = tagsMap[tag];
  const ctaType = ctaTypesMap[type];

  const ctaClasses = classNames({
    cta: true,
    [ctaType]: true,
    'cta-lg': size === 'lg',
    'cta-arrow-right': arrowRight,
    'cta-arrow-left': arrowLeft,
    disabled: disabled,
    [className]: className.length,
  });

  return (
    <TagName onClick={onClick} to={to} href={href} className={ctaClasses}>
      <span className="cta-text">{children}</span>
    </TagName>
  );
};

export default CTA;
