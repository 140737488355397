import React from 'react';
import { Text, RichText, Placeholder, Image } from '@sitecore-jss/sitecore-jss-react';
import Logo from '../Logo';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const Footer = ({ fields, rendering }) => {
  return (
    <footer className="footer theme-dark">
      <div className="container">
        <div className="footer__top">
          <div className="row">
            <div className="footer__col--logo col-12 col-lg-5">
              <div className="footer__logo-wrap">
                <Logo darkTheme fullSize />
              </div>
              {fields.IntroText?.value && <RichText tag={'div'} className="footer__title" field={fields.IntroText} />}
              <div className="footer__navigation-wrap">
                <Placeholder name="jss-footer-navigation" rendering={rendering} />
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-3">
              <Placeholder name="jss-footer-links-1" rendering={rendering} />
            </div>
            <div className="footer__col--menu col-12 col-md-3 col-lg-2">
              <Placeholder name="jss-footer-links-2" rendering={rendering} />
            </div>
            <div className="col-12 col-md-4 col-lg-2">
              <Placeholder name="jss-footer-contacts" rendering={rendering} />
            </div>
          </div>
        </div>

        <div className="footer__bottom">
          <div className="footer__bottom-terms">
            <Placeholder name="jss-footer-terms" rendering={rendering} />
          </div>
          <div className="footer__bottom-logo">
            {fields.FooterLogo?.value?.src && <Image field={fields.FooterLogo}></Image>}
          </div>
          <div className="footer__bottom-copyright">
            {fields.Copyright?.value && <Text tag={'div'} field={fields.Copyright} />}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(Footer);
