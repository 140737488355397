import axios from 'axios';

export const FETCH_BLOGPOSTS_START = 'FETCH_BLOGPOSTS_START';
export const FETCH_BLOGPOSTS_SUCCESS = 'FETCH_BLOGPOSTS_SUCCESS';
export const FETCH_BLOGPOSTS_ERROR = 'FETCH_BLOGPOSTS_ERROR';
export const SET_FILTER_PARAMS = 'SET_FILTER_DATA';
export const SET_STATIC_DATA = ' SET_STATIC_DATA';

export const setStaticData = (data) => {
  return {
    type: SET_STATIC_DATA,
    payload: data,
  };
};

export const setFilterParams = (filterParams) => {
  return {
    type: SET_FILTER_PARAMS,
    payload: filterParams,
  };
};

export const fetchDataStart = (loadMore) => {
  return {
    type: FETCH_BLOGPOSTS_START,
    loadMore,
  };
};

export const fetchDataSuccess = (blogposts) => {
  return {
    type: FETCH_BLOGPOSTS_SUCCESS,
    payload: blogposts,
  };
};

export const fetchDataError = (errorMessage) => {
  return {
    type: FETCH_BLOGPOSTS_ERROR,
    errorMessage: errorMessage,
  };
};

export const fetchBlogPosts =
  (page, loadMore = false) =>
  async (dispatch, getState) => {
    const { ApiUrl, PageSize, SettingId } = getState().BlogOverviewReducer.staticData;
    const formData = {
      ...getState().BlogOverviewReducer.filterParams,
      Page: page || getState().BlogOverviewReducer.currentPage,
      PageSize,
      SettingId,
    };
    dispatch(fetchDataStart(loadMore));
    axios
      .post(ApiUrl, formData)
      .then(function (response) {
        dispatch(
          fetchDataSuccess({
            posts: response.data.SearchResult,
            currentPage: response.data.CurrentPage,
            countPages: response.data.CountPages,
            categories: response.data.AvailableCategories,
            platforms: response.data.AvailablePlatforms,
            products: response.data.AvailableProducts,
            authors: response.data.AvailableAuthors,
            count: response.data.Count,
            loadMore,
          })
        );
      })
      .catch(function () {
        dispatch(fetchDataError('error'));
      });
  };
