import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ImageComponent from '../UI/Image';
import CTA from '../UI/CTA';
import { NavLink } from 'react-router-dom';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import './styles.scss';

const LinkIconItem = ({ fields }) => {
  return (
    <>
      {fields.Link?.value?.href && (
        <NavLink className="link-row-item theme-dark link-row-item--green" to={fields.Link.value.href}>
          <LinkOpenIcon type={3} />
          <div className="link-row-item__wrap">
            {fields.Image?.value?.src && (
              <ImageComponent className="link-row-item__icon" imageData={fields.Image.value} />
            )}
          </div>
          {fields.LinkLabel?.value && (
            <CTA tag={'button'} type="secondary">
              <Text field={fields.LinkLabel} />
            </CTA>
          )}
        </NavLink>
      )}
    </>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(LinkIconItem);
