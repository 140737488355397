import {
  FETCH_TERM_START,
  FETCH_TERM_SUCCESS,
  FETCH_TERM_ERROR,
  SET_STATIC_DATA,
  HIDE_TOOLTIP,
  FETCH_TERM_FINISH,
  CLEAR_TOOLTIP,
} from '../actions/TermTooltip';

const initialState = {
  apiUrl: '',
  title: '',
  linkTitle: '',
  errorMessage: '',
  error: false,
  isLoading: false,
  show: false,
  term: '',
  target: null,
  linkUrl: '',
  previewText: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATIC_DATA:
      const { apiUrl, title, linkTitle, errorMessage } = action.payload;
      const dataToUpdate = {};
      if(state.apiUrl !== apiUrl) dataToUpdate.apiUrl = apiUrl;
      if(state.title !== title) dataToUpdate.title = title;
      if(state.linkTitle !== linkTitle) dataToUpdate.linkTitle = linkTitle;
      if(state.errorMessage !== errorMessage) dataToUpdate.errorMessage = errorMessage;
      return {
        ...state,
        ...dataToUpdate,
      };

    case FETCH_TERM_START:
      return {
        ...state,
        show: true,
        isLoading: true,
        target: action.payload.target,
        term: action.payload.term,
        linkUrl: initialState.linkUrl,
        previewText: initialState.previewText,
      };

    case FETCH_TERM_SUCCESS:
      return {
        ...state,
        error: initialState.error,
        linkUrl: action.payload.Url,
        previewText: action.payload.PreviewText,
      };

    case FETCH_TERM_ERROR:
      return {
        ...state,
        error: true,
        linkUrl: initialState.linkUrl,
        previewText: initialState.previewText,
      };

    case FETCH_TERM_FINISH:
      return {
        ...state,
        isLoading: false,
      };

    case HIDE_TOOLTIP:
      return {
        ...state,
        show: false,
        error: initialState.error,
        linkUrl: initialState.linkUrl,
        previewText: initialState.previewText,
      };

    case CLEAR_TOOLTIP:
      return {
        ...state,
        error: initialState.error,
        term: initialState.term,
        target: initialState.target,
      };

    default: {
      return state;
    }
  }
};

export default reducer;
