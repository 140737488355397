import React, { useEffect, useRef } from 'react';
import useIntersection from '@/js/hooks/useIntersection';

const WithIntersectionBlock = (Component) => (props) => {
  const [ref, entries, observer] = useIntersection({ threshold: 1, rootMargin: '0px' }, true);
  const blockRef = useRef();

  useEffect(() => {
    const nodesToObserve = blockRef.current.querySelectorAll('.filling-highlight');
    ref(Array.from(nodesToObserve));
  }, []);

  useEffect(() => {
    if(entries) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('filling-highlight--filled');
          observer.unobserve(entry.target);
        }
      });
    }
  }, [entries]);

  return (
    <div ref={blockRef}>
      <Component {...props} />
    </div>
  )
};

export default WithIntersectionBlock;
