import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Overlay , Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { tooltipClose } from '../redux/actions/TermTooltip';

const TermTooltip = () => {
  const TermTooltipReducer = useSelector((state) => state.TermTooltipReducer);
  const dispatchRedux = useDispatch();

  const linkClickHandler = (e) => {
    dispatchRedux(tooltipClose());

    if(!TermTooltipReducer.linkUrl) {
      e.preventDefault();
    }
  }

  const tooltipLeaveHandler = (e) => {
    if(!e.nativeEvent.relatedTarget || !e.nativeEvent.relatedTarget.closest('.rt-tooltip')) {
      dispatchRedux(tooltipClose());
    }
  }

  return <Overlay placemet="top" target={TermTooltipReducer.target} show={TermTooltipReducer.show}>
    {(props) => {
      return (
      <Tooltip id="term-tooltip-overlay" {...props} onMouseLeave={tooltipLeaveHandler}>
        <div className="glossary-tip-content">
          {
            TermTooltipReducer.error ?
            <div className="glossary-tip-intro">{TermTooltipReducer.errorMessage}</div>
            :
            <>
              <h5 className="glossary-tip-title">{TermTooltipReducer.title}</h5>
              <p className="glossary-tip-intro">{TermTooltipReducer.previewText}</p>
              <p className="glossary-tip-mdt">
                <Link className="glossary-tip-mdt-link" onClick={linkClickHandler} to={TermTooltipReducer.linkUrl}>
                  {TermTooltipReducer.linkTitle}
                </Link>
              </p>
            </>
          }
        </div>
      </Tooltip>
    )}}
  </Overlay>
}

export default TermTooltip;
