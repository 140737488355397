import React, { useRef, useEffect, useState } from 'react';
import { FileUpload } from '@sitecore-jss/sitecore-jss-react-forms';
import PreviewFile from '../PreviewFile/index';
import { SIZE_UNITS } from '@/js/utils';
import useDragNDrop from "@/js/hooks/useDragNDrop";
import i18n from 'i18next';
import './styles.scss';

const downloadIcon = <svg className="box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43">
  <path
      d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
</svg>;

const getMetaMessage = (props) => {
  let message = i18n.t('FormsContactsMessagesFileUpload');
  message = message.replace('{0}', props.field.model.allowedContentTypes);
  message = message.replace('{1}', props.field.model.maxFileSize + " " + SIZE_UNITS[props.field.model.fileSizeUnit]);
  message = message.replace('{2}', props.field.model.maxFileCount);
  return message;
};

const JssFileUpload = (props) => { // toDo clear files onSubmitSuccess
  const fileUploadInstance = useRef(null);
  const fileInputRef = useRef(null);
  const isMultiple = useRef(props.field.model.isMultiple);
  const dropArea = useRef(null);
  const [files, setFiles] = useState([]);

  const filterExistingFiles = (items) => {
    return items.filter((item) => {
      return !files.some((file) => {
        return file.name === item.name;
      });
    });
  };

  const handleInputChange = (name, value, valid, errorMessages) => {
    let isValid = errorMessages.length === 0 && valid;
    const newFiles = Array.from(value);

    if (isValid) {
      const validFiles = isMultiple.current
          ? files.concat(filterExistingFiles(newFiles))
          : newFiles;
      handleFilesChange(validFiles);
    } else {
      props.onChange(name, value, isValid, errorMessages);
    }
    fileInputRef.current.value = '';
  };

  const handleFilterRemove = (file) => {
    if (file) {
      handleFilesChange(files.filter((item) => item.name !== file.name))
    }
  };

  const handleFilesChange = (files) => {
    setFiles(files);
    props.onChange(props.field.valueField.name, files, true, []);
  }

  const loadFile = () => {
    fileInputRef.current?.click();
  };

  const highlight = () => {
    dropArea.current?.classList.add('highlight');
  }

  const unHighlight = () => {
    dropArea.current?.classList.remove('highlight');
  }

  const handleDrop = (e) => {
    let dt = e.dataTransfer
    let files = Array.from(dt.files);

    fileUploadInstance.current?.onChangeField(files, props.field, fileUploadInstance.current.props.onChange);
  }

  useDragNDrop({
    el: dropArea.current,
    onDragEnter: highlight,
    onDragLeave: unHighlight,
    onDrop: handleDrop,
  });

  useEffect(() => {
    fileInputRef.current = fileUploadInstance.current?.fileInputRef.current;
  }, []);

  return (
    <div className="file-upload">
      <div className="file-upload__area" onClick={loadFile} ref={dropArea}>
        {files.length > 0 ? (
          <div className="file-upload__preview">
            {files.map((file, i) => (
              <div className="file-upload__preview-item" key={file.name}>
                <PreviewFile
                  file={file}
                  onRemove={handleFilterRemove}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="file-upload__label">
            <div className="file-upload__icon">{downloadIcon}</div>
            {props.field.model.title}
          </div>
        )}
      </div>
      <div className="file-upload__meta">
        {getMetaMessage(props)}
      </div>
      <FileUpload {...props} onChange={handleInputChange} ref={fileUploadInstance} />
    </div>
  );
};

export default JssFileUpload;