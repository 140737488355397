import { combineReducers } from 'redux';
import BlogOverviewReducer from './BlogOverview';
import StoriesOverviewReducer from './StoriesOverview';
import GlossaryOverviewReducer from './GlossaryOverview';
import TermTooltipReducer from './TermTooltip';
import InsightsOverviewReducer from './InsightsOverview';

export default combineReducers({
  BlogOverviewReducer,
  StoriesOverviewReducer,
  GlossaryOverviewReducer,
  TermTooltipReducer,
  InsightsOverviewReducer,
});
