import axios from 'axios';

export const FETCH_GLOSSARY_START = 'FETCH_GLOSSARY_START';
export const FETCH_GLOSSARY_SUCCESS = 'FETCH_GLOSSARY_SUCCESS';
export const FETCH_GLOSSARY_ERROR = 'FETCH_GLOSSARY_ERROR';

export const fetchDataStart = () => {
  return {
    type: FETCH_GLOSSARY_START,
  };
};

export const fetchDataSuccess = (payload) => {
  return {
    type: FETCH_GLOSSARY_SUCCESS,
    payload,
  };
};

export const fetchDataError = (errorMessage) => {
  return {
    type: FETCH_GLOSSARY_ERROR,
    errorMessage,
  };
};

export const fetchGlossaryArticles = ({ url, pageId }) => (dispatch) => {
  dispatch(fetchDataStart());
  axios
    .post(url, {
      currentId: pageId
    })
    .then((result) => {
      dispatch(fetchDataSuccess(result.data));
    })
    .catch((error) => {
      dispatch(fetchDataError(error));
    });
};
