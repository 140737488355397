import React from 'react';
import { NavLink } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';

const LinksBlock = (props) => {
  return (
    <>
      <div className="footer__title">
        <Text field={{ value: props.fields.title }} />
      </div>

      <ul className="footer__menu list-reset">
        {props.fields.children &&
          Object.keys(props.fields.children).map((key) => {
            const link = props.fields.children[key];
            return (
              <li key={link.title}>
                <NavLink to={link.url}>
                  <Text field={{ value: link.title }} />
                </NavLink>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(LinksBlock);
