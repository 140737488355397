import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import { breakpoints } from '@/js/utils';
import useBreakpoint from '@/js/hooks/useBreakpoint';
import Slider from '../UI/Slider';
import classNames from 'classnames';
import './styles.scss';

const OurCustomerStoriesBlock = (props) => {
  const { isDesktop } = useBreakpoint();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: breakpoints.screen_xs_max,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '30px',
        },
      },
    ],
  };

  const renderItems = () => {
    if (props.isExperienceEditor) {
      return <Placeholder rendering={props.rendering} name="jss-company-our-customer-stories" />;
    }
    return props.stories?.map((Component) => {
      //OurCustomerStoriesBlockItem
      return (
        <div key={Component.key} className={`customer-stories__item ${isDesktop ? 'col-12 col-lg-6' : ''}`}>
          {Component}
        </div>
      );
    });
  };

  return (
    <section
      className={classNames({
        'customer-stories': true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        {props?.fields?.SubTitle?.value && <Text tag="h6" className="customer-stories__subtitle" field={props.fields.SubTitle} />}
        <div className="row">
          <div className="col col-12 col-lg-6">
            {props?.fields?.Title?.value && (<Text tag="h2" className="customer-stories__title" field={props.fields.Title} />)}
            {props?.fields?.Text?.value && <RichText field={props.fields.Text} />}
          </div>
          <div className="col col-12 col-lg-5 offset-lg-1">
            <div className="customer-stories__body">
              {!isDesktop && !props.isExperienceEditor ? (
                <Slider {...settings}>{renderItems()}</Slider>
              ) : (
                <div className="customer-stories__list row">{renderItems()}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-company-our-customer-stories',
    prop: 'stories',
  }),
  ExpEditor,
  ThemeResolver
)(OurCustomerStoriesBlock);
