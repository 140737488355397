import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const MiscTextBlock = ({ fields, rendering, additionalClasses, additionalStyles, drawBeveledBorder }) => {
  return (
    <section
      className={classNames({
        'misctext-block': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        <div className="misctext-block__list row">
          <Placeholder
            customColumns={fields.Columns?.fields.Value?.value}
            name="jss-common-misctext"
            rendering={rendering}
          />
        </div>
      </div>
      {drawBeveledBorder && <div className="beveled-border-maker" />}
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(MiscTextBlock);
