import React from 'react';
import { compose, ExpEditor } from '@/js/HOC';
import ImageComponent from '../UI/Image';
import './styles.scss';

const FullWidthImage = ({ fields }) => {
  const blockAttr = { className: 'full-width-visual' };

  if(fields.Height?.value) {
    blockAttr.style = {
      '--blockMaxHeight': `${fields.Height?.value}px`,
    }
  }

  return fields.Image?.value?.src && (
    <div {...blockAttr}>
      <ImageComponent className="full-width-visual__image" imageData={{...fields.Image.value, title: fields.Image.value.alt}} />
    </div>
  );
};

export default compose(ExpEditor)(FullWidthImage);
