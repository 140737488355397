import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import CTA from '../UI/CTA';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import i18n from 'i18next';
import { NavLink } from 'react-router-dom';
import './styles.scss';

const RelatedBlogpost = (props) => {
  return (
    <NavLink className="story-card" to={props.fields.url}>
      <LinkOpenIcon type={3} />
      <div className="story-card__info">
        <div className="story-card__cats">
          <h6 className="story-card__cat-item">
            {i18n.t('BlogsRelatedPost')}
          </h6>
        </div>
        {props.fields.PostTitle?.value && <Text className="story-card__title" field={props.fields.PostTitle} tag={'h4'} />}
      </div>
      {props.fields.PreviewLinkLabel?.value && (
        <CTA tag={'button'} type="secondary" className="text-nowrap ml-auto">
          <Text field={props.fields.PreviewLinkLabel} />
        </CTA>
      )}
    </NavLink>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(RelatedBlogpost);
