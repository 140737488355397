import React from 'react';
import pin from './img/pin.svg';

const MapPin = ({ text }) => {
  return (
    <div style={{ width: '42px', transform: 'translate(-50%, -100%)' }} className="pin">
      <img src={pin} alt="map-pin" title={text} />
    </div>
  );
};

export default MapPin;
