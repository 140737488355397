import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../UI/CTA';
import CustomerCard from '../CustomerCard';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const OurCustomers = ({ fields, additionalClasses, additionalStyles }) => {
  return (
    <section
      className={classNames({
        'our-customers': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        <div className="our-customers__header">
          {fields.Title?.value && <Text tag={'h6'} className="our-customers__label" field={fields.Title} />}
          {fields.IntroText?.value && <Text tag={'h2'} className="our-customers__title" field={fields.IntroText} />}
        </div>

        <div className="our-customers__body">
          <div className="our-customers__list">
            {fields.Stories?.map((item) => {
              return (
                <div key={item.id} className="our-customers__item">
                  <CustomerCard url={item.url} fields={item.fields} />
                </div>
              );
            })}
          </div>
        </div>

        <div className="our-customers__footer">
          {fields.LinkTitle?.value && (
            <CTA tag={'navlink'} to={fields.Link.value.href} type="primary" arrowRight>
              <Text field={fields.LinkTitle} />
            </CTA>
          )}
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(OurCustomers);
