import React, { useRef, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import { textColorResolver } from '@/js/utils/themeElementsResolver';
import classNames from 'classnames';
import './styles.scss';

const Navigation = ({ mobile, fields, params}) => {
  const history = useHistory();
  const primaryNavRef = useRef();
  const [level3ShadowShow, setLevel3ShadowShow] = useState(false);

  useEffect(() => {
    if(mobile) {
      ['nav-action-inactive', 'nav-action-active', 'nav-action-hide', 'nav-action-show'].forEach(cssClass => {
        primaryNavRef.current.querySelectorAll(`.${cssClass}`).forEach(nodeItem => {nodeItem.classList.remove(cssClass)});
      });
    }
  }, [history.location.pathname]);

  const handleCollapseClick = (e) => {
    const el = e.target;
    if (!el || !mobile) {
      return;
    }

    const navLink = e.currentTarget;
    const navItem = el.closest('.navigation__item');
    const submenuWrapper = navItem.querySelector('.navigation__submenu');
    if(el.classList.contains('collapse-arrow')) {
      e.preventDefault();

      if(!navItem.classList.contains('nav-action-active') && !navItem.classList.contains('nav-action-inactive')) {
        if(navLink.classList.contains('navigation__link--active')) {
          navItem.classList.add('nav-action-inactive');
          submenuWrapper.classList.add('nav-action-hide');
        } else {
          navItem.classList.add('nav-action-active');
          submenuWrapper.classList.add('nav-action-show');
        }
        return;
      }

      if(navItem.classList.contains('nav-action-active')) {
        navItem.classList.add('nav-action-inactive');
        navItem.classList.remove('nav-action-active');
        submenuWrapper.classList.add('nav-action-hide');
        submenuWrapper.classList.remove('nav-action-show');
        return;
      }

      navItem.classList.remove('nav-action-inactive');
      navItem.classList.add('nav-action-active');
      submenuWrapper.classList.remove('nav-action-hide');
      submenuWrapper.classList.add('nav-action-show');
    }
  };

  const l2EnterMouseHandler = (hasSubmenu) => (e) => {
    if(hasSubmenu) {
      setLevel3ShadowShow(true);
    }
  };

  const l2LeaveMouseHandler = (e) => {
    setLevel3ShadowShow(false);
  };

  const renderMenu = (menu, level) => {
    const isColored = params?.TextColor && level === 1;
    const textColorClass = textColorResolver(params?.TextColor);
    const currentLevel = level + 1;
    return (
      <ul className="navigation__list list-reset">
        {menu &&
          menu.map((item) => {
            
            const hasSubmenu = item.children.length > 0;
            const linkAttrs = mobile && hasSubmenu ?
              { onClick: handleCollapseClick } :
              {};
            const l2EnterLeaveListeners = !mobile && level === 2 ?
              { onMouseLeave: l2LeaveMouseHandler, onMouseEnter: l2EnterMouseHandler(hasSubmenu) } :
              {};
            return (
              <li
                key={item.title}
                className={`navigation__item ${hasSubmenu ? 'navigation__item--has-submenu' : ''}`}
                {...l2EnterLeaveListeners }
              >
                {item.url ? (
                  <NavLink
                    activeClassName="navigation__link--active"
                    to={item.url}
                    className={`navigation__link ${isColored ? `${textColorClass} navigation__link--is-colored` : ''} `}
                    {...linkAttrs}
                  >
                    <Text className="navigation__link-text" field={{ value: item.title }} tag={'span'} />
                    {hasSubmenu && <span className="collapse-arrow" />}
                  </NavLink>
                ) : (
                  <div className={`navigation__link ${isColored ? `${textColorClass} navigation__link--is-colored` : ''}`}>
                    <Text className="navigation__link-text" field={{ value: item.title }} tag={'span'} />
                    {hasSubmenu && <span className="collapse-arrow" />}
                  </div>
                )}
                {hasSubmenu && <div className={`navigation__submenu level-${currentLevel}`}>{renderMenu(item.children, currentLevel)}</div>}
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <nav
      className={classNames('navigation', {
        'navigation--mobile': mobile,
        'navigation--desktop': !mobile,
        'navigation--l3-visible': level3ShadowShow,
      })}
      ref={primaryNavRef}
    >
      {renderMenu(Object.keys(fields).map((key) => fields[key]), 1)}
    </nav>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(Navigation);
