import React from 'react';
import { DateFormatter } from '@/js/utils';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import PostCardPresentationalComponent from '../PostCardPresentation';

const PostCard = ({ fields }) => {
  const blogFields = fields.Blog?.fields;
  const authors = blogFields?.Authors?.map((author) => author?.fields?.AuthorName.value);

  return (
    <PostCardPresentationalComponent
      url={fields.Blog?.url}
      image={blogFields?.PreviewImage?.value}
      title={blogFields?.PreviewTitle?.value}
      text={blogFields?.PreviewText?.value}
      categories={blogFields?.Category}
      platforms={blogFields?.Platform}
      authors={authors}
      publicationDate={DateFormatter(blogFields?.DateOfPublication?.value)}
    />
  );
};

export default compose(withSitecoreContext(), ExpEditor)(PostCard);
