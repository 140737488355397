import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import LinkOpenIcon from '../../UI/LinkOpenIcon';
import SkeletonLoader from '../../UI/SkeletonLoader';
import './styles.scss';

const SearchCard = ({ image, title, text, url, isLoading }) => {
  return (
    <NavLink to={url || ''} className={classNames('search-card', { 'search-card--loading': isLoading })}>
      {!isLoading && <LinkOpenIcon type={3} />}
      {!isLoading && image && (
        <div className="search-card__pic-wrap">
          <img src={image} alt={title} />
        </div>
      )}
      <SkeletonLoader lines={1} isLoading={isLoading} customClass="search-card__loading-title">
        {!isLoading && <h5 className="search-card__title">{title}</h5>}
      </SkeletonLoader>
      <SkeletonLoader lines={2} isLoading={isLoading}>
        {!isLoading && <div className="search-card__text" dangerouslySetInnerHTML={{ __html: text }} />}
      </SkeletonLoader>
    </NavLink>
  );
};

export default SearchCard;
