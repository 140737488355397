import React from 'react';
import loadable from '@loadable/component';
const GoogleMapReact = loadable(() => import('google-map-react'));
import MapPin from '../GoogleMapPin';
import mapStyle from './mapStyle.json';
import i18n from 'i18next';

const GoogleMap = ({ location, zoom }) => {
  const renderMapPin = () => {
    return location?.map((item, i) => {
      return <MapPin key={i} lat={item.lat} lng={item.lng} text={item.text} />;
    });
  };

  const center = {
    lat: location[0]?.lat || 0,
    lng: location[0]?.lng || 0,
  };

  return (
    <GoogleMapReact
      options={{
        styles: mapStyle,
      }}
      bootstrapURLKeys={{ key: i18n.t('CommonSettingsGoogleMapsKey') }}
      defaultCenter={center}
      defaultZoom={zoom || 17}
    >
      {renderMapPin(location)}
    </GoogleMapReact>
  );
};

export default GoogleMap;
