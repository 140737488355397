import { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useSearchParams = () => {
  const history = useHistory();
  const urlParams = useRef({});

  useEffect(() => {
    urlParams.current = new URLSearchParams(history.location.search);
  }, []);

  const getParams = (name) => {
    return urlParams.current.get(name);
  };

  const setParams = ({ name, value }) => {
    if (value?.length) {
      urlParams.current.set(name, value);
    } else {
      deleteParams(name);
    }

    history.push({
      search: '?' + urlParams.current.toString(),
    });
  };

  const deleteParams = (name) => {
    urlParams.current.delete(name);
  };

  return { getParams, setParams, deleteParams };
};

export default useSearchParams;
