import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import CTA from '../UI/CTA';
import ImageComponent from '../UI/Image';
import classNames from 'classnames';
import './styles.scss';

const OurLocation = ({ fields, additionalClasses, additionalStyles, drawBeveledBorder }) => {

  const locationNumber = fields.Locations && fields.Locations.length;
  return (
    <section
      className={classNames(
        'our-location', {
          ...additionalClasses,
        }
      )}
      style={additionalStyles}
    >
      <div className="container">
        {fields.SectionSubtitle?.value && <Text tag="h6" className="our-location__subtitle" field={fields.SectionSubtitle} />}
        {fields.SectionTitle?.value && <Text tag="h2" className="our-location__title" field={fields.SectionTitle} />}

        <div className={classNames('our-location__list-wrapper', `list-from-${locationNumber}`)}>
          {fields.Locations.map(({fields: locationFields, id: locationId}) => (
            <figure key={locationId}>
              {locationFields.Logo?.value?.src && (
                <div className="our-location__img-wrapper">
                  <ImageComponent imageData={locationFields.Logo.value} />
                </div>
              )}
              <figcaption>
                <h5 className="our-location__place">
                  {locationFields.ShortAddress?.value && <Text field={locationFields.ShortAddress} />}
                </h5>
                <div className="our-location__role">
                  {locationFields.LocationType?.fields?.Title?.value && <Text field={locationFields.LocationType.fields.Title} />}
                </div>
              </figcaption>
            </figure>
          ))}
        </div>

        {(fields.Link?.value && fields.Link?.value.href && fields.Link?.value.text) && (
          <div className="our-location__link">
            <CTA className="cta--color-stable" tag={'navlink'} to={fields.Link.value.href} type="primary" arrowRight>
              <Text field={{value:fields.Link.value.text}} />
            </CTA>
          </div>
        )}
      </div>
      {drawBeveledBorder && <div className="beveled-border-maker" />}
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(OurLocation);
