import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, WithCustomColumnsGrid, withSitecoreContext } from '@/js/HOC';
import './style.scss';

const StepsBlockItem = ({ fields }) => {
  return (
    <article className="steps-block-item">
      {fields?.Title?.value && (
        <h4 className="steps-block-item__title">
          <Text field={fields.Title} />
        </h4>
      )}
      <div className="list-square">{fields?.ListText?.value && <RichText field={fields.ListText} />}</div>
    </article>
  );
};

export default compose(withSitecoreContext(), ExpEditor, WithCustomColumnsGrid)(StepsBlockItem);
