import React, { useEffect, useRef, useState } from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import useIntersection from '@/js/hooks/useIntersection';
import Preloader from '../UI/Preloader';
import './styles.scss';
import { debounce } from '@/js/utils/helpers';

const Twitter = ({ fields }) => {
  const [ref, entry, observer] = useIntersection({ rootMargin: '0px 0px 200px 0px' });
  const [isLoading, setIsLoading] = useState(false);
  const widget = useRef(null);

  const script = useRef(null);
  const isIntersecting = entry?.isIntersecting;

  useEffect(() => {
    if (isIntersecting && !script.current) {
      setIsLoading(true);
      script.current = document.createElement('script');
      script.current.src = 'https://platform.twitter.com/widgets.js';
      script.current.async = true;
      document.body.appendChild(script.current);
      script.current.addEventListener('load', drawTwitterWidget);
      observer && observer.disconnect();
    }
    return () => {
      window.twttr?.events.unbind('rendered');
      script.current?.removeEventListener('load', drawTwitterWidget);
    }
  }, [isIntersecting]);

  const drawTwitterWidget = () => {
    createTimeline();
    if (window.twttr) {
      window.twttr.events.bind(
        'rendered',
        (ev) => setIsLoading(false)
      );
    }
  };

  const createTimeline = (url = fields.Link?.value?.href, parentNode = widget.current) => {
    if (window.twttr) {
      const height = parentNode.offsetHeight;
      window.twttr.widgets.createTimeline(
        { sourceType: 'url', url },
        parentNode,
        { height }
      );
    }
  };

  const handleResize = () => {
    widget.current.innerHTML = '';
    setIsLoading(true);
    createTimeline();
  };
  const debouncedHandleResize = debounce(handleResize, 300);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      script.current && document.body.removeChild(script.current);
    };
  }, []);

  return (
    <div className="twitter" ref={ref}>
      {fields.Title?.value && <Text tag={'h4'} className="twitter__title" field={fields.Title} />}
      <div className="twitter__widget" ref={widget}></div>
      <div className="twitter__preloader">
        {fields.LinkLabel?.value && isLoading && (
          <Link field={fields.Link}>
            <Text field={fields.LinkLabel} />
          </Link>
        )}
        <Preloader show={isLoading} />
      </div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(Twitter);
