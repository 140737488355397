import { useEffect } from "react";

const events = ['dragenter', 'dragover', 'dragleave', 'drop'];

const useDragNDrop = ({el, onDragEnter, onDragLeave, onDrop}) => {

    const handleDragNDropEvents = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === 'dragenter' || e.type === 'dragover') {
            onDragEnter && onDragEnter();
        }

        if (e.type === 'dragleave' || e.type === 'drop') {
            onDragLeave && onDragLeave();
        }

        if (e.type === 'drop') {
            onDrop && onDrop(e);
        }
    }

    useEffect(() => {
        events.forEach((event) => {
            el?.addEventListener(event, handleDragNDropEvents);
        });

        return () => {
            events.forEach((event) => {
                el?.removeEventListener(event, handleDragNDropEvents);
            });
        }
    }, [el]);
}

export default useDragNDrop;