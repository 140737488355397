import React, {useState} from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../UI/CTA';
import { compose, ExpEditor, ThemeResolver, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import { getColumnsClass } from '@/js/utils/helpers';
import classNames from 'classnames';
import './styles.scss';

const NewsCompany = ({ fields, additionalClasses, additionalStyles, isExperienceEditor, rendering, events }) => {
  const {"jss-newscompany-twitter": twitterPlaceholder} = rendering?.placeholders;
  const { Columns, LinkLabel, Link, Title } = fields;
  const isTwitter = twitterPlaceholder &&  twitterPlaceholder?.length > 0;

  const renderLink = () => {
    if (LinkLabel?.value && Link?.value?.href) {
      return (
        <CTA tag={'navlink'} to={Link.value.href} type="primary" arrowRight>
          <Text field={LinkLabel} />
        </CTA>
      );
    } else {
      return null;
    }
  };

  const renderItems = () => {
    if (isExperienceEditor) {
      return <Placeholder rendering={rendering} name="jss-newscompany-conteiner" />;
    }
    return events?.map((Component) => {
      //PostCard
      return (
        <div 
          key={Component.key}
          className={`news-company__item ${isTwitter
            ? 'col-12 col-md-6'
            : `col-xs-12 col-md-6 ${getColumnsClass(Columns?.fields.Value?.value)}`}`}
        >
          {Component}
        </div>
      );
    });
  };

  return (
    <section
      className={classNames({
        'news-company': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        <div className="row">
          <div className={`news-company__left-col col-12 ${isTwitter ? 'col-lg-8' : ''}`}>
            <div className="news-company__header">
              {Title?.value && <Text tag="h4" className="news-company__title" field={Title} />}
              {renderLink()}
            </div>
            <div className="news-company__body">
              <div className="news-company__list row">{renderItems()}</div>
            </div>
            <div className="news-company__footer">{renderLink()}</div>
          </div>
          {isTwitter && !isExperienceEditor && (
            <div className="news-company__right-col col-12 col-lg-4">
              <Placeholder name="jss-newscompany-twitter" rendering={rendering} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-newscompany-conteiner',
    prop: 'events',
  }),
  ExpEditor,
  ThemeResolver
)(NewsCompany);
