import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import CTA from '../UI/CTA';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import LinkTag from '../UI/LinkTag';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import './styles.scss';

const RelatedStories = (props) => {
  return (
    <section className="related-stories">
      <div className="related-stories__list">
        {props?.fields?.Stories?.map((item) => {
          return (
            <NavLink to={item.url} key={item.id} className="related-stories__link">
              <LinkOpenIcon type={3} />
              {item?.fields?.PreviewTitle?.value && (
                <Text tag="h5" className="related-stories__link-title" field={item.fields.PreviewTitle} />
              )}
              {(item?.fields?.BusinessCategory.length > 0 || item?.fields?.Platform.length > 0) && (
                <div className="tags">
                  {item.fields.BusinessCategory.map((tag) => {
                    return (
                      <LinkTag key={tag.id} tag="span">
                        <Text field={tag.fields.Title} />
                      </LinkTag>
                    );
                  })}
                  {item.fields.Platform.map((tag) => {
                    return (
                      <LinkTag key={tag.id} tag="span">
                        <Text field={tag.fields.Title} />
                      </LinkTag>
                    );
                  })}
                </div>
              )}
            </NavLink>
          );
        })}
      </div>
      {props?.fields?.LinkLabel?.value && props.fields?.Link?.value?.href && (
        <div className="related-stories__footer">
          <CTA tag={'navlink'} to={props.fields.Link.value.href} type="primary" arrowRight>
            <Text field={props.fields.LinkLabel} />
          </CTA>
        </div>
      )}
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(RelatedStories);
