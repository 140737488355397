import React from 'react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import CTA from '../UI/CTA';
import './styles.scss';

const StepsBlock = ({ fields, rendering, additionalClasses, additionalStyles, drawBeveledBorder }) => {

  return (
    <section
      className={classNames({
        'steps-block': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
	  {fields.Subtitle?.value && <Text tag="h6" className="steps-block__subtitle" field={fields.Subtitle} />}
        {fields.Title?.value && (
          <h2 className="steps-block__title">
            <Text field={fields.Title} />
          </h2>
        )}
        {fields.IntroText?.value && (
          <p className="steps-block__intro">
            <Text field={fields.IntroText} />
          </p>
        )}
        <div className="steps-block__list row">
          <Placeholder customColumns={fields.Columns?.fields.Value?.value} name="jss-common-steps" rendering={rendering} />
        </div>
        {fields?.LinkLabel?.value && fields?.Link?.value?.href && (
        <div className="steps-block__footer">
          <CTA tag={'navlink'} to={fields.Link.value.href} type="primary" arrowRight>
            <Text field={fields.LinkLabel} />
          </CTA>
        </div>
        )}
      </div>
      {drawBeveledBorder && <div className="beveled-border-maker" />}
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(StepsBlock);
