import {
  FETCH_BLOGPOSTS_SUCCESS,
  FETCH_BLOGPOSTS_START,
  FETCH_BLOGPOSTS_ERROR,
  SET_FILTER_PARAMS,
  SET_STATIC_DATA,
} from '../actions/BlogOverview';

const initialState = {
  staticData: {
    ApiUrl: null,
    NoResultMessage: null,
    PageSize: null,
    SettingId: null,
  },
  filterParams: {
    Category: null,
    Platform: null,
    Product: null,
    Author: null,
  },
  isLoading: {
    loadMore: false,
    value: false,
  },
  isLoaded: false,
  posts: [],
  categories: [],
  platforms: [],
  products: [],
  authors: [],
  currentPage: 1,
  count: 0,
  errorMessage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          ...action.payload,
        },
      };
    case SET_STATIC_DATA:
      return {
        ...state,
        staticData: {
          ...state.staticData,
          ...action.payload,
        },
      };
    case FETCH_BLOGPOSTS_START:
      return {
        ...state,
        isLoading: {
          loadMore: action.loadMore,
          value: true,
        },
      };
    case FETCH_BLOGPOSTS_SUCCESS:
      let posts = action.payload.loadMore ? [...state.posts, ...action.payload.posts] : action.payload.posts;
      return {
        ...state,
        isLoading: false,
        posts: posts,
        categories: action.payload.categories,
        platforms: action.payload.platforms,
        products: action.payload.products,
        authors: action.payload.authors,
        currentPage: action.payload.currentPage,
        countPages: action.payload.countPages,
        count: action.payload.count,
        isLoaded: true,
      };
    case FETCH_BLOGPOSTS_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: {
          ...state.isLoading,
          value: false,
        },
      };
    default: {
      return state;
    }
  }
};

export default reducer;
