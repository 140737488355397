import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Slider from '../UI/Slider';
import { breakpoints } from '@/js/utils';
import useBreakpoint from '@/js/hooks/useBreakpoint';
import CTA from '../UI/CTA';
import Service from '../Service';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import { getColumnsClass } from '../../js/utils/helpers';
import classNames from 'classnames';
import './styles.scss';

const OurServices = ({ fields, additionalClasses, additionalStyles, isExperienceEditor, rendering }) => {
  const { isDesktop } = useBreakpoint();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: breakpoints.screen_sm_max,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };

  const renderItems = () => {
    return fields.ServicePages?.map((item) => {
      return (
        <div className={`our-services__item ${isDesktop ? getColumnsClass(rendering?.fields?.Columns?.fields?.Value?.value) : ''}`} key={item.id}>
          <Service url={item.url} fields={item.fields} />
        </div>
      );
    });
  };

  return (
    <section
      className={classNames({
        'our-services': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        <div className="our-services__header">
          {fields.Title?.value && <Text tag={'h6'} className="our-services__label" field={fields.Title} />}
          {fields.IntroText?.value && <RichText tag={'h2'} className="our-services__title" field={fields.IntroText} />}
        </div>
        <div className="our-services__body">
          {!isDesktop && !isExperienceEditor ? (
            <Slider {...settings}>{renderItems()}</Slider>
          ) : (
            <div className="our-services__list row">{renderItems()}</div>
          )}
        </div>
        <div className="our-services__footer">
          {fields.LinkTitle?.value && (
            <CTA tag={'navlink'} to={fields.Link.value.href} type="primary" arrowRight>
              <Text field={fields.LinkTitle} />
            </CTA>
          )}
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(OurServices);
