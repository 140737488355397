import React from 'react';
import './styles.scss';

const VideoIframe = (props) => {
  if (!props.url) {
    return null;
  }

  const type = props.url?.includes('youtube') ? 'youtube' : 'video';

  return (
    <div className={`video ${props.embeded ? ' video--embeded' : ''}`}>
      {type === 'video' && <video className="video__player" src={props.url} />}
      {type === 'youtube' && <iframe className="video__player" src={props.url} loading="lazy" />}
    </div>
  );
};

export default VideoIframe;
