import React, { useState, useEffect, useCallback } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import { DateFormatter, isDevMode } from '@/js/utils';
import { compose, ExpEditor, withSitecoreContext, WithLoader } from '@/js/HOC';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import useFetch from '@/js/hooks/useFetch';
import './styles.scss';

const MoreFromAuthor = ({ fields, sitecoreContext }) => {
  const [result, setResult] = useState([]);
  const apiUrl = isDevMode ? 'http://localhost:4000/getPostByAuthor' : fields?.ApiUrl?.value;
  const { post, isLoading } = useFetch({ url: apiUrl });

  useEffect(() => {
    getData();
  }, []);

  const getData = useCallback(async () => {
    const authors =
      fields?.Authors?.length > 0 ? fields?.Authors : sitecoreContext.route?.fields?.Authors?.map((item) => item.id);

    const data = await post({
      AuthorId: authors,
      Count: fields.Count?.value,
      ExcludePostId: sitecoreContext.route?.itemId,
    });

    if (data) {
      setResult(data);
    }
  }, [post, setResult]);

  return (
    <WithLoader isLoading={isLoading} itemsLength={result.length}>
      <div className="related-stories-2">
        {fields.Title?.value && <Text className="related-stories-2__title" tag="h6" field={fields.Title} />}
        <div className="related-stories-2__list">
          {result?.map((item, i) => {
            return (
              <NavLink to={item.Url} key={i} className="related-stories__link related-stories__link--sm">
                <LinkOpenIcon type={3} />
                {item.PreviewTitle && (
                  <Text tag="h5" className="related-stories__link-title" field={{ value: item.PreviewTitle }} />
                )}
                {item.DateOfPublication && (
                  <Text
                    tag="span"
                    className="related-stories__date"
                    field={{ value: DateFormatter(item.DateOfPublication) }}
                  />
                )}
              </NavLink>
            );
          })}
        </div>
      </div>
    </WithLoader>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(MoreFromAuthor);
