import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import Avatar from '../UI/Avatar';
import { NavLink } from 'react-router-dom';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const CurrentAuthor = ({ fields, sitecoreContext }) => {
  const authorsUrl = sitecoreContext?.route?.fields?.BlogAuthorPage?.url;
  const authors = fields?.Authors;

  if (!authors?.length) {
    return null;
  }

  return (
    <div className="current-author">
      <Text tag="h6" className="sidebar__title" field={{ value: i18n.t('ComponentsCurrentAuthorAuthor') }} />
      <div className="current-author__list">
        {authors?.map((item) => {
          return (
            <NavLink
              to={{ pathname: authorsUrl, search: `?authors=${item.fields.AuthorName.value}` }}
              key={item.id}
              className="current-author__item"
            >
              <Avatar photo={item.fields.AuthorPhoto} name={item.fields.AuthorName} title={item.fields.AuthorTitle} />
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(CurrentAuthor);
