import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import logoIcon from './img/logo-icon.svg';
import logoText from './img/logo-text.svg';
import './style.scss';

const Logo = ({ fullSize }) => {
  return (
    <NavLink to="/" className={`logo ${fullSize ? 'logo--full-size' : ''}`}>
      <img className="logo__icon" src={logoIcon} alt="Brimit Logo" />
      <img className="logo__text" src={logoText} alt="Brimit Logo" />
    </NavLink>
  );
};

export default withSitecoreContext()(Logo);
