import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const StoryCategoryAndPlatform = ({ fields }) => {
  const platform = fields.Platform[0]?.fields?.Title;
  const category = fields.BusinessCategory[0]?.fields?.Title;
  const countryTitle = fields.CountryFilter[0]?.fields?.Title;
  const countryVisible = fields.CountryFilter[0]?.fields?.Visible;

  return (
    <div className="header-categories">
      {block(platform, 'ComponentsStoriesStoryCategoryAndPlatformPlatform')}
      {block(category, 'ComponentsStoriesStoryCategoryAndPlatformBusinessCategory')}
      {countryVisible && block(countryTitle, 'ComponentsStoriesStoryCategoryAndPlatformCountry')}
    </div>
  );
};

const block = (field, dictionaryKey) => {
  return field?.value && (
      <div className="header-categories__item">
        <Text
            tag="h6"
            className="header-categories__item-name"
            field={{ value: i18n.t(dictionaryKey) }}
        />

        <Text tag="div" className="header-categories__item-value" field={field} />
      </div>
  )
}

export default compose(withSitecoreContext(), ExpEditor)(StoryCategoryAndPlatform);
