import React from 'react';
import Header from '../Header';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const GlossaryHeader = (props) => {
  const additionalClasses = {
    'header--glossary': true,
  };
  return (
    <Header
      additionalClasses={additionalClasses}
      title={props.sitecoreContext?.route?.fields?.GlossaryTitle}
      {...props}
    />
  );
};

export default compose(withSitecoreContext(), ExpEditor)(GlossaryHeader);
