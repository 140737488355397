import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import CTA from '../UI/CTA';
import { NavLink } from 'react-router-dom';
import { FormattedDateField } from '@/js/utils';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import './styles.scss';

const EventCard = ({ fields }) => {
  const linkUrl = fields?.Link?.value?.href;
  const Tag = linkUrl ? NavLink : 'article';

  return (
    <Tag className="event-card" to={linkUrl}>
      {linkUrl && <LinkOpenIcon />}
      <div className="event-card__pic-wrap">
        {fields?.Blog?.fields?.PreviewImage?.value?.src && (
          <Image className="event-card__img" field={fields.Blog.fields.PreviewImage} />
        )}
      </div>
      <div className="event-card__text-wrap">
        {fields?.Blog?.fields?.BrowserTitle?.value && (
          <Text className="event-card__title" field={fields.Blog.fields.BrowserTitle} tag="h5" />
        )}
        {fields?.Blog?.fields?.DateOfPublication?.value && (
          <Text
            className="event-card__date"
            field={FormattedDateField(fields.Blog.fields.DateOfPublication)}
            tag="span"
          />
        )}
        {fields?.LinkText?.value && linkUrl && (
          <div className="event-card__btn-wrap">
            <CTA tag={'button'} type="secondary">
              <Text field={fields.LinkText} />
            </CTA>
          </div>
        )}
      </div>
    </Tag>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(EventCard);
