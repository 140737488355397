import React from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import ImageComponent from '../Image';
import classNames from 'classnames';
import './styles.scss';

const Avatar = ({ types = [], photo, name, title, introText, link, linkLabel }) => {
  const avatarClasses = classNames({
    avatar: true,
    'avatar--lg': types.includes('lg'),
    'avatar--vertical': types.includes('vertical'),
  });

  const LinkTag = link?.value?.linktype === 'external' ? Link : NavLink;
  const linkAttrs = link?.value?.linktype === 'external' ? { field: link } : { to: link?.value?.href };

  return (
    <div className={avatarClasses}>
      {photo?.value?.src && (
        <div className="avatar__pic-wrap">
          <ImageComponent imageData={photo.value} />
        </div>
      )}
      <div className="avatar__text-wrap">
        {name?.value && <Text className="avatar__name" tag="div" field={name} />}
        {title?.value && <Text className="avatar__title" tag="div" field={title} />}
        {introText?.value && <Text className="avatar__intro-text" tag="div" field={introText} />}
        {link?.value?.href && linkLabel?.value && (
          <div className={'avatar__link-wrap'}>
            <LinkTag {...linkAttrs} className={'link-underline'}>
              <Text field={linkLabel} />
            </LinkTag>
          </div>
        )}
      </div>
    </div>
  );
};

export default Avatar;
