import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import { NavLink } from 'react-router-dom';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import ImageComponent from '../UI/Image';
import useBreakpoint from '@/js/hooks/useBreakpoint';
import classNames from 'classnames';
import './styles.scss';

const Service = ({ fields, url }) => {
  const { isDesktop } = useBreakpoint();
  const Tag = url ? NavLink : 'article';
  const alternativeImageUrl = fields.PreviewImageAnother?.value?.src ? fields.PreviewImageAnother : fields.PreviewImage;

  return (
    <Tag
      to={url}
      className={classNames({
        'our-service': true,
        'is-desktop': isDesktop,
      })}
    >
      <LinkOpenIcon />
      {fields.PreviewImage?.value?.src && (
        <ImageComponent imageData={fields.PreviewImage.value} className="our-service__img" />
      )}
      {isDesktop && (
        <ImageComponent imageData={alternativeImageUrl} className="our-service__img our-service__img--dark-theme" />
      )}
      {fields.PreviewTitle?.value && <Text tag={'h5'} className="our-service__title" field={fields.PreviewTitle} />}
      {fields.PreviewText?.value && <RichText tag={'p'} className="our-service__desc" field={fields.PreviewText} />}
    </Tag>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(Service);
