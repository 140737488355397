import React from 'react';
import { NavLink } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const MenuSecondary = ({ mobile, fields }) => {
  return (
    <nav className={`menu-secondary ${mobile ? 'menu-secondary--mobile' : 'menu-secondary--desktop'}`}>
      <ul className="menu-secondary__list list-reset">
        {fields &&
          Object.keys(fields).map((key) => {
            const link = fields[key];
            return (
              <li key={link.title} className="menu-secondary__item">
                <NavLink activeClassName="menu-secondary__link--active" to={link.url} className="menu-secondary__link">
                  <Text field={{ value: link.title }} />
                </NavLink>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(MenuSecondary);
