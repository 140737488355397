import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const HighlightsItem = ({ fields }) => {
  return (
    <div className="highlights-item">
      <i className="highlights-item__icon" />
      <div className="highlights-item__text">{fields?.Text?.value && <RichText field={fields.Text} />}</div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(HighlightsItem);
