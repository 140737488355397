import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import { NavLink } from 'react-router-dom';
import CTA from '../UI/CTA';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import ImageComponent from '../UI/Image';
import './styles.scss';

const OurCustomerStoriesBlockItem = ({ fields }) => {
  const linkUrl = fields.StoryLink?.value?.href;
  const Tag = linkUrl ? NavLink : 'article';
  const tagProps = linkUrl ? { to: linkUrl } : {};

  return (
    <Tag {...tagProps} className="customer-story">
      {linkUrl && <LinkOpenIcon />}
      <div className="customer-story__pic-wrap">
        {fields.Image?.value?.src && <ImageComponent className="customer-story__img" imageData={fields.Image.value} />}
      </div>
      <div className="customer-story__text-wrap">
        {fields.Text?.value && <Text tag="h6" className="customer-story__title" field={fields.Text} />}
        {fields.LinkText?.value && linkUrl && (
          <div className="customer-story__btn-wrap">
            <CTA tag="button" type="secondary">
              <Text field={fields.LinkText} />
            </CTA>
          </div>
        )}
      </div>
    </Tag>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(OurCustomerStoriesBlockItem);
