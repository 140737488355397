import React from 'react';
import './styles.scss';

const BlockQuote = (props) => {
  return (
    <div className="blockquote">
      <i className="blockquote__icon fas fa-quote-right" aria-hidden="true" />
      <div className="blockquote__text">{props.children}</div>
    </div>
  );
};

export default BlockQuote;
