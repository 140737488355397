import React from 'react';
import { WithLoader } from '@/js/HOC';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './styles.scss';

const GlossaryOverviewContainer = ({fields}) => {
  const { articles, isLoading } = useSelector((state) => state.GlossaryOverviewReducer);

  const renderArticles = (articles) => {
    return Object.keys(articles).map((key) => {
      const list = articles[key];
      return (
        <div className="glossary-overview__column" key={key}>
          <div className="glossary-overview__letter">{key}</div>
          {list.length > 0 && (
            <ul className="glossary-overview__list">
              {list.map((item, i) => {
                return (
                  <li key={i}>
                    <NavLink to={{ pathname: item.Path }}>{item.Name}</NavLink>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      );
    });
  };

  return (
    <div className="glossary-overview">
      <WithLoader isLoading={isLoading} itemsLength={articles.length} noResultMessage={fields?.NotFoundMessage?.value}>
        <div className="glossary-overview__columns">{renderArticles(articles)}</div>
      </WithLoader>
    </div>
  );
};

export default GlossaryOverviewContainer;
