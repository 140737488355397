import React, { useEffect, useRef, useState } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import CTA from '../UI/CTA';
import ImageComponent from '../UI/Image';
import classNames from 'classnames';
import './styles.scss';

const CustomerReview = ({ fields, additionalClasses, additionalStyles, drawBeveledBorder }) => {
  const script = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const getLinkProps = (link) => {
    const propsObj = {};
    if (link?.value && link?.value.href && link?.value.text) {
      propsObj.type = 'primary';
      if (link.value.linktype === 'external') {
        propsObj.tag = 'a';
        propsObj.href = link.value.href;
      } else {
        propsObj.tag = 'navlink';
        propsObj.to = link.value.href;
      }
    }

    return propsObj;
  };

  const extraLinkProps = getLinkProps(fields.ExtraLink);
  const linkProps = getLinkProps(fields.Link);

  const initWidget = () => {
    setIsLoading(false);
    window.CLUTCHCO?.Init();
  };

  useEffect(() => {
    if (!script.current) {
      script.current = document.createElement('script');
      script.current.src = 'https://widget.clutch.co/static/js/widget.js';
      script.current.async = true;
      script.current.addEventListener('load',  initWidget);
      document.body.appendChild(script.current);
    } 

    return () => {
      window.CLUTCHCO?.Destroy();
      script.current?.removeEventListener('load', initWidget);
      script.current && document.body.removeChild(script.current);
    }
  }, []);

  return (
    <section className={classNames('customer-review', { ...additionalClasses })} style={additionalStyles}>
      <div className="container">
        {fields.SubTitle?.value && <Text tag="h6" className="customer-review__subtitle" field={fields.SubTitle} />}
        {fields.Title?.value && <Text tag="h2" className="customer-review__title" field={fields.Title} />}

        <div className="d-lg-flex">
          {fields.Logo?.value?.src && (
            <div className="customer-review__image-wrapper">
              <ImageComponent imageData={fields.Logo.value} className="customer-review__image" />
            </div>
          )}
          <div className="flex-grow-1">
            {fields.Text?.value && <RichText className="customer-review__description" field={fields.Text} />}

            <div
              className="customer-review__clutch-widget-wrapper"
              dangerouslySetInnerHTML={{__html: fields.Widget?.value}}
            />

            <div className="customer-review__ctas-wrapper">
              {extraLinkProps.type && isLoading && (
                <CTA {...extraLinkProps}>
                  <Text field={{ value: fields.ExtraLink.value.text }} />
                </CTA>
              )}

              {linkProps.type && (
                <CTA {...linkProps}>
                  <Text field={{ value: fields.Link.value.text }} />
                </CTA>
              )}
            </div>
          </div>
        </div>
      </div>
      {drawBeveledBorder && <div className="beveled-border-maker" />}
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(CustomerReview);
