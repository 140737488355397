import React, { useEffect, useState, useRef } from 'react';
import { Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Logo from '../Logo';
import BurgerMenu from '../BurgerMenu';
import ContactUsBtn from '../ContactUsBtn';
import ImageComponent from '../UI/Image';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const Header = (props) => {
  let timer = null;
  const menuWrap = useRef();
  const headerDesktop = useRef();
  const headerMobile = useRef();
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    openSearch
      ? headerDesktop.current.classList.add('search-opened')
      : headerDesktop.current.classList.remove('search-opened');
  }, [openSearch]);

  useEffect(() => {
    handleScroll();
    handleResize(); // toDo replace with resize observer

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      document.documentElement.style.setProperty('--navigation-width', `${menuWrap.current?.offsetWidth}px`);
    }, 300);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      headerDesktop.current.classList.remove('theme-dark');
      headerMobile.current.classList.remove('theme-dark');
    } else {
      headerDesktop.current.classList.add('theme-dark');
      headerMobile.current.classList.add('theme-dark');
    }
  };

  const title = props.title || props.fields?.HeaderTitle;

  return (
    <header
      className={classNames({
        header: true,
        ...(props.additionalClasses || {}),
      })}
    >
      <div className={`header__top--desktop header__top theme-dark`} ref={headerDesktop}>
        <div className="container">
          <Logo />
          <div className="header__navigation-wrap" ref={menuWrap}>
            <Placeholder name="jss-header-navigation" rendering={props.rendering} />
            <Placeholder
              name="jss-header-search"
              open={openSearch}
              setOpen={setOpenSearch}
              rendering={props.rendering}
            />
          </div>
          <div className="header__navigation-secondary-wrap">
            <Placeholder name="jss-header-navigation-secondary" rendering={props.rendering} />
          </div>
          <div className="header__cta-wrap">
            <ContactUsBtn rendering={props.rendering} />
          </div>
        </div>
      </div>

      <div className={`header__top--mobile header__top theme-dark`} ref={headerMobile}>
        <div className="container">
          <BurgerMenu rendering={props.rendering} />
          <Logo />
          <div className="header__cta-wrap">
            <ContactUsBtn rendering={props.rendering} />
          </div>
        </div>
      </div>

      <div className="header__body theme-dark">
        {props.fields?.BackgroundImage?.value?.src && (
          <div className="header__body-bg">
            <ImageComponent imageData={props.fields.BackgroundImage.value} className={'header__body-bg-image'} />
          </div>
        )}
        <div className="container">
          <div className="header__breadcrumb">
            <Placeholder name="jss-header-breadcrumb" rendering={props.rendering} />
          </div>
          <div className="header__body-content-wrap">
            {props.fields?.IntroText?.value && (
              <Text tag={'h6'} className="header__introtext" field={props.fields.IntroText} />
            )}

            {title?.value && <Text tag={'h1'} className="header__title" field={title} />}

            <div className="header__desc-wrap">
              {props.fields?.HeaderIcon?.value?.src && (
                <div className="header__icon-wrap">
                  <ImageComponent imageData={props.fields.HeaderIcon.value} />
                </div>
              )}
              {props.fields?.HeaderDescription?.value && (
                <RichText tag={'div'} className="header__desc" field={props.fields.HeaderDescription} />
              )}
            </div>
            {props.rendering?.placeholders['jss-header-facts'].length > 0 && (
              <div className="header__facts">
                <Placeholder name="jss-header-facts" rendering={props.rendering} />
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(Header);
