const backgroundThemesMap = {
  Dark: 'section theme-dark bg-dark',
  Green: 'section theme-accent bg-accent',
  Gray: 'section theme-gray bg-gray',
  White: 'section initial-background',
};

const beveledCornerBorderMap = {
  Dark: 'beveled-border-dark',
  Green: 'beveled-border-accent',
  Gray: 'beveled-border-gray',
};

const borderColorMap = {
  Default: 'border-white',
  White: 'border-white',
  Dark: 'border-dark',
  Green: 'border-accent',
  Gray: 'border-gray',
};

const textColorMap = {
  Default: 'text-white',
  White: 'text-white',
  Dark: 'text-dark',
  Green: 'text-accent',
  Gray: 'text-gray',
  Black: 'text-black',
};

export const backgroundResolver = (themeName) => {
  if (backgroundThemesMap.hasOwnProperty(themeName)) {
    return backgroundThemesMap[themeName];
  } else {
    return backgroundThemesMap['White'];
  }
};

export const textColorResolver = (textColor) => {
  if (textColor && textColorMap.hasOwnProperty(textColor)) {
    return textColorMap[textColor];
  } else {
    return textColorMap['Default'];
  }
};

export const borderColorResolver = (borderColor) => {
  if (borderColor && borderColorMap.hasOwnProperty(borderColor)) {
    return borderColorMap[borderColor];
  } else {
    return borderColorMap['Default'];
  }
};

export const beveledCornerBorderResolver = (hasBeveledCorner, borderThemeColor) => {
  if (hasBeveledCorner && beveledCornerBorderMap.hasOwnProperty(borderThemeColor)) {
    return beveledCornerBorderMap[borderThemeColor];
  }
  return;
};

export const backgroundImageResolver = (url) => {
  if (!url) {
    return {};
  }
  return {
    backgroundImage: `url("/-/media/${url.slice(url.indexOf('{') + 1, url.indexOf('}')).replace(/-/g, '')}")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
};
