import React, { useEffect, useState } from 'react';
import { compose, withSitecoreContext } from '@/js/HOC';
import { CookieService } from '@/js/utils';
import i18n from 'i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './styles.scss';

const cookieService = new CookieService();

const CookieBanner = ({ fields }) => {
  const [show, setShow] = useState(false);
  const cookieName = 'cookieBannerClosed';
  const date = new Date();
  const expireDate = new Date(date.setMonth(date.getMonth() + 12));

  useEffect(() => {
    const cookieValue = cookieService.get(cookieName);
    if (cookieValue != 1) {
      setShow(true);
    }
  }, []);

  const handleBannerClose = () => {
    setShow(false);
    cookieService.set(cookieName, 1, { expires: expireDate });
  };

  return (
    <section className={`cookie-banner theme-dark${show ? ' cookie-banner--show' : ''}`}>
      <div className="container">
        <div className="cookie-banner__body">
          <RichText field={{ value: i18n.t('CommonCookieBannerBodyText') }} />
          <button className="cookie-banner__close" onClick={handleBannerClose}>
            <i className="fas fas fa-times" aria-hidden="true" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext())(CookieBanner);
