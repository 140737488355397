import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import LinkTag from '../UI/LinkTag';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import ImageComponent from '../UI/Image';
import './styles.scss';

const PostCardPresentationalComponent = ({
  url,
  image,
  title,
  text,
  categories,
  platforms,
  authors,
  publicationDate,
}) => {
  const Tag = url ? NavLink : 'article';

  return (
    <Tag to={url} className="post-card">
      {url && <LinkOpenIcon type={2} />}
      <figure className="post-card__figure">
        {image && <ImageComponent className="post-card__img" imageData={image} />}
      </figure>
      <div className="post-card__text-wrap">
        <div className="tags">
          {categories?.map((item, i) => {
            const tagText = item.fields?.Title.value || item;
            return (
              <LinkTag key={i} tag="span">
                <Text field={{ value: tagText }} />
              </LinkTag>
            );
          })}
          {platforms?.map((item, i) => {
            const tagText = item.fields?.Title.value || item;
            return (
              <LinkTag key={i} tag="span">
                <Text field={{ value: tagText }} />
              </LinkTag>
            );
          })}
        </div>
        {title && <Text tag="h5" className="post-card__title" field={{ value: title }} />}
        {text && <RichText tag="p" className="post-card__text" field={{ value: text }} />}
        <p className="post-card__meta">
          {authors?.map((author, i) => (
            <span key={i}>
              {i > 0 && ', '}
              <Text field={{ value: author }} />
            </span>
          ))}
          {publicationDate && (
            <>
              {' '}
              on <Text field={{ value: publicationDate }} />
            </>
          )}
        </p>
      </div>
    </Tag>
  );
};

export default PostCardPresentationalComponent;
