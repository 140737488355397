import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import CTA from '../UI/CTA';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import i18n from 'i18next';
import { NavLink } from 'react-router-dom';
import './styles.scss';

const LinkItem = (props) => {
  const categoties = props.fields.BusinessCategory?.map(cat => ({title: cat.fields.Title, id: cat.id}));
  const platforms = props.fields.Platform?.map(platform => ({title: platform.fields.Title, id: platform.id}));

  return (
    <NavLink className="story-card" to={props.fields.url}>
      <LinkOpenIcon type={3} />
      <div className="story-card__info">
        <div className="story-card__cats">
          <h6 className="story-card__cat-item">
            {i18n.t('BlogsStoryCardRelatedCustomerStory')}
          </h6>
        </div>
        {props.fields.Title?.value && <Text className="story-card__title" field={props.fields.Title} tag={'h4'} />}
        <div className="story-card__platforms">
          {categoties?.map(cat => <Text key={cat.id} className="story-card__platform-item" field={cat.title} tag={'span'} />)}
          {platforms?.map(platform => <Text key={platform.id} className="story-card__platform-item" field={platform.title} tag={'span'} />)}
        </div>
      </div>
      {props.fields.PreviewLinkLabel?.value && (
        <CTA tag={'button'} type="secondary" className="text-nowrap ml-auto">
          <Text field={props.fields.PreviewLinkLabel} />
        </CTA>
      )}
    </NavLink>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(LinkItem);
