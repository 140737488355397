import React from 'react';
import loadable from '@loadable/component';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
const MapChart = loadable(() => import('./MapChart/MapChart'));
import './styles.scss';

const CustomerMapBlock = ({ fields }) => {
  const countriesFieldValue = fields.Countries?.value;
  const countries = countriesFieldValue.split('&').reduce((acc, item) => {
    let parsedItem = item.split('=');
    let [key, value] = parsedItem;
    acc[key] = value.replace('%20', ' ');
    return acc;
  }, {});

  const renderCustomerList = (customers) => {
    const customersArray = Object.keys(customers).map((item) => {
      return { iso: item, label: customers[item] };
    });
    const sortedCustomerLabels = customersArray.sort((a, b) => {
      return a.label - b.label;
    });

    return (
      <ul className="customer-map-block__customer-list list-square">
        {sortedCustomerLabels.map((item) => {
          return (
            <li key={item.iso}>
              <Text tag="span" field={{ value: item.label }} />
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <section className="customer-map-block-wrapper">
      <div className="customer-map-block">
        <div className="container">
          {fields.Title?.value && <Text tag="h2" className="customer-map-block__title" field={fields.Title} />}
          {renderCustomerList(countries)}
        </div>
        <div className="customer-map-block__map">
          <MapChart customers={countries} />
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(CustomerMapBlock);
