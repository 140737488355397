import React, { useRef, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import Avatar from '../UI/Avatar';
import './styles.scss';

const ConsultExpert = ({ fields }) => {
  const descriptionRef = useRef();
  let description = fields?.Description?.value;
  const phoneLabel = fields?.PhoneLabel?.value;
  const phone = fields?.Phone?.value;

  useEffect(() => {
    if (!description || !phoneLabel || !phone) {
      return;
    }
    description = description.replace('{0}', `<a href="tel:${phone}">${phoneLabel}</a>`);
    descriptionRef.current.innerHTML = description;
  }, []);

  return (
    <section className="consult theme-dark">
      <div className="consult__bg" />
      <div className="consult__wrap">
        {fields?.Title?.value && <Text tag="h2" className="consult__title" field={fields.Title} />}
        <Avatar types={['vertical']} photo={fields.Image} name={fields.Name} title={fields.Position} />
        <div className="consult__list">
          {(fields?.EmailLinkLabel?.value || fields?.Email?.value) && (
            <div className="consult__item">
              <a href={`mailto:${fields.Email.value}`}>
                <Text field={fields.EmailLinkLabel} />
              </a>
            </div>
          )}
          <div className="consult__item" ref={descriptionRef} />
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(ConsultExpert);
