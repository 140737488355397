import React, { useEffect, useState, useCallback } from 'react';
import {
  Form,
  createDefaultFieldFactory,
  FieldTypes,
  SingleLineText,
  MultipleLineText,
} from '@sitecore-jss/sitecore-jss-react-forms';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import FileUpload from '../UI/FileUpload';
import Preloader from '../UI/Preloader';
import { TextField } from './form-fields';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import { withRouter } from 'react-router-dom';
import { sitecoreApiHost, sitecoreApiKey } from '../../temp/config';
import { deepCopyFunction, FieldErrorComponent, FieldWrapperComponent } from '@/js/utils';
import './styles.scss';

const defaultFieldFactory = createDefaultFieldFactory();
const RECAPTCHA_ACTION_TITLE = 'CONTACT_US_FORM_SUBMIT'

const nameFieldType = '{9172127C-F489-43E1-8787-C5F4EE17B9CC}';
const emailWithDomainsFieldType = '{036D0E3A-E153-4DAC-AE84-4CA5873B0937}';
const dragAndDropFileUpload = '{A950D414-1804-4E85-9616-48D4E754544F}';
const hiddenUrl = '{8088E17C-4DF7-42D3-8246-8289681BE56B}';

const fieldsSettings = {
  trackForm: false,
};

const fieldsMap = {
  [nameFieldType]: TextField(SingleLineText, fieldsSettings),
  [emailWithDomainsFieldType]: TextField(SingleLineText, fieldsSettings),
  [FieldTypes.Telephone]: TextField(SingleLineText, fieldsSettings),
  [FieldTypes.MultipleLineText]: TextField(MultipleLineText, fieldsSettings),
  [dragAndDropFileUpload]: (props) => <FileUpload {...props} />,
  [hiddenUrl]: (props) => null,
};

Object.keys(fieldsMap).forEach((field) => {
  defaultFieldFactory.setComponent(field, fieldsMap[field]);
});

const setFieldsValuesFormStorage = (fields) => {
  fields.forEach((field) => {
    field.fields?.forEach((inputField) => {
      const fieldEntity = inputField?.fields?.[0];
      if (fieldEntity && fieldEntity.model) {
        fieldEntity.model.value = sessionStorage.getItem(fieldEntity.model.itemId);
      }
    });
  });
  return fields;
};

const formFetcher = (setIsLoading, onSuccess, handleReCaptchaVerify, setCommonFormError) => async (formData, endpoint) => {
  setIsLoading(true);
  setCommonFormError('');

  try {
    const reCaptchaToken = await handleReCaptchaVerify();
    formData.append('g-recaptcha-token', reCaptchaToken);
    formData.append('g-recaptcha-action-name', RECAPTCHA_ACTION_TITLE);
    if (!reCaptchaToken) {
      const noCaptachaText = 'Captcha is incorrect';
      setCommonFormError(noCaptachaText);
      return noCaptachaText;
    }
    
    const formResponse = await fetch(endpoint, {
      body: formData.toMultipartFormData(),
      method: 'post',
      credentials: 'include',
    });    
    const parsedResponse = await formResponse.json();

    if (parsedResponse?.success) {
      onSuccess && onSuccess();
    }
    return parsedResponse;
  }
  catch(error) {
    console.log(error.message)
    setCommonFormError(error.message);
    return error;
  }
  finally {
    setIsLoading(false);
  }
};

const JssForm = ({ fields, history, onSubmit, isTrack }) => {
  const [formFields, setFormFields] = useState(fields.fields);
  const [isLoading, setIsLoading] = useState(false);
  const [commonFormError, setCommonFormError] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  fieldsSettings.trackForm = isTrack;

  const onSuccess = () => {
    onSubmit();
    isTrack && sessionStorage.clear();
  };

  
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha)  return;

    return await executeRecaptcha();
  }, [executeRecaptcha]);

  useEffect(() => {
    if (isTrack) {
      setFormFields((prev) => setFieldsValuesFormStorage(deepCopyFunction(prev)));
    }
  }, [isTrack]);

  return (
    <section className="jss-form">
      <style dangerouslySetInnerHTML={{__html: '.grecaptcha-badge{visibility: visible;}'}}></style>
      <div className="form preloader__wrap">
        { commonFormError ? <div className="validation-message mb-3"><span className="error-message">{commonFormError}</span></div> : null } 
        <Form
          formFetcher={formFetcher(setIsLoading, onSuccess, handleReCaptchaVerify, setCommonFormError)}
          fieldValidationErrorsComponent={FieldErrorComponent}
          fieldWrapperComponent={FieldWrapperComponent}
          fieldFactory={defaultFieldFactory}
          form={{
            ...fields,
            fields: formFields,
          }}
          sitecoreApiHost={sitecoreApiHost}
          sitecoreApiKey={sitecoreApiKey}
          onRedirect={(url) => history.push(url)}
        />
        <Preloader show={isLoading} absolute={true} />
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), withRouter, ExpEditor)(JssForm);
