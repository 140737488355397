import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import BtnTag2 from '../UI/BtnTag2';
import Filter from '../Filter';
import FilterMobile from '../FilterMobile';
import FilterDropdown from '../FilterDropdown';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import useBreakpoint from '@/js/hooks/useBreakpoint';
import useSearchParams from '@/js/hooks/useSearchParams';
import { setFilterParams, fetchBlogPosts, setStaticData } from '../../js/redux/actions/BlogOverview';
import { isDevMode, shallowEqual as shallowEqualFn } from '@/js/utils';
import classNames from 'classnames';
import './styles.scss';

const initialFilterState = {
  activeCategoryId: null,
  activePlatformId: null,
  activeProductId: null,
  activeAuthorId: null,
};

const BlogOverviewMenu = ({ rendering, fields, additionalClasses, additionalStyles }) => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState(initialFilterState);

  const {
    categories: availableCategories,
    platforms: availablePlatforms,
    products: availableProducts,
    authors: availableAuthors,
    isLoading,
    isLoaded,
  } = useSelector((state) => state.BlogOverviewReducer, shallowEqual);

  const categories = fields.Categories?.map((category) => ({
    Id: category.id,
    Name: category.fields?.Title?.value,
    Disabled: !availableCategories.map((item) => item.Id).includes(category.id),
  }));

  const platforms = fields.Platforms?.map((platform) => ({
    Id: platform.id,
    Name: platform.fields?.Title?.value,
    Disabled: !availablePlatforms.map((item) => item.Id).includes(platform.id),
  }));

  const products = availableProducts?.map((product) => ({
    Id: product.Id,
    Name: product.Name,
    Disabled: false,
  }));

  const authors = fields.Authors?.map((author) => ({
    Id: author.id,
    Name: author.fields?.AuthorName?.value,
    Disabled: !availableAuthors.map((item) => item.Id).includes(author.id),
  }));

  const { isDesktop } = useBreakpoint();
  const { getParams, setParams } = useSearchParams();

  const isInitialState = useMemo(
    () => shallowEqualFn(initialFilterState, filterState),
    [initialFilterState, filterState]
  );

  useEffect(() => {
    dispatch(
      setStaticData({
        ApiUrl: isDevMode ? 'http://localhost:4000/blogOverview' : fields.ApiUrl?.value,
        NoResultMessage: fields?.NotFoundMessage?.value,
        PageSize: +fields?.PageSize?.value,
        SettingId: rendering?.dataSource,
      })
    );

    const activeCategoryId = (getActiveFilterFromUrl(categories, 'categories') || {}).Id;
    const activePlatformId = (getActiveFilterFromUrl(platforms, 'platforms') || {}).Id;
    const activeProductId = (
      getActiveFilterFromUrl(
        fields.Products.map((product) => ({ Id: product.id, Name: product.fields?.Title?.value })),
        'products'
      ) || {}
    ).Id;
    const activeAuthorId = (getActiveFilterFromUrl(authors, 'authors') || {}).Id;

    setFilter(
      {
        activeCategoryId,
        activePlatformId,
        activeProductId,
        activeAuthorId,
      },
      false
    );

    dispatch(fetchBlogPosts(1));
  }, []);

  const getActiveFilterFromUrl = (filterList, filterParam) => {
    const paramFromUrl = getParams(filterParam);
    return paramFromUrl ? filterList?.find((item) => item.Name.toLowerCase() === paramFromUrl.toLowerCase()) : null;
  };

  const getActiveFilterItem = (filterList, id) => {
    return filterList?.find((item) => item.Id === id);
  };

  const setFilter = (
    {
      activeCategoryId = filterState.activeCategoryId,
      activePlatformId = filterState.activePlatformId,
      activeProductId = filterState.activeProductId,
      activeAuthorId = filterState.activeAuthorId,
    } = filterState,
    writeToUrl = true
  ) => {
    setFilterState({
      ...filterState,
      activeCategoryId,
      activePlatformId,
      activeProductId,
      activeAuthorId,
    });

    dispatch(
      setFilterParams({
        Category: activeCategoryId,
        Platform: activePlatformId,
        Product: activeProductId,
        Author: activeAuthorId,
      })
    );

    if (writeToUrl) {
      setParams({ name: 'categories', value: (getActiveFilterItem(categories, activeCategoryId) || {}).Name });
      setParams({ name: 'platforms', value: (getActiveFilterItem(platforms, activePlatformId) || {}).Name });
      setParams({ name: 'products', value: (getActiveFilterItem(products, activeProductId) || {}).Name });
      setParams({ name: 'authors', value: (getActiveFilterItem(authors, activeAuthorId) || {}).Name });
    }
  };

  const handleFilterChange = (filterParam) => {
    setFilter(filterParam);
    dispatch(fetchBlogPosts(1));
  };

  const resetFilter = useCallback(() => {
    handleFilterChange(initialFilterState);
  }, [setFilter]);

  const blogFilterProps = {
    categories,
    platforms,
    products,
    authors,
    handleFilterChange,
    filterState,
  };

  const filterProps = {
    resetBtnLabel: fields?.Label,
    resetFilter,
    isInitialState,
    isLoading,
    isLoaded,
    isDesktop,
  };

  return (
    <section
      className={classNames({
        'blog-menu': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        {isDesktop ? (
          <Filter {...filterProps}>
            <BlogFilter {...blogFilterProps} />
          </Filter>
        ) : (
          <FilterMobile {...filterProps}>
            <BlogFilter {...blogFilterProps} />
          </FilterMobile>
        )}
      </div>
    </section>
  );
};

const BlogFilter = (props) => {
  const { categories, platforms, products, authors, handleFilterChange, filterState } = props;

  const handleCategoryChange = (selectedItem) => () => {
    handleFilterChange({
      activeCategoryId: filterState.activeCategoryId === selectedItem?.Id ? null : selectedItem.Id,
    });
  };

  const handlePlatformChange = (selectedItem) => () => {
    // if platform is disabled => reset all filters
    handleFilterChange({
      activePlatformId: filterState.activePlatformId === selectedItem?.Id ? null : selectedItem.Id,
      activeProductId: null,
      ...(selectedItem.Disabled && {
        activeCategoryId: null,
        activeAuthorId: null,
      }),
    });
  };

  const handleProductChange = (selectedItem) => () => {
    handleFilterChange({ activeProductId: selectedItem?.Id });
  };

  const handleAuthorChange = (selectedItem) => () => {
    handleFilterChange({ activeAuthorId: selectedItem?.Id });
  };

  return (
    <>
      <div className="filter__item filter__item--categories col-auto">
        <div className="filter__item-name">
          <Text field={{ value: i18n.t('ComponentsBlogsNavigationMenuCategories') }} />
        </div>
        {categories?.map((category) => (
          <div className="filter__item-control" key={category.Id}>
            <BtnTag2
              onClick={handleCategoryChange(category)}
              selected={category.Id === filterState.activeCategoryId}
              disabled={category.Disabled}
            >
              {category.Name}
            </BtnTag2>
          </div>
        ))}
      </div>
      <div className="filter__item filter__item--platforms col-auto">
        <div className="filter__item-name">
          <Text field={{ value: i18n.t('ComponentsBlogsNavigationMenuPlatforms') }} />
        </div>
        {platforms?.map((platform, index, array) => (
          <div
            className={classNames({
              'filter__item-control': true,
              'mr-3': index !== array.length - 1,
            })}
            key={platform.Id}
          >
            <FilterDropdown
              label={platform.Name}
              onClick={handlePlatformChange(platform)}
              split={true}
              selected={platform.Id === filterState.activePlatformId}
              disabled={platform.Disabled}
              options={platform.Id === filterState.activePlatformId ? products : null}
              defaultOption={i18n.t('ComponentsBlogsNavigationMenuResetProduct')}
              selectedOptionId={platform.Id === filterState.activePlatformId ? filterState.activeProductId : null}
              onChange={handleProductChange}
            ></FilterDropdown>
          </div>
        ))}
      </div>
      {authors?.length > 0 && (
        <div className="filter__item filter__item--authors col-auto">
          <div className="filter__item-name">
            <Text field={{ value: i18n.t('ComponentsBlogsNavigationMenuAuthors') }} />
          </div>
          <div className="filter__item-control">
            <FilterDropdown
              label={i18n.t('ComponentsAuthorsDropdownAuthor')}
              options={authors}
              defaultOption={i18n.t('ComponentsBlogsNavigationMenuResetAuthor')}
              selectedOptionId={filterState.activeAuthorId}
              onChange={handleAuthorChange}
            ></FilterDropdown>
          </div>
        </div>
      )}
    </>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(BlogOverviewMenu);
