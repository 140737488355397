import React from 'react';
import ImageComponent from '../UI/Image';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './style.scss';

const ImageBlock = ({ fields }) => {
  return fields.Image?.value?.src ? (
    <div className="image-block">
      <ImageComponent imageData={fields.Image} zoom={fields.Zoom?.value} />
    </div>
  ) : null;
};

export default compose(withSitecoreContext(), ExpEditor)(ImageBlock);
