export const DateFormatter = (value) => {
  if (!value) {
    return;
  }
  return new Date(value).toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export const FormattedDateField = (field) => {
  return {
    ...field,
    value: DateFormatter(field.value),
  };
};
