import React from 'react';
import { Modal } from 'react-bootstrap';
import { Image, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ModalRoot, ExpEditor, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const ContactUsModal = ({ show, title, onHide, fields, rendering, isExperienceEditor, backdrop = true, onShow }) => {
  const modalTitle = title || fields.Title.value;

  return (
    <>
      <Modal
        className={classNames({
          'contact-us-modal': true,
          'experience-editor': isExperienceEditor,
        })}
        centered
        show={show}
        onHide={onHide}
        backdrop={backdrop}
        onShow={onShow}
      >
        <Modal.Header className={'theme-dark'} closeButton>
          <div className="modal-header-inner">
            {fields.TitleImage?.value?.src && <Image className="modal-header-image" field={fields.TitleImage} />}
            {fields.Title?.value && (
              <Modal.Title as={'h2'}>
                <Text field={{ value: modalTitle }} />
              </Modal.Title>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Placeholder name="jss-contact-form" rendering={rendering} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ModalRoot)(ContactUsModal);
