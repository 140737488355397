import React from 'react';
import SearchCard from '../SearchCard';
import './styles.scss';

const SearchResults = ({ data, isLoading }) => {
  return (
    <div className="search-results">
      <ul className="search-results__list list-reset">
        {data?.map((item, i) => {
          return (
            <li key={i} className="search-results__item">
              <SearchCard
                isLoading={isLoading}
                image={item.PreviewImage}
                title={item.Title}
                text={item.SearchTeaser}
                url={item.Url}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchResults;
