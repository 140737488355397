import React from 'react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import CTA from '../UI/CTA';
import ImageComponent from '../UI/Image';
import classNames from 'classnames';
import './styles.scss';

const ToolsBlock = ({ fields, rendering, additionalClasses, additionalStyles, drawBeveledBorder }) => {
  return (
    <section
      className={classNames({
        tools: true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        {fields.Subtitle?.value && <Text tag="h6" className="tools__subtitle" field={fields.Subtitle} />}
        {fields.Title?.value && <Text tag="h2" className="tools__title" field={fields.Title} />}
        {fields.IntroText?.value && <Text tag="p" className="tools__intro" field={fields.IntroText} />}
        {(fields.LogoLeft?.value?.src || fields.LogoRight?.value?.src) && (
          <div className="tools__logos-wrap">
            {fields.LogoLeft?.value?.src && (
              <div className="tools__logo">
                <ImageComponent imageData={fields.LogoLeft.value} />
              </div>
            )}
            {fields.LogoRight?.value?.src && (
              <div className="tools__logo">
                <ImageComponent imageData={fields.LogoRight.value} />
              </div>
            )}
          </div>
        )}
        <div className="row tools__list">
          <Placeholder customColumns={fields.Columns?.fields.Value?.value} rendering={rendering} name="jss-common-tools" />
        </div>
        {(fields.Link?.value && fields.Link?.value.href && fields.Link?.value.text) && (
          <div className="tools__link">
            <CTA tag={'navlink'} to={fields.Link.value.href} type="primary" arrowRight>
              <Text field={{value:fields.Link.value.text}} />
            </CTA>
          </div>
        )}
      </div>
      {drawBeveledBorder && <div className="beveled-border-maker" />}
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(ToolsBlock);
