import React, { createContext, useReducer } from 'react';

export const ActionTypes = {
  SHOW_MODAL: 'SHOW_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  RESET_MODAL: 'RESET_MODAL',
};

const initialState = {
  modalName: null,
  modalProps: {},
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_MODAL: {
      const { modalName, modalProps = {} } = action.payload;
      return {
        modalName,
        modalProps: {
          ...modalProps,
          show: true,
        },
      };
    }
    case ActionTypes.CLOSE_MODAL: {
      const { modalName, modalProps = {} } = action.payload;
      return {
        modalName,
        modalProps: {
          ...modalProps,
          show: false,
        },
      };
    }
    case ActionTypes.RESET_MODAL: {
      return initialState;
    }
  }
};

export const ModalStateContext = createContext();
export const ModalDispatchContext = createContext();

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  return (
    <ModalStateContext.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>{children}</ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};
