import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import CTA from '../UI/CTA';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import ImageComponent from '../UI/Image';
import useBreakpoint from '@/js/hooks/useBreakpoint';
import classNames from 'classnames';
import './style.scss';

const TechCard = ({ fields, url, type }) => {
  const { isDesktop } = useBreakpoint();
  const alternativeImageUrl = fields.PreviewImageAnother?.value?.src ? fields.PreviewImageAnother : fields.PreviewImage;

  return (
    <NavLink
      to={url}
      className={classNames({
        'tech-card': true,
        [`tech-card--${type}`]: type,
        'is-desktop': isDesktop,
      })}
    >
      <LinkOpenIcon />
      <div className="tech-card__inner">
        <div className="tech-card__text-wrap">
          <div className="tech-card__body">
            {fields.PreviewTitle?.value && (
              <h4 className="tech-card__title">
                <Text field={fields.PreviewTitle} />
              </h4>
            )}
            {fields.PreviewText?.value && (
              <div className="tech-card__text">
                <RichText field={fields.PreviewText} />
              </div>
            )}
          </div>
          {fields.PreviewLinkLabel?.value && type === 'vertical' && (
            <div className="tech-card__footer">
              <CTA tag="span" type="secondary">
                <Text field={fields.PreviewLinkLabel} />
              </CTA>
            </div>
          )}
        </div>
        <div className="tech-card__pic-wrap">
          <div className="tech-card__image-wrap">
            {fields.PreviewImage?.value?.src && (
              <ImageComponent imageData={fields.PreviewImage.value} className="tech-card__img" />
            )}
            {isDesktop && (
              <ImageComponent
                imageData={alternativeImageUrl.value}
                className="tech-card__img tech-card__img--dark-theme"
              />
            )}
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default TechCard;
