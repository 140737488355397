import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ThemeResolver } from '@/js/HOC';
import classNames from 'classnames';
import './style.scss';

const ContainerWithTwoColumns = (props) => {
  return (
    <div
      className={classNames({
        'container-two-col': true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        <div className="row">
          <div className="col col-12 col-lg-6">
            <Placeholder rendering={props.rendering} name="jss-container-col-1" />
          </div>
          <div className="col col-12 col-lg-6">
            <Placeholder rendering={props.rendering} name="jss-container-col-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(ThemeResolver)(ContainerWithTwoColumns);
