import React from 'react';
import Zoom from 'react-medium-image-zoom';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import 'react-medium-image-zoom/dist/styles.css';

const ImageComponent = ({ imageData = {}, className, sources, lazy = true, zoom = false }) => {
  if (imageData.value) {
    imageData = imageData.value;
  }

  const imageAttrs = {
    ...(lazy ? { loading: 'lazy' } : {}),
  };

  const { srcMobile, ...data } = imageData;

  const renderImage = () => (
    <picture className="image-component">
      {imageData.webpSrc && <source srcSet={imageData.webpSrc} type="image/webp" />}
      {srcMobile && <source srcSet={srcMobile} media={`(max-width: 767px)`} />}
      {sources?.map((item) => (
        <source key={item.resolution} media={`(min-width: ${item.resolution}px)`} srcSet={item.src} />
      ))}
      <Image className={classNames('img-responsive', className)} field={data} {...imageAttrs} />
    </picture>
  );

  return zoom ? <Zoom>{renderImage()}</Zoom> : renderImage();
};

export default ImageComponent;
