import React from 'react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import CustomerCardPresentationalComponent from '../CustomerCardPresentation';

const CustomerCard = ({ fields, url, cardType = 'Horizontal' }) => {
  const videoUrl = fields?.PreviewYouTubeLink?.value || fields?.PreviewVideoLInk?.value;

  let type = 'text';
  if (fields?.PreviewQuote?.value) {
    type = 'quote';
  } else if (videoUrl) {
    type = 'video';
  }

  return (
    <CustomerCardPresentationalComponent
      cotentType={type}
      cardType={cardType}
      categories={fields?.BusinessCategory}
      platforms={fields?.Platform}
      title={fields?.PreviewTitle?.value}
      linkText={fields?.PreviewLinkLabel?.value}
      linkUrl={url}
      videoUrl={videoUrl}
      logo={fields?.PreviewLogo?.value}
      image={fields?.PreviewImage?.value}
      extLinkUrl={fields?.PreviewExtLink?.Url}
      extLinkTitle={fields?.PreviewExtLinkTitle?.value}
      quote={fields?.PreviewQuote?.value}
    />
  );
};

export default compose(withSitecoreContext(), ExpEditor)(CustomerCard);
