import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const BtnTag2 = ({ children, onClick, selected, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={classNames({
        'btn-tag-2': true,
        selected: selected,
        disabled: disabled,
      })}
    >
      {children}
    </button>
  );
};

export default BtnTag2;
