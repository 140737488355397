import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import CTA from '../UI/CTA';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import { NavLink } from 'react-router-dom';
import './styles.scss';

const LinkItem = ({ fields }) => {
  return (
    <>
      {fields.Link?.value?.href && (
        <NavLink className="link-row-item " to={fields.Link.value.href}>
          <LinkOpenIcon type={3} />
          <div className="link-row-item__wrap">
            {fields.Title?.value && <Text className="link-row-item__title" field={fields.Title} tag={'h4'} />}
          </div>
          {fields.LinkLabel?.value && (
            <CTA tag={'button'} type="secondary">
              <Text field={fields.LinkLabel} />
            </CTA>
          )}
        </NavLink>
      )}
    </>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(LinkItem);
