import React from 'react';
import ReactDOM from 'react-dom';
import { StickyShareButtons } from 'sharethis-reactjs';
import { canUseDOM } from '@/js/utils';
import { compose, withSitecoreContext } from '@/js/HOC';
import './style.scss';

const SocialButtons = ({ sitecoreContext }) => {
  if (!canUseDOM()) {
    return null;
  }

  const title = sitecoreContext.route?.fields?.PreviewTitle?.value;
  const description = sitecoreContext.route?.fields?.PreviewText?.value;
  const emailMessage = description ? `${description}\n\n${window.location.href}` : window.location.href;

  return ReactDOM.createPortal(
    <div className={'social-buttons'}>
      <StickyShareButtons
        config={{
          alignment: 'right', // alignment of buttons (left, right)
          color: 'social', // set the color of buttons (social, white)
          enabled: true, // show/hide buttons (true, false)
          font_size: 16, // font size for the buttons
          hide_desktop: false, // hide buttons on desktop (true, false)
          labels: 'cta', // button labels (cta, counts, null)
          language: 'en', // which language to use (see LANGUAGES)
          min_count: 9999, // hide react counts less than min_count (INTEGER)
          networks: ['facebook', 'twitter', 'linkedin', 'email'],
          padding: 12, // padding within buttons (INTEGER)
          radius: 4, // the corner radius on each button (INTEGER)
          show_total: false, // show/hide the total share count (true, false)
          show_mobile: true, // show/hide the buttons on mobile (true, false)
          show_toggle: true, // show/hide the toggle buttons (true, false)
          size: 48, // the size of each button (INTEGER)
          top: window.innerHeight / 2 - 100, // offset in pixels from the top of the page

          image: sitecoreContext.route?.fields?.PreviewImage?.value?.src,
          title: title,
          description: description,
          subject: title, // (only for email sharing)
          message: emailMessage, // (only for email sharing)
        }}
      ></StickyShareButtons>
    </div>,
    document.getElementById('social-buttons')
  );
};

export default compose(withSitecoreContext())(SocialButtons);
