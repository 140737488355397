export const canUseDOM = () => !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const isDevMode = process.env.NODE_ENV === 'development';

export const deepCopyFunction = (inObject) => {
  let outObject, value, key;

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value);
  }

  return outObject;
};

export const SIZE_UNITS = {
  '1': 'Bytes',
  '1024': 'KB',
  '1048576': 'MB',
};

export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    let self = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(self, args);
    }, delay);
  };
};

const columnsMap = {
  1: 'col-lg-12',
  2: 'col-lg-6',
  3: 'col-lg-4',
  4: 'col-lg-3',
  6: 'col-lg-2',
  default: 'col-lg-4',
};

export const getColumnsClass = (columns) => {
  return columnsMap.hasOwnProperty(columns) ? columnsMap[columns] : columnsMap.default;
}