import React, { useEffect, useReducer } from 'react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import useFetch from '@/js/hooks/useFetch';
import useSearchParams from '@/js/hooks/useSearchParams';
import SearchForm from '../GlobalSearch/SearchForm';
import SearchCategories from '../GlobalSearch/SearchCategories';
import SearchResults from '../GlobalSearch/SearchResults';
import SearchCard from '../GlobalSearch/SearchCard';
import { isDevMode } from '@/js/utils';
import Pagination from '../UI/Pagination';
import i18n from 'i18next';
import classNames from 'classnames';
import './styles.scss';

const initialState = {
  searchTerm: '',
  category: null,
  results: [],
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };
    case 'SET_CATEGORY':
      return {
        ...state,
        category: action.payload,
      };
    case 'SET_RESULTS': {
      return {
        ...state,
        results: action.payload,
      };
    }
    case 'SET_STATE_FROM_URL':
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
        category: action.payload.category,
      };
    default:
      return state;
  }
};

const SearchResult = ({ fields, additionalClasses, additionalStyles, rendering }) => {
  const url = isDevMode ? 'http://localhost:4000/globalSearch' : fields.ApiUrl?.value;
  const [state, dispatch] = useReducer(searchReducer, initialState);
  const { post, response, isLoading } = useFetch({ url: url });
  const { getParams, setParams } = useSearchParams();
  const categories = fields.Sections;

  useEffect(() => {
    const searchTerm = getParams('query') || '';
    const categoryFormUrl = getParams('category') || i18n.t('ComponentsSearchResultsAll');
    const page = getParams('page') || 1;
    const category = categories?.find((item) => item.fields?.Title?.value === categoryFormUrl) || '';
    dispatch({
      type: 'SET_STATE_FROM_URL',
      payload: {
        searchTerm,
        category,
      },
    });
    getData({ searchTerm, category, page });
  }, []);

  const onFormSubmit = () => {
    getData();
  };

  const onCategoryChange = (selectedCategory) => {
    getData({ category: selectedCategory });
  };

  const getData = async ({ searchTerm = state.searchTerm, category = state.category, page = 1 } = {}) => {
    const data = await post({
      query: searchTerm,
      page: page,
      pageSize: fields.PageSize?.value,
      sectionId: category?.id,
      settingId: rendering.dataSource,
    });
    if (data) {
      dispatch({
        type: 'SET_RESULTS',
        payload: data.ResultAll,
      });
    }
    setParams({ name: 'page', value: String(page) });
    setParams({ name: 'category', value: category?.fields?.Title?.value });
    setParams({ name: 'query', value: searchTerm });
  };

  const handlePaginationChange = (currentPage) => {
    getData({ page: currentPage });
  };

  const getResultsDescription = () => {
    if (!response?.data || !state.results.length) {
      return null;
    }
    const page = response.data.CurrentPage;
    const totalItems = response.data.CountAll;
    const searchTerm = response.data.Query ? `"${i18n.t('ComponentsSearchResultsFor')}" "${response.data.Query}"` : '';
    const itemsFrom = page > 1 ? (page - 1) * fields.PageSize?.value + 1 : 1;
    const itemsTo = page * fields.PageSize?.value;
    const totalItemsTo = itemsTo > totalItems ? totalItems : itemsTo;
    return (
      <div className="search__description">
        {i18n.t('ComponentsSearchResultsResults')} {itemsFrom}-{totalItemsTo} {i18n.t('ComponentsSearchResultsOf')}{' '}
        {totalItems} {searchTerm}
      </div>
    );
  };

  return (
    <section
      className={classNames({
        search: true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        <SearchForm
          placeholder={fields.PlaceholderLabel?.value}
          searchTerm={state.searchTerm}
          dispatch={dispatch}
          onFormSubmit={onFormSubmit}
        />
        <SearchCategories
          categories={fields.Sections}
          selectedCategory={state.category}
          dispatch={dispatch}
          onCategoryChange={onCategoryChange}
        />
        <>
          {isLoading && (
            <>
              <SearchCard isLoading={isLoading} />
              <SearchCard isLoading={isLoading} />
              <SearchCard isLoading={isLoading} />
            </>
          )}
          {!isLoading && (
            <>
              {state.results.length <= 0 ? (
                fields.NotFoundMessage?.value
              ) : (
                <>
                  {getResultsDescription()}
                  <SearchResults data={state.results} isLoading={isLoading} />
                  <Pagination
                    currentPage={response?.data.CurrentPage}
                    pagesCount={response?.data.CountPagesAll}
                    maxPages={7}
                    onPageChange={handlePaginationChange}
                  />
                </>
              )}
            </>
          )}
        </>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(SearchResult);
