import axios from 'axios';

export const FETCH_TERM_START = 'FETCH_TERM_START';
export const FETCH_TERM_SUCCESS = 'FETCH_TERM_SUCCESS';
export const FETCH_TERM_ERROR = 'FETCH_TERM_ERROR';
export const SET_STATIC_DATA= 'SET_STATIC_DATA';
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';
export const FETCH_TERM_FINISH = 'FETCH_TERM_FINISH';
export const SHOW_TOOLTIP = 'SHOW_TOOLTIP';
export const CLEAR_TOOLTIP = 'CLEAR_TOOLTIP';

export const setStaticData = (payload) => ({ type: SET_STATIC_DATA, payload });
export const fetchTermStart = (payload) => ({ type: FETCH_TERM_START, payload });
export const fetchDataSuccess = (payload) => ({ type: FETCH_TERM_SUCCESS, payload });

export const fetchTermError = () => ({ type: FETCH_TERM_ERROR });
export const fetchTermFinish = () => ({ type: FETCH_TERM_FINISH });
export const clearTooltip = () => ({ type: CLEAR_TOOLTIP });
export const hideTooltip = () => ({ type: HIDE_TOOLTIP });

export const tooltipClose = () => (dispatch, getState) => {
  dispatch(hideTooltip());
  if(!getState().TermTooltipReducer.isLoading) {
    dispatch(clearTooltip());
  }
}

export const tooltipProcess = (data, useLoaded = true) => (dispatch, getState) => {
  const {
    isLoading: startProcessLoading,
    term: startProcessTerm,
    target: startProcessTarget,
    apiUrl
  } = getState().TermTooltipReducer;

  if(
    startProcessTerm === data.term &&
    startProcessTarget === data.target &&
    !startProcessLoading &&
    useLoaded
  ) {
    return;
  }

  dispatch(fetchTermStart(data));

  if(!startProcessLoading) {
    axios
    .post(apiUrl, {
      name: data.term
    })
    .then((response) => {
      const currentState = getState().TermTooltipReducer;

      if(currentState.show) {
        if(currentState.term === data.term) {
          dispatch(fetchDataSuccess(response.data));
        } else {
          dispatch(fetchTermFinish());
          dispatch(tooltipProcess({
            target: currentState.target,
            term: currentState.term,
          }, false));
        }
      } else {
        dispatch(clearTooltip());
      }
    })
    .catch(() => {
      if(getState().TermTooltipReducer.show) {
        dispatch(fetchTermError());
      } else {
        dispatch(clearTooltip());
      }
    })
    .finally(() => {
      dispatch(fetchTermFinish());
    });
    return;
  }
};
