import React from 'react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import Slider from '../UI/Slider';
import useBreakpoint from '@/js/hooks/useBreakpoint';
import { compose, ExpEditor, ThemeResolver, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const BenefitsBlock = ({ fields, rendering, additionalClasses, additionalStyles, drawBeveledBorder, isExperienceEditor, benefits }) => {
  const { Columns, Subtitle, Title, Introtext } = fields;
  const { isDesktop } = useBreakpoint();

  const settings = {
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const renderItems = () => {
    if (isExperienceEditor) {
      return <Placeholder rendering={rendering} name="jss-common-benefits" />;
    }

    if (!isDesktop) {
      return <Slider { ...settings }>{ benefits?.map((Component) => <div key={Component.key}>{Component}</div>) }</Slider>;
    }

    return (
      <div className="row">
        <Placeholder customColumns={Columns?.fields.Value?.value} rendering={rendering} name="jss-common-benefits" />
      </div>
    );
  };

  return (
    <section
    className={classNames({
      benefits: true,
      ...additionalClasses,
    })}
    style={additionalStyles}
    >
      <div className="container">
	  	{Subtitle?.value && <Text tag="h6" className="benefits__subtitle" field={Subtitle} />}
        {Title?.value && (
          <h2 className="benefits__title">
            <Text field={Title} />
          </h2>
        )}
        {Introtext?.value && (
          <p className="benefits__intro">
            <Text field={Introtext} />
          </p>
        )}
        <div className="benefits__body">{ renderItems() }</div>
      </div>
      {drawBeveledBorder && <div className="beveled-border-maker" />}
    </section>
  );
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-common-benefits',
    prop: 'benefits',
  }),
  ExpEditor,
  ThemeResolver
)(BenefitsBlock);
