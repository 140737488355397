import React from 'react';
import classNames from 'classnames';

export const FieldErrorComponent = (props) => {
  return (
    <div className="validation-message">
      {props.errors.map((error, index) => (
        <span className="error-message" key={index}>
          {error}
        </span>
      ))}
    </div>
  );
};

export const FieldWrapperComponent = (props) => {
  const hasError = props.errors?.length > 0;

  const classes = classNames({
    'form-control-wrap': true,
    'has-error': hasError,
    'has-value': props.value,
    'has-success': props.value && props.isValid,
  });

  return <div className={classes}>{props.children}</div>;
};
