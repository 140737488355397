import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import './styles.scss';

const LocationItem = ({ fields }) => {
  return (
    <div className="location-item__header">
      {fields.Title?.value && <Text tag={'div'} className={'location-item__title'} field={fields.Title} />}
      {fields.Address?.value && (
        <div className="location-item__contact">
          <i className={fields.AddressIcon?.value} />
          <Text field={fields.Address} />
        </div>
      )}
      {fields.Phone?.value && fields.PhoneLabel?.value && (
        <div className="location-item__contact">
          <i className={fields.PhoneIcon?.value} />
          <a className="location-item__contact-link" href={fields.Phone.value}>
            <Text field={fields.PhoneLabel} />
          </a>
          <Text tag="span" field={fields.PhoneDescription} />
        </div>
      )}
      {fields.Website?.value && fields.WebsiteLabel?.value && (
        <div className="location-item__contact">
          <i className={fields.WebsiteIcon?.value} />
          <a className="location-item__contact-link" href={fields.Website.value}>
            <Text field={fields.WebsiteLabel} />
          </a>
        </div>
      )}
    </div>
  );
};

export default LocationItem;
