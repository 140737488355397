import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const FollowUs = ({ fields }) => {
  return (
    <section className={'follow-us theme-dark'}>
      <div className="container">
        <div className="follow-us__inner">
          {fields?.Title?.value && <Text tag={'h4'} className={'follow-us__title'} field={fields.Title} />}
          {fields?.Icon?.value && (
            <div className="follow-us__devider">
              <i className={fields.Icon.value} aria-hidden="true" />
            </div>
          )}
          {fields?.Links?.length > 0 && (
            <div className="socials socials--rounded">
              <ul className="socials__list list-reset">
                {fields.Links.map((item) => {
                  const fields = item.fields;
                  return (
                    <li key={item.id} className="socials__item">
                      <a href={fields.Link?.value?.href} target="_blank" className="socials__link" rel="noreferrer">
                        <i className={`fab ${fields.Icon?.fields?.Value?.value}`} aria-hidden="true" />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(FollowUs);
