import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const FactItem = ({ fields }) => {
  return (
    <div className="fact-item">
      <div className="fact-item__wrapper">
        {fields.Value?.value && <Text field={fields.Value} className="fact-item__count" tag="span" />}
        {fields.Title?.value && <Text field={fields.Title} className="fact-item__type" tag="span" />}
      </div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(FactItem);
