import React from 'react';

const columnsMap = {
  1: 'col-lg-12',
  2: 'col-lg-6',
  3: 'col-lg-4',
  4: 'col-lg-3',
  6: 'col-lg-2',
  default: 'col-lg-4',
};

const WithCustomColumnsGrid = (Component) => (props) => {
  const { customColumns: columns, additionalClass } = props;

  if(columns) {
    const columnClass = columnsMap.hasOwnProperty(columns) ? columnsMap[columns] : columnsMap.default;
    return (
      <div className={`col-xs-12 col-md-6 ${columnClass} ${additionalClass || ''}`}>
        <Component {...props} />
      </div>
    );
  }

  return <Component {...props} />
};

export default WithCustomColumnsGrid;
