import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const ContainerWithThreeColumns = (props) => (
  <div className="container">
    <div className="row">
      <div className="col">
        <Placeholder rendering={props.rendering} name="jss-container-col-1" />
      </div>
      <div className="col">
        <Placeholder rendering={props.rendering} name="jss-container-col-2" />
      </div>
      <div className="col">
        <Placeholder rendering={props.rendering} name="jss-container-col-3" />
      </div>
    </div>
  </div>
);

export default ContainerWithThreeColumns;
