import React from 'react';
import Slider from '../UI/Slider';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Avatar from '../UI/Avatar';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const QuoteStories = (props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
  };

  const renderItems = () => {
    return props?.fields?.Stories?.map((item) => {
      return (
        <div className="quote-stories__item" key={item.id}>
          {item?.fields?.MainQuote && (
            <div className="quote-stories__quote">
              <i className="quote-stories__quote-icon fas fa-quote-right" aria-hidden="true" />
              <div className="quote-stories__quote-text">
                <RichText field={item.fields.MainQuote} />
              </div>
            </div>
          )}
          {item?.fields?.AuthorName?.value && (
            <Avatar photo={item.fields.AuthorPhoto} name={item.fields.AuthorName} title={item.fields.AuthorTitle} />
          )}
        </div>
      );
    });
  };

  return (
    <div className="quote-stories">
      {props?.fields?.Title?.value && (
        <h2 className="quote-stories__title">
          <Text field={props.fields.Title} />
        </h2>
      )}
      <div className="quote-stories__body">
        {props.isExperienceEditor ? renderItems() : <Slider {...settings}>{renderItems()}</Slider>}
      </div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(QuoteStories);
