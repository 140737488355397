import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const CtaRow = (props) => {
  return (
    <section className="cta-row theme-dark">
      <div className="container">
        <div className="row">
          <Placeholder name="jss-cta" rendering={props.rendering} />
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext())(CtaRow);
