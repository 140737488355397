import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import SkeletonLoader from '../UI/SkeletonLoader';
import ImageComponent from '../UI/Image';
import './styles.scss';

const AuthorPreview = ({ author, isLoading }) => {
  return (
    <div className="author-preview">
      <div className="author-preview__pic-wrap">
        <SkeletonLoader isLoading={isLoading}>
          <ImageComponent
            className="author-preview__pic"
            imageData={{
              src: author?.AuthorPhoto || ' ',
              alt: author?.AuthorName,
              width: 200,
              height: 200,
            }}
          />
        </SkeletonLoader>
      </div>
      <div className="author-preview__text-wrap">
        <SkeletonLoader lines={1} isLoading={isLoading}>
          <h4 className="author-preview__name">
            <Text field={{ value: author?.AuthorName }} />
          </h4>
        </SkeletonLoader>
        <SkeletonLoader lines={1} isLoading={isLoading}>
          <h5 className="author-preview__title">
            <Text field={{ value: author?.AuthorTitle }} />
          </h5>
        </SkeletonLoader>
        {!!author?.AuthorAwards.length && <div className="author-preview__awards">{
          author?.AuthorAwards.map(award => (
            <ImageComponent
              key={award.Src}
              className="author-preview__award"
              imageData={{
                src: award.Src || ' ',
                alt: award.Title,
                width: 100,
                height: 100,
              }}
            />
          ))
        }</div>}
        <SkeletonLoader lines={3} isLoading={isLoading}>
          <p className="author-preview__description">
            <Text field={{ value: author?.AuthorDescription }} />
          </p>
        </SkeletonLoader>
      </div>
    </div>
  );
};

export default AuthorPreview;
