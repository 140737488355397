import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../UI/CTA';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const ContactUs = ({ fields }) => {
  return (
    <div className="contact-us">
      {fields.Title?.value && <Text field={fields.Title} className="contact-us__title" tag="h2" />}
      {fields.SubTitle?.value && <Text field={fields.SubTitle} className="contact-us__subtitle" tag="h4" />}
      {fields.Email?.value && fields.EmailLabel?.value && (
        <div className="contact-us__row-wrap">
          <div className="contact-us__icon">
            <i className={fields.EmailIcon.value} />
          </div>
          <CTA tag="a" href={`mailto:${fields.Email.value}`} type="secondary">
            <Text field={fields.EmailLabel} />
          </CTA>
        </div>
      )}
      {fields.Phone?.value && fields.PhoneLabel?.value && (
        <div className="contact-us__row-wrap">
          <div className="contact-us__icon">
            <i className={fields.PhoneIcon.value} />
          </div>
          <CTA tag="a" href={`tel:${fields.Phone.value}`} type="secondary">
            <Text field={fields.PhoneLabel} />
          </CTA>
          <Text tag="span" className="contact-us__link-description" field={fields.PhoneDescription} />
        </div>
      )}
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(ContactUs);
