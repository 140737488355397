import React, { useContext } from 'react';
import { ModalStateContext, ModalDispatchContext, ActionTypes } from '../context/ModalContext';

const ModalRoot = (Component) => (props) => {
  const { modalName, modalProps } = useContext(ModalStateContext);
  const dispatch = useContext(ModalDispatchContext);
  const { isExperienceEditor, rendering } = props;
  const { componentName } = rendering;

  // const onShow = () => {
  //     document.body.style = 'overflow: visible'
  //     setTimeout(() => {
  //         window.scrollTo(0, 0)
  //     }, 0)
  // }

  let totalProps = {
    ...props,
    // ...(!isExperienceEditor ? { backdrop: false, show: true, onShow } : {}),
  };

  const onHide = () => {
    dispatch({
      type: ActionTypes.CLOSE_MODAL,
      payload: {
        modalName,
      },
    });
  };

  if (modalName?.toLowerCase() === componentName?.toLowerCase()) {
    totalProps = { ...totalProps, ...modalProps, onHide };
  }

  return <Component {...totalProps} />;
};

export default ModalRoot;
