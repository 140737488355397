import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext, WithIntersectionBlock, WithRichtextTooltipBlock } from '@/js/HOC';
import './styles.scss';

const ContentBlock = (props) => {
  const blockClasses = props.theme ?
    `content-block ${props.theme}` :
    'content-block';

  return (
    <div className={blockClasses}>
      <Text tag="h4" className="content-block__title" field={props.fields.Title} />
      <RichText className="content-block__text" field={props.fields.Text} />
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver, WithIntersectionBlock, WithRichtextTooltipBlock)(ContentBlock);
