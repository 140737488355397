import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import Avatar from '../UI/Avatar';
import classNames from 'classnames';
import './styles.scss';

const QuoteStory = ({ fields, additionalClasses, additionalStyles }) => {
  return (
    <div
      className={classNames({
        'quote-story': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        {fields.Story?.fields?.MainQuote?.value && (
          <div className="quote-story__quote">
            <i className="quote-story__quote-icon fas fa-quote-right" aria-hidden="true" />
            <div className="quote-story__quote-text">
              <RichText field={fields.Story.fields.MainQuote} />
            </div>
          </div>
        )}
        {fields.Story?.fields?.AuthorName?.value && (
          <div className="quote-story__avatar">
            <Avatar
              types={['lg']}
              photo={fields.Story.fields.AuthorPhoto}
              name={fields.Story.fields.AuthorName}
              title={fields.Story.fields.AuthorTitle}
              introText={fields.Story.fields.AuthorIntroText}
              link={fields.Story.fields.PreviewExtLink}
              linkLabel={fields.Story.fields.PreviewExtLinkTitle}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(QuoteStory);
