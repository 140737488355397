import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import BtnTag2 from '../../UI/BtnTag2';
import './styles.scss';

const SearchCategories = ({ categories, selectedCategory, dispatch, onCategoryChange }) => {
  const handleCategoryChange = (category) => (e) => {
    const selectedCategory = categories.find((item) => item.id === category.id);
    dispatch({
      type: 'SET_CATEGORY',
      payload: selectedCategory,
    });
    onCategoryChange(selectedCategory);
  };

  return (
    <div className="search-categories">
      {categories?.map((item) => {
        return (
          <BtnTag2 onClick={handleCategoryChange(item)} key={item.id} selected={item.id === selectedCategory?.id}>
            <Text field={item.fields?.Title} />
          </BtnTag2>
        );
      })}
    </div>
  );
};

export default SearchCategories;
