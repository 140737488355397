import React from 'react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './style.scss';

const Socials = ({ rounded, fields }) => {
  return (
    <div className={`socials ${rounded ? 'socials--rounded' : ''}`}>
      <ul className="socials__list list-reset">
        {fields &&
          Object.keys(fields).map((key) => {
            const link = fields[key];
            return (
              <li key={key} className="socials__item">
                <a href={link.url} target="_blank" className="socials__link" rel="noreferrer">
                  <i className={`fab ${link.icon}`} aria-hidden="true" />
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(Socials);
