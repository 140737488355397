import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DateFormatter, isDevMode } from '@/js/utils';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext, WithLoader } from '@/js/HOC';
import useFetch from '@/js/hooks/useFetch';
import PostCardPresentationalComponent from '../PostCardPresentation';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import './styles.scss';

const categoryKey = '$category$';
const platformKey = '$platform$';

const MoreFromCategoryOrPlatform = ({ fields, sitecoreContext, additionalClasses, additionalStyles }) => {
  const titleRef = useRef();
  const [result, setResult] = useState([]);
  const history = useHistory();
  const apiUrl = isDevMode ? 'http://localhost:4000/getPostsByCategory' : fields?.ApiUrl?.value;
  const url = fields?.BlogOverviewPage?.value?.href;

  const { post, isLoading } = useFetch({ url: apiUrl });

  let categories;
  let platforms;

  useEffect(() => {
    getData();
  }, []);

  const handleTitleClick = (e) => {
    if (!url) {
      return;
    }
    e.preventDefault();
    const { target } = e;
    history.push({
      pathname: url,
      search: `?${target.getAttribute('data-category')}=${target.id}`,
    });
  };

  const renderTitle = (value) => {
    if (!value) return;
    let category = categories?.length > 0 ? categories[0]?.fields?.Title?.value : null;
    let platform = platforms?.length > 0 ? platforms[0]?.fields?.Title?.value : null;
    if (category) {
      value = value.replace(categoryKey, `<a href="#" data-category="categories" id="${category}">${category}</a>`);
    }

    if (platform) {
      value = value.replace(platformKey, `<a href="#" data-category="platforms" id="${platform}">${platform}</a>`);
    }

    if (titleRef.current) {
      titleRef.current.innerHTML = value;
      titleRef.current.querySelector('a')?.addEventListener('click', handleTitleClick);
    }
  };

  const getData = useCallback(async () => {
    categories = fields?.Categories.length > 0 ? fields?.Categories : sitecoreContext?.route?.fields?.Category;
    platforms = fields?.Platforms.length > 0 ? fields?.Platforms : sitecoreContext?.route?.fields?.Platform;

    if (fields?.OnlyCategoryOrPlatform.value) {
      categories = null;
    }

    const data = await post({
      Categories: categories?.map((item) => item.id),
      Platform: platforms?.map((item) => item.id),
      ExcludePostId: sitecoreContext?.route?.itemId,
      Count: fields?.Count?.value,
    });

    if (data) {
      setResult(data);
      renderTitle(fields?.Title?.value);
    }
  }, [post, setResult]);

  return (
    <section
      className={classNames({
        'more-posts': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <WithLoader isLoading={isLoading} itemsLength={result.length}>
        <div className="container">
          <h4 className="more-posts__title" ref={titleRef} />
          <div className="more-posts__list row">
            {result.length !== 0 && result?.map((post, i) => {
              return (
                <div key={i} className="more-posts__item col-12 col-md-6 col-lg-4">
                  <PostCardPresentationalComponent
                    url={post?.Url}
                    image={{
                      src: post?.PreviewImage,
                      alt: post?.PreviewTitle,
                      width: 715,
                      height: 475,
                    }}
                    title={post?.PreviewTitle}
                    text={post?.PreviewText}
                    categories={post?.Category}
                    platforms={post?.Platform}
                    authors={post?.Authors}
                    publicationDate={DateFormatter(post?.DateOfPublication)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </WithLoader>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(MoreFromCategoryOrPlatform);
