import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext, WithCustomColumnsGrid } from '@/js/HOC';
import ImageComponent from '../UI/Image';
import './styles.scss';

const ToolsBlockItem = ({ fields }) => {
  return (
    <article className="tools-item">
      {fields.Image?.value?.src && (
        <div className="tools-item__pic-wrap">
          <ImageComponent imageData={fields.Image.value} className="tools-item__image" />
        </div>
      )}
      {fields.Text?.value && <RichText field={fields.Text} />}
    </article>
  );
};

export default compose(withSitecoreContext(), ExpEditor, WithCustomColumnsGrid)(ToolsBlockItem);
