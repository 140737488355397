import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const ContainerBlock = (props) => {
  return (
    <div className="main-wrap">
      <Placeholder rendering={props.rendering} name="jss-container" />
    </div>
  );
};

export default ContainerBlock;
