import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ThemeResolver } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const ContainerWithSidebar = (props) => {
  const sideBarPosition = props.params?.Sidebar;
  return (
    <div
      className={classNames({
        'container-width-sidebar': true,
        ...props.additionalClasses,
        [`sidebar-position-${sideBarPosition}`]: sideBarPosition,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        <div className="row">
          <div
            className={classNames('col-12 content-wrap', {
              'col-lg-9': sideBarPosition === 'Left', // toDo read column size from params
              'col-lg-7': sideBarPosition !== 'Left',
            })}
          >
            <Placeholder rendering={props.rendering} name="jss-main-wrap" />
          </div>
          <div
            className={classNames('col-12 sidebar', {
              'col-lg-3': sideBarPosition === 'Left', // toDo read column size from params
              'col-lg-4': sideBarPosition !== 'Left',
            })}
          >
            <Placeholder rendering={props.rendering} name="jss-sidebar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(ThemeResolver)(ContainerWithSidebar);
