import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import Slider from '../UI/Slider';

const RenderNext= ({className, onClick}) => <div className="slick-arrow-wrapper"><button className={className} onClick={onClick} /></div>;
const RenderPrev= ({className, onClick}) => <div className="slick-arrow-wrapper"><button className={className} onClick={onClick} /></div>;

const SliderSection = ({isExperienceEditor, rendering, 'image-slider': imageSlider}) => {

  const settings = {
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'slider-image-block',
    nextArrow: <RenderNext />,
    prevArrow: <RenderPrev />,
  };

  if (isExperienceEditor) {
    return <Placeholder rendering={rendering} name="jss-common-image-slider" />;
  }

  return (<Slider { ...settings }>{
    imageSlider?.map((Component) => Component)
  }</Slider>);
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-common-image-slider',
    prop: 'image-slider',
  }),
  ExpEditor,
)(SliderSection);
