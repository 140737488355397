import React from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import ImageComponent from '../UI/Image';
import './styles.scss';

const OurAwardsBlockItem = ({ fields }) => {
  const linkUrl = fields.Link?.value?.href;
  const Tag = linkUrl ? Link : 'div';
  const tagProps = linkUrl ? { field: fields.Link } : {};

  return (
    <Tag {...tagProps} className="our-awards-block-item">
      <div className="our-awards-block-item__image-wrapper">
        {fields.Image?.value?.src && (
          <ImageComponent className="our-awards-block-item__image" imageData={fields.Image.value} />
        )}
      </div>
      {fields.Title?.value && <Text className="our-awards-block-item__text" field={fields.Title} tag="p" />}
    </Tag>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(OurAwardsBlockItem);
