import React from 'react';

const ExpEditor = (Component) => (props) => {
  const isExperienceEditor = props.sitecoreContext?.pageEditing;
  if (isExperienceEditor && !props?.fields) {
    return <div>Please select datasource item</div>;
  } else if (!isExperienceEditor && !props?.fields) {
    return null;
  }
  return <Component isExperienceEditor={isExperienceEditor} {...props} />;
};

export default ExpEditor;
