import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import CTA from '../UI/CTA';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const StoryNavigate = ({ fields }) => {
  return (
    <div className="story-nav">
      <div className="story-nav__inner">
        <div className="story-nav__btn-wrap">
          {fields?.previousUrl && (
            <CTA tag={'navlink'} to={fields.previousUrl} type="primary" arrowLeft>
              <Text field={{ value: i18n.t('ComponentsStoriesStoryNavigatePreviousCase') }} />
            </CTA>
          )}
        </div>
        <div className="story-nav__btn-wrap">
          {fields?.nextUrl && (
            <CTA tag={'navlink'} to={fields.nextUrl} type="primary" arrowRight>
              <Text field={{ value: i18n.t('ComponentsStoriesStoryNavigateNextCase') }} />
            </CTA>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(StoryNavigate);
