import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../UI/CTA';
import { NavLink } from 'react-router-dom';
import LinkTag from '../UI/LinkTag';
import LinkOpenIcon from '../UI/LinkOpenIcon';
import ImageComponent from '../UI/Image';
import SkeletonLoader from '../UI/SkeletonLoader';
import './styles.scss';

const PostPreviewPresentationalComponent = ({
  url,
  image,
  title,
  text,
  categories,
  platforms,
  authors,
  publicationDate,
  isLoading,
}) => {
  const Tag = url ? NavLink : 'article';
  return (
    <Tag to={url} className="post-preview">
      <figure className="post-preview__figure">
        {url && <LinkOpenIcon type={2} />}
        <SkeletonLoader isLoading={isLoading}>
          <ImageComponent className="post-preview__img" imageData={image ? image : {}} />
        </SkeletonLoader>
      </figure>
      <div className="post-preview__text-wrap">
        <div className="post-preview__text-inner">
          <SkeletonLoader lines={1} customClass="post-preview__tags">
            <div className="tags">
              {categories?.map((item, i) => {
                const tagText = item.fields?.Title.value || item;
                return (
                  <LinkTag key={i} tag="span">
                    <Text field={{ value: tagText }} />
                  </LinkTag>
                );
              })}
              {platforms?.map((item, i) => {
                const tagText = item.fields?.Title.value || item;
                return (
                  <LinkTag key={i} tag="span">
                    <Text field={{ value: tagText }} />
                  </LinkTag>
                );
              })}
            </div>
          </SkeletonLoader>
          <SkeletonLoader lines={2} isLoading={isLoading}>
            {title && <Text tag="h4" className="post-preview__title" field={{ value: title }} />}
          </SkeletonLoader>
          <SkeletonLoader lines={4} isLoading={isLoading}>
            {text && <Text tag="p" className="post-preview__text" field={{ value: text }} />}
          </SkeletonLoader>
          <SkeletonLoader lines={1} isLoading={isLoading} customClass="post-preview__laoding-meta">
            <p className="post-preview__meta">
              {authors?.map((author, i) => (
                <span key={i}>
                  {i > 0 && ', '}
                  <Text field={{ value: author }} />
                </span>
              ))}
              {publicationDate && (
                <span> on <Text field={{ value: publicationDate }} /> </span>
              )}
            </p>
          </SkeletonLoader>
          <SkeletonLoader lines={1} isLoading={isLoading} customClass="post-preview__loading-btn">
            {url && (
              <CTA tag={'button'} type="secondary">
                <Text field={{ value: 'Read post' }} />
              </CTA>
            )}
          </SkeletonLoader>
        </div>
      </div>
    </Tag>
  );
};

export default PostPreviewPresentationalComponent;
