import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import LinkTag from '../UI/LinkTag';

const MoreCategories = ({ fields }) => {
  const url = fields.BlogOverviewPage?.value?.href;

  return (
    <div className="more-categories">
      {fields.Title?.value && <Text tag="h6" className="sidebar__title" field={fields.Title} />}
      <div className="tags">
        {fields.Categories?.map((item) => {
          return (
            <NavLink key={item.id} to={{ pathname: url, search: `?categories=${item.fields.Title.value}` }}>
              <LinkTag>
                <Text field={item.fields.Title} />
              </LinkTag>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(MoreCategories);
