import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import BtnTag2 from '../UI/BtnTag2';
import Filter from '../Filter';
import FilterMobile from '../FilterMobile';
import FilterDropdown from '../FilterDropdown';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import useBreakpoint from '@/js/hooks/useBreakpoint';
import useSearchParams from '@/js/hooks/useSearchParams';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchInsights, setFilterParams, setStaticData } from '../../js/redux/actions/InsightsOverview';
import { isDevMode, shallowEqual as shallowEqualFn } from '@/js/utils';
import classNames from 'classnames';
import './styles.scss';

const initialFilterState = {
  activeCategoryId: null,
  activeTechnologyId: null,
};

const InsightsOverviewMenu = ({ rendering, fields, additionalClasses, additionalStyles }) => {
  console.log('fields', fields);
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState(initialFilterState);

  const {
    categories: availableCategories,
    technologies: availableTechnologies,
    isLoading,
    isLoaded,
  } = useSelector((state) => state.InsightsOverviewReducer, shallowEqual);

  const categories = fields.Categories?.map((category) => ({
    Id: category.id,
    Name: category.fields?.Title?.value,
    Disabled: !availableCategories.map((item) => item.Id).includes(category.id),
  }));

  const technologies = fields.Technologies?.map((technology) => ({
    Id: technology.id,
    Name: technology.fields?.Title?.value,
    Disabled: !availableTechnologies.map((item) => item.Id).includes(technology.id),
  }));

  const { isDesktop } = useBreakpoint();
  const { getParams, setParams } = useSearchParams();

  const isInitialState = useMemo(
    () => shallowEqualFn(initialFilterState, filterState),
    [initialFilterState, filterState]
  );

  useEffect(() => {
    dispatch(
      setStaticData({
        ApiUrl: isDevMode ? 'http://localhost:4000/insightsOverview' : fields.ApiUrl?.value,
        NoResultMessage: fields?.NotFoundMessage?.value,
        PageSize: +fields?.PageSize?.value || 12,
        SettingId: rendering?.dataSource,
      })
    );

    const activeCategoryId = (getActiveFilterFromUrl(categories, 'categories') || {}).Id;
    const activeTechnologyId = (getActiveFilterFromUrl(technologies, 'technologies') || {}).Id;

    setFilter(
      {
        activeCategoryId,
        activeTechnologyId,
      },
      false
    );

    dispatch(fetchInsights(1));
  }, []);

  const getActiveFilterFromUrl = (filterList, filterParam, key = 'Title') => {
    const paramFromUrl = getParams(filterParam);
    return paramFromUrl ? filterList?.find((item) => item.Name.toLowerCase() === paramFromUrl.toLowerCase()) : null;
  };

  const getActiveFilterItem = (filterList, id) => {
    return filterList?.find((item) => item.Id === id);
  };

  const setFilter = (
    {
      activeCategoryId = filterState.activeCategoryId,
      activeTechnologyId = filterState.activeTechnologyId,
    } = filterState,
    writeToUrl = true
  ) => {
    setFilterState({
      ...filterState,
      activeCategoryId,
      activeTechnologyId,
    });

    dispatch(
      setFilterParams({
        Category: activeCategoryId,
        Technology: activeTechnologyId,
      })
    );

    if (writeToUrl) {
      setParams({ name: 'categories', value: (getActiveFilterItem(categories, activeCategoryId) || {}).Name });
      setParams({ name: 'technology', value: (getActiveFilterItem(technologies, activeTechnologyId) || {}).Name });
    }
  };

  const handleFilterChange = (filterParam) => {
    setFilter(filterParam);
    dispatch(fetchInsights(1));
  };

  const resetFilter = useCallback(() => {
    handleFilterChange(initialFilterState);
  }, [setFilter]);

  const insightsFilterProps = {
    categories,
    technologies,
    handleFilterChange,
    filterState,
  };

  const filterProps = {
    resetBtnLabel: fields?.Label,
    resetFilter,
    isInitialState,
    isLoading,
    isLoaded,
    isDesktop,
  };

  return (
    <section
      className={classNames({
        'blog-menu': true,
        'stories-menu': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        {isDesktop ? (
          <Filter {...filterProps}>
            <InsightsFilter {...insightsFilterProps} />
          </Filter>
        ) : (
          <FilterMobile {...filterProps}>
            <InsightsFilter {...insightsFilterProps} />
          </FilterMobile>
        )}
      </div>
    </section>
  );
};

const InsightsFilter = (props) => {
  const { categories, technologies, handleFilterChange, filterState } = props;

  const handleCategoryChange = (selectedItem) => () => {
    handleFilterChange({
      activeCategoryId: filterState.activeCategoryId === selectedItem?.Id ? null : selectedItem.Id,
    });
  };

  const handleTechnologyChange = (selectedItem) => () => {
    handleFilterChange({
      activeTechnologyId: filterState.activeTechnologyId === selectedItem?.Id ? null : selectedItem.Id,
    });
  };

  return (
    <>
      <div className="filter__item filter__item--categories col-auto">
        <div className="filter__item-name">
          <Text field={{ value: i18n.t('ComponentsInsightsOverviewMenuCategories') }} />
        </div>
        {categories?.map((category) => (
          <div className="filter__item-control" key={category.Id}>
            <BtnTag2
              onClick={handleCategoryChange(category)}
              selected={category.Id === filterState.activeCategoryId}
              disabled={category.Disabled}
            >
              {category.Name}
            </BtnTag2>
          </div>
        ))}
      </div>
      <div className="filter__item filter__item--technologies col-auto">
        <div className="filter__item-name">
          <Text field={{ value: i18n.t('ComponentsInsightsOverviewMenuTechnologies') }} />
        </div>
        {technologies?.map((technology, index, array) => (
          <div
            className={classNames({
              'filter__item-control': true,
              'mr-3': index !== array.length - 1,
            })}
            key={technology.Id}
          >
            <FilterDropdown
              label={technology.Name}
              onClick={handleTechnologyChange(technology)}
              split={true}
              selected={technology.Id === filterState.activeTechnologyId}
              disabled={technology.Disabled}
              onChange={handleTechnologyChange}
            ></FilterDropdown>
          </div>
        ))}
      </div>
    </>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(InsightsOverviewMenu);
