import React from 'react';
import './styles.scss';

const SearchForm = ({ placeholder, searchTerm, dispatch, onFormSubmit }) => {
  const setSearchTerm = (value = '') => {
    dispatch({
      type: 'SET_SEARCH_TERM',
      payload: value,
    });
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearInput = (e) => {
    e.preventDefault();
    setSearchTerm();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onFormSubmit();
  };

  return (
    <form className="search-form" onSubmit={handleFormSubmit}>
      <div className="search-form__input-wrap">
        <button className="search-form__submit-btn" type="submit">
          <i className="fas fa-search" />
        </button>
        <input
          type="search"
          className="search-form__input form-control-2"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleInputChange}
        />
        <button className="search-form__reset-btn" type="reset" onClick={clearInput}>
          <i className="fas fa-times" />
        </button>
      </div>
    </form>
  );
};

export default SearchForm;
