import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CustomerCard from '../CustomerCard';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const RelatedBlockStories = (props) => {
  return (
    <section
      className={classNames({
        'related-stories-section': true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        {props.fields.Title?.value && (
          <Text tag="h4" className="related-stories-section__title" field={props.fields.Title} />
        )}
        <div className="related-stories-section__list row">
          {props.fields.Stories?.map((item) => {
            return (
              <div key={item.id} className="related-stories-section__item col-12 col-md-6">
                <CustomerCard url={item.url} fields={item.fields} cardType="Short" />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(RelatedBlockStories);
