import React, { useState, useEffect, useRef } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGlossaryArticles } from '@/js/redux/actions/GlossaryOverview';
import { isDevMode } from '@/js/utils';
import useDebounce from '@/js/hooks/useDebounce';
import { compose, withSitecoreContext, WithLoader } from '@/js/HOC';
import './styles.scss';

// toDo add skeleton loader
const GlossaryOverviewMenu = ({ fields, sitecoreContext }) => {
  const apiUrl = isDevMode ? 'http://localhost:4000/getGlossaryOverview' : fields?.ApiUrl?.value;
  const dispatchRedux = useDispatch();
  const { articles, isLoading } = useSelector((state) => state.GlossaryOverviewReducer);
  const [state, setState] = useState({
    filteredArticles: [],
    searchTerm: '',
    selectedLetter: 'A',
  });

  const scrollBlock = useRef(null);
  const debouncedSearchTerm = useDebounce(state.searchTerm, 500);

  useEffect(() => {
    dispatchRedux(
      fetchGlossaryArticles({
        url: apiUrl,
        pageId: sitecoreContext.itemId,
      })
    );
  }, []);

  const onFilterChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      searchTerm: e.target?.value.toLowerCase(),
    }));
  };

  useEffect(() => {
    const filteredItems = Object.keys(articles).reduce((accumulator, key) => {
      const filteredArticles = articles[key].filter((item) => {
        return item.Name.toLowerCase().includes(debouncedSearchTerm);
      });
      if (filteredArticles.length) {
        accumulator[key] = filteredArticles;
      }
      return accumulator;
    }, {});
    setState((prev) => ({
      ...prev,
      filteredArticles: filteredItems,
    }));
  }, [articles, debouncedSearchTerm]);

  const scrollToLetter = (key) => {
    const currentSection = scrollBlock.current?.querySelector(`#${key}`);
    if (!currentSection) {
      return;
    }
    scrollBlock.current.scroll({
      top: currentSection.offsetTop,
      behavior: 'smooth',
    });
  };

  const onNavigationClick = (key) => () => {
    setState((prev) => ({
      ...prev,
      selectedLetter: key,
    }));
    scrollToLetter(key);
  };

  const renderArticles = (articles) => {
    return (
      <ul className="glossary-filter__article-list list-reset">
        {Object.keys(articles).map((key) => {
          const items = articles[key];
          return (
            <li className="glossary-filter__article-list-item" id={key} key={key}>
              <div className="glossary-filter__letter">{key}</div>
              <div className="glossary-filter__links">
                {items?.map((item, i) => (
                  <NavLink activeClassName="active" key={key + i} to={{ pathname: item.Path }}>
                    {item.Name}
                  </NavLink>
                ))}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      {fields.BackLinkLabel?.value && fields.GlossaryOverviewPage?.url && (
        <NavLink className="glossary-filter__back-link" to={{ pathname: fields.GlossaryOverviewPage.url }}>
          <i className="fas fa-chevron-left" /> <Text field={fields.BackLinkLabel} />
        </NavLink>
      )}
      <div className="glossary-filter">
        <div className="glossary-filter__search-input">
          <i className="fas fa-search" />
          <input type="text" placeholder={fields.Label?.value} value={state.searchTerm} onChange={onFilterChange} />
        </div>
        <div className="glossary-filter__content">
          <div className="glossary-filter__alphabetic-catalog">
            {Object.keys(articles).map((key) => {
              const isDisabled = !Object.keys(state.filteredArticles).includes(key);
              const isSelected = state.selectedLetter === key;
              return (
                <button
                  className={classNames('color-accent glossary-filter__alphabetic-catalog-btn', {
                    selected: isSelected && !isDisabled,
                  })}
                  key={key}
                  onClick={onNavigationClick(key)}
                  disabled={isDisabled}
                >
                  {key}
                </button>
              );
            })}
          </div>
          <div className="glossary-filter__article-list-wrapper">
            <div className="glossary-filter__article-scroll-list" ref={scrollBlock}>
              <WithLoader
                isLoading={isLoading}
                itemsLength={Object.keys(state.filteredArticles).length}
                noResultMessage={fields?.NotFoundMessage?.value}
              >
                {renderArticles(state.filteredArticles)}
              </WithLoader>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withSitecoreContext())(GlossaryOverviewMenu);
