import React, { useEffect, useState, useCallback } from 'react';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import { isDevMode } from '@/js/utils';
import useFetch from '@/js/hooks/useFetch';
import useSearchParams from '@/js/hooks/useSearchParams';
import AuthorPreview from '../AuthorPreview';
import AuthorPosts from '../AuthorPosts';
import classNames from 'classnames';
import './styles.scss';

const BlogOverviewAuthor = ({ fields, additionalClasses, additionalStyles }) => {
  const url = isDevMode ? 'http://localhost:4000/getAuthor' : fields.ApiUrl?.value;
  const [state, setState] = useState({
    author: null,
    posts: [],
    currentPage: null,
    countPages: null,
  });
  const { post, isLoading, error } = useFetch({ url: url, errorMessage: fields.NotFoundMessage?.value });
  const { getParams } = useSearchParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = useCallback(
    async ({ page = 1 } = {}) => {
      const authorName = getParams('authors');
      const data = await post({
        author: authorName,
        page: page,
        pagesize: fields.PageSize?.value || 5,
      });
      if (data) {
        setState((prev) => ({
          ...prev,
          author: data.Author,
          posts: [...prev.posts, ...data.Result],
          countPages: data.CountPages,
          currentPage: data.CurrentPage,
        }));
      }
    },
    [post, setState]
  );

  const handleLoadMore = (page) => {
    getData({ page: page });
  };

  return (
    <section
      className={classNames({
        'blog-author': true,
        ...additionalClasses,
      })}
      style={additionalStyles}
    >
      <div className="container">
        {error ? (
          error
        ) : (
          <>
            <AuthorPreview isLoading={!state.author} author={state.author} />
            <AuthorPosts
              isLoading={isLoading}
              posts={state.posts}
              noResultMessage={fields.NotFoundMessage?.value}
              loadBtnLabel={fields.Label?.value}
              currentPage={state.currentPage}
              totalPages={state.countPages}
              onLoadMore={handleLoadMore}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(BlogOverviewAuthor);
