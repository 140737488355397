import { ModalDispatchContext, ActionTypes } from './ModalContext';
import { useEffect, useContext } from 'react';

const ModalHandler = () => {
  const dispatch = useContext(ModalDispatchContext);

  const globalModalHandler = (e) => {
    let target = e.target;
    const href = target.getAttribute('href');
    if (typeof href === 'string' && href.indexOf('#modal') > -1) {
      e.preventDefault();
      const modalName = href.split('=')[1];
      dispatch({
        type: ActionTypes.SHOW_MODAL,
        payload: {
          modalName: modalName,
          modalProps: {
            title: target.textContent,
          },
        },
      });
    }
  };

  useEffect(() => {
    document.addEventListener('click', globalModalHandler);
    return () => document.removeEventListener('click', globalModalHandler);
  });

  return null;
};

export default ModalHandler;
