import React from 'react';
import Video from '../UI/Video';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const VideoBlock = (props) => {
  const videoUrl = props.fields?.YouTubeLink?.value || props.fields?.VideoLInk?.value;

  return (
    <div className="video-block">
      <Video url={videoUrl} />
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(VideoBlock);
