import axios from 'axios';

export const FETCH_STORIES_START = 'FETCH_STORIES_START';
export const FETCH_STORIES_SUCCESS = 'FETCH_STORIES_SUCCESS';
export const FETCH_STORIES_ERROR = 'FETCH_STORIES_ERROR';
export const SET_STATIC_DATA = 'SET_STATIC_DATA';
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS';

export const setStaticData = (data) => {
  return {
    type: SET_STATIC_DATA,
    payload: data,
  };
};

export const setFilterParams = (filterData) => {
  return {
    type: SET_FILTER_PARAMS,
    payload: filterData,
  };
};

export const fetchDataStart = (loadMore = false) => {
  return {
    type: FETCH_STORIES_START,
    loadMore,
  };
};

export const fetchDataSuccess = (STORIES) => {
  return {
    type: FETCH_STORIES_SUCCESS,
    payload: STORIES,
  };
};

export const fetchDataError = (errorMessage) => {
  return {
    type: FETCH_STORIES_ERROR,
    errorMessage: errorMessage,
  };
};

export const fetchStories =
  (page, loadMore = false) =>
  async (dispatch, getState) => {
    const { ApiUrl, PageSize, SettingId } = getState().StoriesOverviewReducer.staticData;

    const formData = {
      ...getState().StoriesOverviewReducer.filterData,
      Page: page || getState().StoriesOverviewReducer.currentPage,
      PageSize,
      SettingId,
    };
    dispatch(fetchDataStart(loadMore));
    axios
      .post(ApiUrl, formData)
      .then(function (response) {
        dispatch(
          fetchDataSuccess({
            posts: response.data.SearchResult,
            currentPage: response.data.CurrentPage,
            countPages: response.data.CountPages,
            categories: response.data.AvailableCategories,
            platforms: response.data.AvailablePlatforms,
            products: response.data.AvailableProducts,
            countries: response.data.AvailableCountries,
            count: response.data.Count,
            loadMore,
          })
        );
      })
      .catch(function () {
        dispatch(fetchDataError('error'));
      });
  };
