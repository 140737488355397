import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isDevMode } from '@/js/utils';
import { setStaticData, tooltipProcess, tooltipClose } from '../redux/actions/TermTooltip';


const WithRichtextTooltipBlock = (Component) => (props) => {

  const dispatchRedux = useDispatch();
  const requestUrl = isDevMode ? 'http://localhost:4000/getGlossaryByName' : props.fields.GlossaryTooltipApiUrl?.value;
  const tooltipTitle = props.fields.TooltipTitle?.value || 'Glossary.';
  const tooltipLinkTitle = props.fields.TooltipLinkTitle?.value || 'Read more.';
  const errorMessageText = props.fields.TooltipErrorText?.value || 'Something went wrong.';
  const blockRef = useRef();

  const enterTermHandler = (node) => {
    dispatchRedux(tooltipProcess({
      target: node,
      term: node.dataset.term,
    }));
  };

  const leaveTermHandler = (e) => {
    if(!e.relatedTarget || !e.relatedTarget.closest('#term-tooltip-overlay')) {
      dispatchRedux(tooltipClose());
    }
  };

  useEffect(() => {
    dispatchRedux(setStaticData({
      apiUrl: requestUrl,
      title: tooltipTitle,
      linkTitle: tooltipLinkTitle,
      errorMessage: errorMessageText,
    }));
  }, []);

  useEffect(() => {
    const nodesToTooltip = Array.from(blockRef.current.querySelectorAll('.rt-tooltip'));

    nodesToTooltip.forEach((node) => {
      node.addEventListener('mouseenter', enterTermHandler.bind(null, node));
      node.addEventListener('mouseleave', leaveTermHandler);
    });

    return () => {
      nodesToTooltip.forEach((node) => {
        node.removeEventListener('mouseenter', enterTermHandler);
        node.removeEventListener('mouseleave', leaveTermHandler);
      });
    }
  }, []);


  return (
    <div ref={blockRef}>
      <Component {...props} />
    </div>
  )
};

export default WithRichtextTooltipBlock;
