import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import CTA from '../UI/CTA';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const CTALink = ({ fields }) => {
  return (
    <div className="cta-link col-12 col-md">
      <div className="cta-link__btn">
        {fields.Icon && <Image className="cta-link__icon" field={fields.Icon} />}
        {fields.LinkLabel?.value && (
          <CTA tag={'navlink'} to={fields.Link.value.href} type="primary" arrowRight>
            <Text field={fields.LinkLabel} />
          </CTA>
        )}
      </div>
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(CTALink);
