import React from 'react';
import ImageComponent from '../UI/Image';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';

const ImageSliderItem = ({ fields }) => {
  const SlideTitle = fields.Title?.value;
  return fields.Image?.value?.src ? (
      <div>
        <div className="slider-image-block__slide-img-wrapper">
          <ImageComponent className="slider-image-block__slide-img" imageData={{...fields.Image.value, title: SlideTitle}} />
        </div>
        <h6 className="slick-title">{SlideTitle}</h6>
      </div>
    ) : null;
};

export default compose(withSitecoreContext(), ExpEditor)(ImageSliderItem);
