import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import './styles.scss';

const ProjectHighlights = (props) => {
  return (
    <section className="highlights">
      {props.fields?.Title?.value && (
        <h6 className="sidebar__title">
          <Text field={props.fields.Title} />
        </h6>
      )}
      <div className="highlights__list">
        <Placeholder rendering={props.rendering} name="jss-stories-highlights" />
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(ProjectHighlights);
