import React from 'react';
import TechCard from '../TechCard';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import classNames from 'classnames';
import './styles.scss';

const TechnologyOverview = (props) => {
  return (
    <section
      className={classNames({
        'tech-overview': true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        <div className="tech-overview__list row">
          {props.fields.items?.map((item) => {
            return (
              !item?.fields?.HideInNavigation?.value && (
                <div key={item.id} className="tech-overview__col col-12 col-md-6 col-lg-4">
                  <TechCard url={item.url} fields={item.fields} type="vertical" />
                </div>
              )
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(TechnologyOverview);
