import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, ThemeResolver, withPlaceholder, withSitecoreContext } from '@/js/HOC';
import CTA from '../UI/CTA';
import { breakpoints } from '@/js/utils';
import useBreakpoint from '@/js/hooks/useBreakpoint';
import Slider from '../UI/Slider';
import classNames from 'classnames';
import './styles.scss';

const EventsPastBlock = (props) => {
  const { isDesktop } = useBreakpoint();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: breakpoints.screen_sm_max,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: breakpoints.screen_xs_max,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '30px',
        },
      },
    ],
  };

  const renderItems = () => {
    if (props.isExperienceEditor) {
      return <Placeholder rendering={props.rendering} name="jss-company-events-past" />;
    }
    return props.events?.map((Component) => {
      //PostCard
      return (
        <div key={Component.key} className={`events-section__item ${isDesktop ? '' : 'col-12 col-md-6 col-lg-4'}`}>
          {Component}
        </div>
      );
    });
  };

  return (
    <section
      className={classNames({
        'events-section': true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        {props.fields?.Title?.value && <Text tag="h2" className="events-section__title" field={props.fields.Title} />}
        <div className="events-section__body">
          {!isDesktop && !props.isExperienceEditor ? (
            <Slider {...settings}>{renderItems()}</Slider>
          ) : (
            <div className="events-section__list row">{renderItems()}</div>
          )}
        </div>
        {props.fields?.LinkText?.value && props.fields?.Link?.value?.href && (
          <div className="events-section__footer">
            <CTA tag={'navlink'} to={props.fields.Link} type="primary" arrowRight>
              <Text field={props.fields.LinkText} />
            </CTA>
          </div>
        )}
      </div>
    </section>
  );
};

export default compose(
  withSitecoreContext(),
  withPlaceholder({
    placeholder: 'jss-company-events-past',
    prop: 'events',
  }),
  ExpEditor,
  ThemeResolver
)(EventsPastBlock);
