import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import ImageComponent from '../UI/Image';
import './styles.scss';

const OurPlatformPartnersBlockItem = ({ fields }) => {
  return (
    <article className="partners-block">
      {fields.Image?.value?.src && (
        <div className="partners-block__pic-wrap">
          <ImageComponent imageData={fields.Image.value} />
        </div>
      )}
      {fields.Title?.value && <Text tag="h5" className="partners-block__title" field={fields.Title} />}
      {fields.Text?.value && <Text tag="p" className="partners-block__text" field={fields.Text} />}
    </article>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(OurPlatformPartnersBlockItem);
