import React from 'react';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './styles.scss';

const LinkRow = (props) => {
  return (
    <section className="link-row">
      <Placeholder name="jss-links" rendering={props.rendering} />
    </section>
  );
};

export default withSitecoreContext()(LinkRow);
