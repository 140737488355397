import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import SideMenu from '../SideMenu';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';
import CTA from '../UI/CTA';
import { canUseDOM } from '@/js/utils';
import Filter from '../Filter';
import './styles.scss';

const FilterMobile = (props) => {
  const [open, setOpen] = useState(false);

  const onMenuToggle = () => {
    setOpen((value) => !value);
  };

  const handleReset = () => {
    props.resetFilter();
    onMenuToggle();
  };

  const header = () => {
    return (
      <>
        <div className="filter-mobile__close-wrap">
          <button className="side-menu__close" onClick={onMenuToggle}>
            <i className="fas fas fa-times" aria-hidden="true" />
          </button>
          <h5>
            <Text field={{ value: i18n.t('ComponentsBlogsNavigationMenuFilter') }} />
          </h5>
        </div>
        {props.resetBtnLabel?.value && (
          <button className="tag reset-link" onClick={handleReset}>
            <Text field={props.resetBtnLabel} /> <i className="fas fa-trash" />
          </button>
        )}
      </>
    );
  };

  const body = () => {
    return (
      <div className="filter-mobile">
        <Filter {...props} />
        <div className="filter-mobile__apply-btn-wrap">
          <CTA disabled={props.isInitialState} onClick={onMenuToggle}>
            <Text field={{ value: i18n.t('ComponentsBlogsNavigationMenuApplyFilter') }} />{' '}
            <i className="fas fa-check" />
          </CTA>
        </div>
      </div>
    );
  };

  const portal = () => {
    if (!canUseDOM()) {
      return null;
    }
    return ReactDOM.createPortal(
      <SideMenu
        open={open}
        setOpen={setOpen}
        header={header()}
        headerClassName={'filter-mobile__header bg-dark theme-dark'}
        body={body()}
        bodyClassName={'initial-background'}
      />,
      document.getElementById('filter-menu-root')
    );
  };

  return (
    <>
      <div className="filter-mobile__toggle-btn">
        <CTA type="light" onClick={onMenuToggle}>
          <Text field={{ value: i18n.t('ComponentsBlogsNavigationMenuFilter') }} /> <i className="fas fa-filter" />
        </CTA>
      </div>
      {portal()}
    </>
  );
};

export default FilterMobile;
