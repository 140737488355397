import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import useBreakpoint from '@/js/hooks/useBreakpoint';
import Slider from '../UI/Slider';
import { compose, ExpEditor, ThemeResolver, withSitecoreContext } from '@/js/HOC';
import OurTeamBlockItem from '../OurTeamBlockItem';
import classNames from 'classnames';
import './styles.scss';

const OurTeamBlock = (props) => {
  const { isMobile } = useBreakpoint();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
  };

  const renderItems = () => {
    return props.fields.Authors.map((item) => {
      return (
        <div key={item.id} className={`our-team__item ${!isMobile ? 'col-12 col-md-4' : ''}`}>
          <OurTeamBlockItem fields={item.fields} />
        </div>
      );
    });
  };

  return (
    <section
      className={classNames({
        'our-team': true,
        ...props.additionalClasses,
      })}
      style={props.additionalStyles}
    >
      <div className="container">
        {props.fields.Title?.value && <Text tag="h2" className="our-team__title" field={props.fields.Title} />}
        <div className="our-team__body">
          {isMobile ? (
            <Slider {...settings}>{renderItems()}</Slider>
          ) : (
            <div className="our-team__list row">{renderItems()}</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default compose(withSitecoreContext(), ExpEditor, ThemeResolver)(OurTeamBlock);
