import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import './styles.scss';

const OurTeamBlockItem = (props) => {
  return (
    <article className="team-block">
      <picture className="team-block__pic-wrap">
        {props.fields?.AuthorPhoto?.value?.src && (
          <Image className="team-block__img" field={props.fields.AuthorPhoto} />
        )}
      </picture>
      <div className="team-block__text-wrap">
        {props.fields?.AuthorName?.value && (
          <Text tag="h5" className="team-block__title" field={props.fields.AuthorName} />
        )}
        {props.fields?.AuthorTitle?.value && (
          <Text tag="div" className="team-block__position" field={props.fields.AuthorTitle} />
        )}
        <div className="team-block__link-wrap">
          {props.fields?.AuthorSocial?.value && <RichText field={props.fields.AuthorSocial} />}
        </div>
      </div>
    </article>
  );
};

export default OurTeamBlockItem;
