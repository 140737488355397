import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import { compose, ExpEditor, withSitecoreContext } from '@/js/HOC';
import { FormattedDateField } from '@/js/utils';
import LinkTag from '../UI/LinkTag';
import './styles.scss';

const HeaderCurrentAuthor = ({ fields }) => {
  const category = fields?.Category[0]?.fields?.Title?.value;
  const platform = fields?.Platform[0]?.fields?.Title?.value;

  const blogUrl = fields?.BlogPostOverviewPage?.url;
  const authorsUrl = fields?.BlogAuthorPage?.url;
  const authors = fields.Authors?.map((author) => author.fields?.AuthorName?.value);

  return (
    <div className="header-author">
      <div className="header-author__tags">
        <NavLink to={{ pathname: blogUrl, search: `?categories=${category}` }}>
          <LinkTag>{category}</LinkTag>
        </NavLink>
        <NavLink to={{ pathname: blogUrl, search: `?platforms=${platform}` }}>
          <LinkTag>{platform}</LinkTag>
        </NavLink>
      </div>
      {authors?.map((author, i) => (
        <span key={i}>
          {i > 0 && ', '}
          <NavLink to={{ pathname: authorsUrl, search: `?authors=${author}` }} className={'header-author__name'}>
            <Text field={{ value: author }} />
          </NavLink>
        </span>
      ))}{' '}
      {fields?.DateOfPublication?.value && (
        <span className="header-author__date">
          on <Text field={FormattedDateField(fields.DateOfPublication)} />
        </span>
      )}
    </div>
  );
};

export default compose(withSitecoreContext(), ExpEditor)(HeaderCurrentAuthor);
