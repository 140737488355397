import { useEffect, useState } from 'react';
import { breakpoints } from '@/js/utils';

const breakpointsMap = [
  { name: 'mobile', min: 0, max: breakpoints.screen_sm_max || 767 },
  { name: 'tablet', min: breakpoints.screen_md_min || 768, max: breakpoints.screen_md_max || 1159 },
  { name: 'desktop', min: breakpoints.screen_lg_min || 1160, max: null },
];

const getCurrentDevice = (devices) => {
  const windowWidth = window.innerWidth;
  return devices.find((device) => {
    const maxCondition = device.max ? windowWidth <= device.max : true;
    return windowWidth >= device.min && maxCondition;
  });
};

const useBreakpoint = () => {
  const [device, setDevice] = useState({});

  useEffect(() => {
    setDevice(getCurrentDevice(breakpointsMap));
  }, []);

  return {
    isTablet: device.name === 'tablet',
    isMobile: device.name === 'mobile',
    isDesktop: device.name === 'desktop',
  };
};

export default useBreakpoint;
