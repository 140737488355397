import React from 'react';
import classnames from 'classnames';
import './styles.scss';

const SkeletonLoader = ({ children, lines, isLoading, customClass }) => {
  const classes = classnames({
    'skeleton-loader-item': true,
    [`lines-${lines}`]: lines,
    loading: isLoading,
    [customClass]: customClass,
  });
  return isLoading ? <div className={classes}>{children}</div> : <>{children}</>;
};

export default SkeletonLoader;
