import React, { memo, useContext } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { ModalDispatchContext, ActionTypes } from '@/js/context/ModalContext';
import i18n from 'i18next';
import CTA from '../UI/CTA';
import './styles.scss';

const ContactUsBtn = () => {
  const dispatch = useContext(ModalDispatchContext);

  const toggleModal = () => {
    dispatch({
      type: ActionTypes.SHOW_MODAL,
      payload: {
        modalName: 'ContactUsModal',
      },
    });
  };

  return (
    <>
      <CTA arrowRight size="lg" onClick={toggleModal}>
        <Text field={{ value: i18n.t('HeaderContactUsBtnButtonLabel') }} />
      </CTA>
    </>
  );
};

export default memo(ContactUsBtn);
